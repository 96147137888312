import api from 'utils/api';

const getNextURL = group => `/omni/supervision/next?group=${group}`;

const getUpdateURL = interviewId =>
  `/omni/supervision/update?id=${interviewId}`;

const getFinishSupervisionURL = interviewId =>
  `/omni/supervision/finish?id=${interviewId}`;

export function getNext(token, group) {
  const nextUrl = getNextURL(group);
  return api.get(nextUrl, {}, { token }).then(result => result.data);
}

export function update(interviewId, newData, token) {
  const updateURL = getUpdateURL(interviewId);

  return api.post(updateURL, newData, { token });
}

export function finishSupervision(interviewId, token) {
  const finishURL = getFinishSupervisionURL(interviewId);

  return api.post(finishURL, {}, { token });
}
