import { Route, Router, Switch, Redirect } from 'react-router-dom';
import customHistory from 'utils/history';
import LoadingSpinner from 'components/common/container/LoadingSpinner';
import Notification from 'components/common/container/Notification';
import ManualCorrection from 'components/view/manualCorrection/ManualCorrection';
import ManualCorrectorsDashboard from 'components/view/usersDashboard/ManualCorrectorsDashboard';
import Conference from 'components/view/conference/Conference';
import Providers from 'utils/Providers';
import 'react-toastify/dist/ReactToastify.css';
import 'App.css';
import 'index.scss';

const App = () => {
  return (
    <Providers>
      <Router history={customHistory}>
        <LoadingSpinner />
        <Notification />
        <Switch>
          <Redirect from="/:client/conference/*" to="/conference/*" />
          <Route component={Conference} path="/conference/:queue?" />
          <Route
            exact
            component={ManualCorrection}
            path="/:client?/manualCorrection"
          />
          <Route
            exact
            component={ManualCorrectorsDashboard}
            path="/manualCorrectors"
          />
          <Redirect to="/conference" />
        </Switch>
      </Router>
    </Providers>
  );
};

export default App;
