import styled from 'styled-components';

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  border: solid 1px white;
  background-color: #fff;
  padding: 40px;
  justify-content: flex-start;
  position: relative;
`;

const ModuleTitle = styled.h2`
  color: #000;
  margin: 25px 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
  box-shadow: 0 2px 8px 0 rgba(141, 141, 141, 0.5);
  border-radius: 10px;
  resize: none;
  padding: 15px;
  border: none;
  font-family: 'CircularStd-Book';
`;

const Button = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  background-color: #00b2fd;

  &:disabled {
    opacity: 0.5;
    box-shadow: 0 6px 10px 0 rgba(18, 21, 28, 0.4);
    background-color: #757575;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
`;

const NoStyleButton = styled.button`
  border: none;
  background-color: transparent;
  margin-left: 35px;
`;

function Notes({ notes, handleNotesChange, areNotesSaved, setAreNotesSaved }) {
  function handleChange(e) {
    handleNotesChange(e.target.value);
  }

  function saveNotes() {
    setAreNotesSaved(!areNotesSaved);
  }

  function deleteNotes() {
    handleNotesChange('');
    setAreNotesSaved(false);
  }

  return (
    <ModuleContainer>
      <ModuleTitle>{`${areNotesSaved ? 'Edit' : 'Add'} Notes:`}</ModuleTitle>
      <Textarea
        value={notes}
        onChange={handleChange}
        disabled={areNotesSaved}
      ></Textarea>
      <ButtonsContainer>
        <Button onClick={saveNotes}>{areNotesSaved ? 'Edit' : 'Save'}</Button>
        {areNotesSaved && (
          <NoStyleButton onClick={deleteNotes}>Delete</NoStyleButton>
        )}
      </ButtonsContainer>
    </ModuleContainer>
  );
}

export default Notes;
