import { useMemo } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { toImage } from '../../../utils/utils';

import approve from 'assets/img/approve.svg';
import deny from 'assets/img/deny.svg';

const noData = myObj =>
  Object.entries(myObj).every(([, value]) => value.value === undefined);

const formatDate = (date, nativeCompatible = false) => {
  if (!date) {
    return '';
  }

  const dateNumber = Number(date);

  if (!moment(dateNumber).isValid()) {
    return '';
  }

  return nativeCompatible
    ? moment.utc(dateNumber).format('YYYY-MM-DD')
    : moment.utc(dateNumber).format('MMM D YYYY');
};

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  border: solid 1px white;
  background-color: #fff;
  padding: 40px;
  align-items: center;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 120px);
`;

const ModuleTitle = styled.h2`
  display: flex;
  width: 100%;
  justify-content: left;
  letter-spacing: 0.88px;
  color: #20263d;
  font-family: $CircularStd-Bold;
`;

const IdContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 40px 0;
`;

const CroppedId = styled.img`
  height: 125px;
  border-radius: 8px;
  margin-right: 30px;
`;

const IdPlaceholder = styled.div`
  height: 125px;
  width: 220px;
  min-width: 220px;
  border-radius: 8px;
  margin-right: 30px;
  border: solid 0.8px #979797;
  background-color: #d8d8d8;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  opacity: 0.5;

  &.current {
    opacity: 1;
  }
`;

const Span = styled.span`
  position: absolute;
  top: 0;
  font-weight: lighter;
  font-size: 10px;
  letter-spacing: 2px;
  width: 180px;
  height: 15px;
  font-family: $CircularStd-Book;
  font-size: 12px;
  letter-spacing: 2px;
  color: #20263d;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  font-family: $CircularStd-Medium;
  font-size: 18px;
  font-weight: 500;
  color: #20263d;
  color: #000;
  margin-top: 10px;
  text-transform: capitalize;

  &.address {
    font-size: 12px;
  }
`;

const ActionButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  min-width: 100px;
  justify-content: space-evenly;
`;

const ActionButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: ${({ current, type, highlight }) =>
    current
      ? type === 'approve'
        ? '#0cd5a2'
        : '#ff5c6f'
      : highlight
      ? type === 'approve'
        ? '#0cd5a2'
        : '#ff5c6f'
      : '#efefef'};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  margin-top: 40px;
`;

const Button = styled.button`
  background-color: #3cb0f7;
  border-radius: 11px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.14);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  height: 50px;
  text-align: center;
  width: 180px;
  border: none;

  &:disabled {
    background-color: #bdbdbd;
  }
`;

const ActionButtonIcon = styled.img``;

const formConfig = {
  labels: {
    name: 'idInfo.fullName',
    birthDate: 'idInfo.dateOfBirth',
    address: 'idInfo.address',
    gender: 'idInfo.gender',
  },
};

function ActionButton({
  type,
  handleReview,
  name,
  current,
  reviewed,
  passed,
  dataValue,
}) {
  function handleClick(ev) {
    ev.preventDefault();
    const { value } = ev.currentTarget;
    handleReview(name, value, dataValue, type === 'approve');
  }

  return (
    <ActionButtonContainer
      current={current}
      value={type}
      onClick={handleClick}
      disabled={!current}
      type={type}
      reviewed={reviewed}
      passed={passed}
      highlight={reviewed && passed}
    >
      <ActionButtonIcon src={type === 'deny' ? deny : approve} />
    </ActionButtonContainer>
  );
}

function FormItem({ title, data, handleReview, name }) {
  return (
    <FormItemContainer className={cx({ current: data?.current })}>
      <Span>{title}</Span>
      <Paragraph className={cx({ address: name === 'address' })}>
        {data?.value
          ? name === 'birthDate'
            ? formatDate(data?.value)
            : data?.value?.toLowerCase()
          : '-'}
      </Paragraph>
      {data?.value && (
        <ActionButtonGroup>
          <ActionButton
            type="approve"
            handleReview={handleReview}
            name={name}
            current={data?.current}
            reviewed={data?.reviewed}
            passed={data?.passed}
            dataValue={
              name === 'birthDate' ? formatDate(data?.value) : data?.value
            }
          ></ActionButton>
          <ActionButton
            type="deny"
            handleReview={handleReview}
            name={name}
            current={data?.current}
            reviewed={data?.reviewed}
            passed={!data?.passed}
            dataValue={
              name === 'birthDate' ? formatDate(data?.value) : data?.value
            }
          ></ActionButton>
        </ActionButtonGroup>
      )}
    </FormItemContainer>
  );
}

const customSort = ['name', 'birthDate', 'gender', 'address'];

function IdInfo({
  frontId,
  backId,
  dataToReview,
  handleReview,
  handleModuleChange,
  handleComplete,
  modules,
  typeOfId,
  skipValidation,
}) {
  const { t } = useTranslation();

  const valid = useMemo(() => {
    return noData(dataToReview) || skipValidation
      ? true
      : Object.values(dataToReview).every(e => e.reviewed);
  }, [dataToReview, skipValidation]);

  function handleClick(module) {
    handleModuleChange(module);
    handleComplete(module);
  }

  return (
    <ModuleContainer>
      <ModuleTitle>{t('idInfo.idCaptured')}</ModuleTitle>
      <IdContainer>
        {frontId ? <CroppedId src={toImage(frontId)} /> : <IdPlaceholder />}
        {backId ? (
          <CroppedId src={toImage(backId)} />
        ) : typeOfId !== 'Passport' ? (
          <IdPlaceholder />
        ) : null}
      </IdContainer>
      <FormContainer>
        {Object.entries(dataToReview)
          .sort((a, b) => customSort.indexOf(a[0]) - customSort.indexOf(b[0]))
          .map(([key, data]) => {
            return (
              <FormItem
                key={key}
                name={key}
                title={t(formConfig.labels[key])}
                data={data}
                handleReview={handleReview}
              ></FormItem>
            );
          })}
      </FormContainer>
      {!modules['info'].completed && (
        <ButtonContainer>
          <Button disabled={!valid} onClick={() => handleClick('image')}>
            {t('common.nextStep')}
          </Button>
        </ButtonContainer>
      )}
    </ModuleContainer>
  );
}

export default IdInfo;
