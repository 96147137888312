import { toast } from 'react-toastify';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { env } from '../env';

export default function Enum(obj) {
  const keysByValue = new Map();
  const EnumLookup = value => keysByValue.get(value);

  for (const key of Object.keys(obj)) {
    EnumLookup[key] = obj[key];
    keysByValue.set(EnumLookup[key], key);
  }
  return Object.freeze(EnumLookup);
}

export const mobile = {
  Android: () => {
    return window.navigator.userAgent.match(/Android/i);
  },
  BlackBerry: () => {
    return window.navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: () => {
    return window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: () => {
    return window.navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: () => {
    return window.navigator.userAgent.match(/IEMobile/i);
  },
  any: () => {
    return (
      mobile.Android() ||
      mobile.BlackBerry() ||
      mobile.iOS() ||
      mobile.Opera() ||
      mobile.Windows()
    );
  },
};

export function toFixed(number, n) {
  const parsedNumber = Number(number);

  if (Number.isNaN(parsedNumber)) {
    return 0;
  }

  if (!parsedNumber) {
    return 0;
  }

  if (!parsedNumber.toFixed) {
    return 0;
  }

  return parsedNumber.toFixed(n);
}

export const toImage = base64 => `data:image/png;base64,${base64}`;

export const toPdf = base64 => `data:application/pdf;base64,${base64}`;

export function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {}
  document.body.removeChild(textArea);
}
// Try and copy with navigator.clipboard (only available in secure https or localhost) otherwise fallback to html element copy method
export const copyToClipboard = text => {
  return navigator.clipboard
    ? navigator.clipboard.writeText(text)
    : fallbackCopyTextToClipboard(text);
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const setNotification = (
  message,
  severity = 'default',
  handleOnClose = () => {},
) => {
  const options = { onClose: handleOnClose };
  switch (severity) {
    case 'info':
      toast.info(message, { ...options });
      break;
    case 'success':
      toast.success(message, { ...options });
      break;
    case 'warning':
      toast.warning(message, { ...options });
      break;
    case 'error':
      toast.error(message, { ...options });
      break;
    case 'dark':
      toast.dark(message);
      break;

    default:
      toast(message);
      break;
  }
};

export const parseError = errorMessage => {
  let parsedError = errorMessage;
  if (errorMessage.includes('BadRequestException')) {
    parsedError = errorMessage.split('BadRequestException: ')[1];
  }
  return parsedError;
};

export const getBaseUrl = () => {
  return window.location.origin.includes('localhost')
    ? 'https://conference.incodesmile.com'
    : window.location.origin;
};

export const parseTOTPSecret = (secretCode, name) => {
  return `otpauth://totp/Incode:${name.replaceAll(
    ' ',
    '%20',
  )}?secret=${secretCode}&issuer=Incode`;
};

export const validatePhone = value => {
  value = value[0] === '+' ? value : '+' + value;
  const number = parsePhoneNumberFromString(value);
  return number?.isValid() || 'Invalid phone number';
};

export const spacify = (string, after = 4, separator = ' ') => {
  if (!string || typeof string !== 'string') {
    return '';
  }
  return [...string]
    .map((d, i) => (i % after === 0 ? separator + d : d))
    .join('')
    .trim();
};

export const isObjectEmpty = obj => obj && Object.keys(obj).length === 0;

export const areObjectValuesFalsy = obj => !Object.values(obj).some(x => x);

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

// [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
export const camelToStartCase = text => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const camelToSentenceCase = text => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const snakeToCamelCase = str =>
  str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );

export function truncate(str, numberOfCharacters) {
  if (str.length <= numberOfCharacters) {
    return str;
  }

  return str.substr(0, numberOfCharacters).concat('...');
}

export const isNotUndefined = value => value !== undefined;

export const numberRange = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step) + 1)
    .fill(start)
    .map((x, y) => x + y * step);

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getRandomCryptoInt = (minNumber, maxNumber) => {
  const maxRange = 100000;
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint16Array(1);

  crypto.getRandomValues(array);

  const range = maxNumber - minNumber + 1;
  if (array[0] >= Math.floor(maxRange / range) * range)
    return getRandomCryptoInt(minNumber, maxNumber);
  return minNumber + (array[0] % range);
};

export const getDefaultIdSeverity = () => {
  const url = env.REACT_APP_USER_SERVER;
  if (url.includes('stage') || url.includes('demo')) return 1.0;
  if (url.includes('saas') || url.includes('banorte')) return 0.7;
  return 1.0;
};

export const sortAlphabetically = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const toTitleCase = sentence =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
