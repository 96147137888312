import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const NewCustomerWrapper = styled.div`
  height: calc(100vh - 120px);
  width: 100vw;
  background: #1c1f28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  font-family: 'CircularStd-Bold';
`;

const Subtitle = styled.h2`
  font-family: 'CircularStd-Book';
  margin: 30px 0;
  font-size: 20px;
  font-weight: lighter;
`;

const Button = styled.button`
  font-size: 16px;
  width: 160px;
  height: 60px;
  object-fit: contain;
  background-color: #00b2fd;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #0290cb;
  }
`;

function NewCustomer({ onConnect }) {
  const { t } = useTranslation();

  return (
    <NewCustomerWrapper>
      <Title>{t('hey')}</Title>
      <Subtitle>{t('newCustomer')}</Subtitle>
      <Button onClick={onConnect}>{t('connect')}</Button>
    </NewCustomerWrapper>
  );
}

export default NewCustomer;
