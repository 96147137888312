import { useEffect, useState } from 'react';
import cx from 'classnames';
import InnerImageZoom from 'react-inner-image-zoom';
import ImgsViewer from 'react-images-viewer';
import { imageKeys } from './constants';

export function ManualCorrectionImages({ data }) {
  const [currImg, setCurrImg] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imageLength, setImageLength] = useState(0);
  const [isVertical, setIsVertical] = useState(false);
  const [hasDifferentOrientation, setHasDifferentOrientation] = useState(false);

  useEffect(() => {
    if (!data.frontIdUrl || !data.backIdUrl) return;

    const loadImage = (url, callback) => {
      const image = document.createElement('img');
      image.addEventListener('load', () => callback(image));
      image.src = url;
    };

    let frontAspectRatio = 0;
    let backAspectRatio = 0;
    let frontIsVertical = false;

    const checkOrientation = () => {
      loadImage(data.frontIdUrl, frontImage => {
        const frontWidth = frontImage.width;
        const frontHeight = frontImage.height;
        frontAspectRatio = frontHeight / frontWidth;

        const verticalThreshold = 1.2;
        frontIsVertical = frontAspectRatio > verticalThreshold;

        loadImage(data.backIdUrl, backImage => {
          const backWidth = backImage.width;
          const backHeight = backImage.height;
          backAspectRatio = backHeight / backWidth;

          const heightDifferenceThreshold = 1.5;

          if (
            !frontIsVertical &&
            backAspectRatio > 1.2 &&
            Math.abs(backHeight - frontHeight) >
              heightDifferenceThreshold * frontHeight
          ) {
            setHasDifferentOrientation(true);
          } else {
            setHasDifferentOrientation(false);
          }

          setIsVertical(frontIsVertical);
        });
      });
    };

    checkOrientation();
  }, [data.frontIdUrl, data.backIdUrl]);

  useEffect(() => {
    if (!data.id) {
      return;
    }
    const calculatedImageLength = imageKeys.reduce((count, key) => {
      return data[key] ? count + 1 : count;
    }, 0);
    setImageLength(calculatedImageLength);
  }, [data, data.id]);

  const images = [
    { src: data.frontIdUrl, alt: 'front' },
    { src: data.backIdUrl, alt: 'back' },
    { src: data.selfieUrl, alt: 'selfie' },
  ].filter(i => i.src);
  const handleImageClick = index => {
    setCurrImg(index);
    setIsOpen(true);
  };
  return (
    <div className="images">
      <div
        className={cx('ids', {
          [`images-number-${imageLength}`]: true,
          'horizontal-single': !isVertical && imageLength === 1,
          'vertical-single': isVertical && imageLength === 1,
          'horizontal-with-selfie': !isVertical && imageLength === 2,
          'vertical-multiple-without-video': isVertical && imageLength === 2,
          'multiple-with-selfie': isVertical && imageLength === 3,
          'horizontals-with-selfie':
            !isVertical && !hasDifferentOrientation && imageLength === 3,
          'different-orientation': hasDifferentOrientation,
        })}
      >
        {images.map((image, index) => (
          <div key={image.alt}>
            <p className="id-type">{image.alt}</p>
            <div
              className="image-wrapper"
              onClick={() => handleImageClick(index)}
            >
              <InnerImageZoom
                src={image.src}
                zoomType="hover"
                zoomScale={1}
                imgAttributes={{
                  crossOrigin: 'anonymous',
                }}
                zoomPreload
              />
            </div>
          </div>
        ))}
        <ImgsViewer
          backdropCloseable
          isOpen={isOpen}
          onClose={() => setIsOpen(s => !s)}
          imgs={images}
          currImg={currImg}
          onClickNext={() => setCurrImg(i => i + 1)}
          onClickPrev={() => setCurrImg(i => i - 1)}
        />
      </div>
    </div>
  );
}
