import sha1 from 'js-sha1';
import api from 'utils/api';

const loginURL = '/executive/log-in';
export async function login(email, password) {
  const result = await api.post(loginURL, {
    email,
    password,
  });
  return result.data;
}

const validateTokenURL = '/executive/validate-token';
export async function validateToken(token, clientId) {
  const result = await api.get(
    validateTokenURL,
    {},
    {
      token,
      headers: {
        'x-api-key': sha1(clientId),
      },
    },
  );
  return result.data;
}

const adminLoginURL = '/executive/admin/login';
export async function adminLogin(email, password) {
  const result = await api.post(adminLoginURL, {
    email,
    password,
  });
  return result.data;
}

const executiveLoginURL = '/executive/credentials-login';
export async function executiveLogin(email, password) {
  const result = await api.post(executiveLoginURL, {
    email,
    password,
  });
  return result.data;
}

const newPasswordURL = '/executive/new-password';
export async function setNewPassword(newPasswordBody) {
  const result = await api.post(newPasswordURL, newPasswordBody);
  return result.data;
}

const registerExecutiveURL = '/executive/register';
export async function registerExecutive(executive, { token }) {
  const result = await api.post(registerExecutiveURL, executive, { token });
  return result.data;
}

const resendInviteURL = '/executive/resend-email';
/*
 * This method should be used when user was created with registerExecutiveURL method
 * @param {String} email
 */
export async function resendInviteEmail(email) {
  const result = await api.post(resendInviteURL, { email });
  return result.data;
}

const signUpURL = '/omni/organization/sign-up';
export function signUp(signUpData, headers) {
  return api.post(signUpURL, signUpData, { headers }).then(res => res.data);
}

const resendSignUpURL = '/omni/organization/resend-sign-up';
/*
 * This method should be used when user was created with signUp method
 * @param {String} email
 */
export function resendSignUpEmail(email, redirectUrl) {
  return api
    .post(resendSignUpURL, { email, redirectUrl })
    .then(res => res.data);
}

const verifyEmailURL = '/omni/organization/verify-email';
export async function confirmSignUp({ email, code }) {
  const result = await api.post(verifyEmailURL, { email, code });
  return result.data;
}

const forgotPasswordURL = '/executive/forgot-password';
export async function sendForgotPasswordCode(email) {
  const result = await api.post(forgotPasswordURL, {
    email,
    redirectUrl: window.location.href,
  });
  return result.data;
}

const resetPasswordURL = '/executive/reset-password';
export async function sendResetPasswordCode(email, code, newPassword) {
  const result = await api.post(resetPasswordURL, {
    email,
    code,
    newPassword,
  });
  return result.data;
}

const logoutUrl = '/executive/logout';
export async function executiveLogout(token) {
  const result = await api.post(logoutUrl, {}, { token });
  return result.data;
}

const enableMFAURL = '/executive/enable-mfa';
export async function enableMFA(refreshToken, token) {
  const result = await api.post(
    enableMFAURL,
    {
      refreshToken,
    },
    { token },
  );
  return result.data;
}

const confirmMFAURL = '/executive/confirm-mfa';
export async function confirmMFA({ refreshToken, code }, token) {
  const result = await api.post(
    confirmMFAURL,
    {
      refreshToken,
      code,
    },
    { token },
  );
  return result.data;
}

const MFALoginURL = '/executive/mfa-login';
export async function MFALogin({ email, mfaCode, session }) {
  const result = await api.post(MFALoginURL, {
    email,
    session,
    mfaCode,
  });
  return result.data;
}

const confirmPasswordURL = '/executive/validate-credentials';
export async function confirmPassword({ email, password }, token) {
  const result = await api.post(
    confirmPasswordURL,
    {
      email,
      password,
    },
    { token },
  );
  return result.data;
}

const disableMFAURL = '/executive/disable-mfa';
export async function disableMFA(refreshToken, token) {
  const result = await api.post(
    disableMFAURL,
    {
      refreshToken,
    },
    { token },
  );
  return result.data;
}
