import { TYPE_KEY } from '../../utils/constant';

const SHOW_LOADING_CIRCLE = 'SHOW_LOADING_CIRCLE';
const CLOSE_LOADING_CIRCLE = 'CLOSE_LOADING_CIRCLE';

export const SHOW_CIRCLE = 'show_circle';

export const initialState = {
  [SHOW_CIRCLE]: false,
};

export default function reducer(state = initialState, action) {
  let newState;

  switch (action[TYPE_KEY]) {
    case SHOW_LOADING_CIRCLE:
      newState = {
        ...state,
        [SHOW_CIRCLE]: true,
      };
      break;
    case CLOSE_LOADING_CIRCLE:
      newState = {
        ...state,
        [SHOW_CIRCLE]: false,
      };
      break;
    default:
      newState = {
        ...state,
      };
      break;
  }

  return newState;
}

export function showLoadingCircle() {
  return {
    [TYPE_KEY]: SHOW_LOADING_CIRCLE,
  };
}

export function closeLoadingCircle() {
  return {
    [TYPE_KEY]: CLOSE_LOADING_CIRCLE,
  };
}
