import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { env } from '../../../env';

import styled, { css } from 'styled-components';
import smsButton from 'assets/img/sms.png';

const UserHungUpContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 120px);
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const AlertsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  z-index: 201;
`;

const SmsButton = styled.button`
  border: none;
  background: none;
  img {
    height: 40px;
    cursor: pointer;
    ${props =>
      props.disabled &&
      css`
        opacity: 40%;
        cursor: inherit;
      `}
  }
`;

const Title = styled.h1`
  font-size: 36px;
  text-align: center;
`;

const Parragraph = styled.p`
  font-size: 18px;
  margin: 0 20px;
  text-align: center;
`;

function UserHungUp({
  sendSMS,
  token,
  interviewId,
  showNotification,
  title,
  subtitle,
}) {
  const { t } = useTranslation();
  const [smsDisabled, setSmsDisabled] = useState(false);

  const timeOutSms = () => {
    setSmsDisabled(true);
    setTimeout(() => {
      setSmsDisabled(false);
    }, 20000);
  };

  const sendSmsRequest = async () => {
    const urlParams = window.location.pathname.split('/');
    const clientId = urlParams[1];
    const url = `${env.REACT_APP_ONBOARDING_LINK}${
      clientId ? '/' + clientId : ''
    }/conference`;
    await sendSMS(url, interviewId, token);
    showNotification(t('smsSent'));
    timeOutSms();
  };

  return (
    <UserHungUpContainer>
      <AlertsContainer>
        <Parragraph>{t('sendReconnectSMS')}</Parragraph>
        <SmsButton onClick={sendSmsRequest} disabled={smsDisabled}>
          <img src={smsButton} alt="send sms button" />
        </SmsButton>
      </AlertsContainer>
      <Title>{title || t('customerLeft')}</Title>
      <Parragraph>{subtitle || t('customerLeftInstructions')}</Parragraph>
    </UserHungUpContainer>
  );
}

export default UserHungUp;
