import Enum from './utils';
import check from 'assets/img/checkmark-shape.svg';
import fail from 'assets/img/fail-icon.svg';
import caution from 'assets/img/caution-icon.svg';
import unknown from 'assets/img/unknown-icon.svg';

export const TYPE_KEY = 'type';
export const PAYLOAD_KEY = 'payload';
export const HTTP_STATUS_OK = 201;

export const hasSessionMessage =
  'Take a photo to continue from where you left off';

export const STEP_ENUM = Enum({
  0: 'Take a photo from the front part of your IFE / INE ',
  1: 'Now take a photo of the backside of the ID.',
  2: 'Your info',
  3: 'Please take a photo to verify you’re you.',
  4: 'Please smile to verify you’re a real person.',
});

export const VALIDITY_ENUM = Enum({
  0: 'Unknown',
  1: 'Passed',
  2: 'Failed',
  3: 'Skipped',
  4: 'Caution',
  5: 'Attention',
});

export const VALIDITY_ENUM_NUMBER = Enum({
  0: 50,
  1: 100,
  2: 0,
  3: 50,
  4: 50,
  5: 50,
});

export const VALIDITY_ENUM_ICON = Enum({
  0: caution,
  1: check,
  2: fail,
  3: caution,
  4: caution,
  5: caution,
  6: unknown,
});

export const cookieUser = 'incodeSmileUsername';

export const kofaxRequest = {
  jobWithDocsInitialization: {
    Documents: [
      {
        Base64Data: '',
        Data: null,
        DocumentGroup: {
          Id: '',
          Name: '',
          Version: -1,
        },
        DocumentName: null,
        DocumentTypeId: null,
        FieldsToReturn: [],
        FilePath: null,
        FolderId: null,
        FolderTypeId: null,
        MimeType: 'image/tiff',
        PageDataList: [],
        ReturnAllFields: true,
        ReturnFullTextOcr: true,
        RuntimeFields: null,
      },
    ],
    InputVariables: [
      {
        Id: 'Verification',
        Value: 'true',
      },
      {
        Id: 'IDType',
        Value: 'ID',
      },
      {
        Id: 'ProcessImage',
        Value: 'true',
      },
      {
        Id: 'Region',
        Value: 'Latin America',
      },
    ],
    StartDate: null,
    StoreFolderAndDocuments: false,
  },
  processIdentity: {
    Id: '',
    Name: 'KofaxMobileIDCaptureSync',
    Version: -1,
  },
  sessionId: 'C640521793431F4486D4EF1586672385',
  variablesToReturn: [],
};
