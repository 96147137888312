import styled from 'styled-components';

import manualWarning from 'assets/img/manual-warning.svg';

const ManualIndicatorContainer = styled.div`
  height: 45px;
  min-height: 45px;
  border-radius: 5px;
  background-color: #f6ab2f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  padding: 15px;

  &.face-recognition {
    align-self: flex-start;
    margin: 0 30px;
  }
`;

const ManualIndicatorText = styled.p`
  font-family: CircularStd-Medium;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  margin-left: 10px;
`;

const ManualIndicatorIcon = styled.img`
  height: 20px;
`;

function ManualIndicator({ origin }) {
  return (
    <ManualIndicatorContainer className={origin}>
      <ManualIndicatorIcon src={manualWarning} />
      <ManualIndicatorText>
        {`This module needs review because the customer tried capturing the document and/or selfie 3 times unsuccessfully`}
      </ManualIndicatorText>
    </ManualIndicatorContainer>
  );
}

export default ManualIndicator;
