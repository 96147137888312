import api from '../../../utils/api';

const getSessionURL = queueName => {
  return `/omni/queue/conference/next?queueName=${queueName}`;
};

const getQueueIndexURL = queueName => {
  return `/omni/queue/conference?queueName=${queueName}`;
};

const getUserDataURL = interviewId => `/omni/get/ocr-data?id=${interviewId}`;

const getSessionStatusUrl = interviewId => `/omni/get/data?id=${interviewId}`;

export async function getSession({ token, queueName = '' }) {
  try {
    const response = await api.get(getSessionURL(queueName), null, { token });
    return response.data;
  } catch (err) {
    console.error('Failed to get session:', err); // eslint-disable-line no-console
  }
}

export function getQueue({ token, queueName = '' }) {
  return api
    .get(getQueueIndexURL(queueName), null, { token })
    .then(response => response.data.queue);
}

export function getUserData(interviewId, { token }) {
  return api
    .get(getUserDataURL(interviewId), null, { token })
    .then(response => response.data)
    .then(async userData => {
      try {
        const images = await getImages({
          token,
          id: interviewId,
        });

        return {
          userData: { ...userData, ...images },
          front: images.croppedFrontID,
          back: images.croppedBackID,
        };
      } catch (err) {
        console.log('Failed to get user data', err); // eslint-disable-line no-console
        return {
          userData,
          front: '',
          back: '',
        };
      }
    });
}

export async function getSessionStatus(interviewId, { token }) {
  try {
    const response = await api.get(getSessionStatusUrl(interviewId), null, {
      token,
    });
    return response.data;
  } catch (err) {
    console.error('Failed to get session status:', err); // eslint-disable-line no-console
  }
}

export function sendSMS(url, id, token) {
  return api
    .post(`/omni/notify/conference-connect`, { id, url }, { token })
    .then(response => response.data);
}

const images = [
  'croppedIDFace',
  'croppedFace',
  'croppedFrontID',
  'croppedBackID',
  'selfie',
  'document',
];

function getImages({ token, id }) {
  const promises = images.map(key =>
    getImagesRequest({ token, id, body: { images: [key] } }),
  );
  return Promise.all(promises).then(images =>
    images.reduce((res, imageObj) => ({ ...res, ...imageObj }), {}),
  );
}

function getImagesRequest({ token, body, id }) {
  return api
    .post(`/omni/get/images?id=${id}`, body, {
      headers: {
        'X-Incode-Hardware-Id': token,
      },
    })
    .then(response => response.data);
}

export function addConferenceEvent({
  token,
  interviewId,
  opentokSessionId,
  eventType,
  details,
  eventSource,
}) {
  return api
    .post(
      '/omni/conference/add/event',
      {
        interviewId,
        opentokSessionId,
        eventType,
        details,
        eventSource,
      },
      {
        headers: {
          'X-Incode-Hardware-Id': token,
        },
      },
    )
    .catch(e => Promise.reject(e.response.data));
}

export function addInterviewEvent({
  token,
  code,
  module,
  screen,
  clientTimestamp,
  payload,
}) {
  return api
    .post(
      '/omni/interview-events',
      { code, module, screen, clientTimestamp, payload },
      {
        headers: {
          'X-Incode-Hardware-Id': token,
        },
      },
    )
    .catch(e => Promise.reject(e.response.data));
}
