export const countries = [
  {
    id: 4,
    countryName: 'Afghanistan',
    alpha2: 'af',
    alpha3: 'afg',
    languageCode: 'ps-AF',
  },
  {
    id: 248,
    countryName: 'Åland Islands',
    alpha2: 'ax',
    alpha3: 'ala',
    languageCode: 'sv-AX',
  },
  {
    id: 8,
    countryName: 'Albania',
    alpha2: 'al',
    alpha3: 'alb',
    languageCode: 'sq-AL',
  },
  {
    id: 12,
    countryName: 'Algeria',
    alpha2: 'dz',
    alpha3: 'dza',
    languageCode: 'ar-DZ',
  },
  {
    id: 16,
    countryName: 'American Samoa',
    alpha2: 'as',
    alpha3: 'asm',
    languageCode: 'en-AS',
  },
  {
    id: 20,
    countryName: 'Andorra',
    alpha2: 'ad',
    alpha3: 'and',
    languageCode: 'ca-AD',
  },
  {
    id: 24,
    countryName: 'Angola',
    alpha2: 'ao',
    alpha3: 'ago',
    languageCode: 'pt-AO',
  },
  {
    id: 660,
    countryName: 'Anguilla',
    alpha2: 'ai',
    alpha3: 'aia',
    languageCode: 'en-AI',
  },
  {
    id: 10,
    countryName: 'Antarctica',
    alpha2: 'aq',
    alpha3: 'ata',
    languageCode: 'en-AQ',
  },
  {
    id: 28,
    countryName: 'Antigua and Barbuda',
    alpha2: 'ag',
    alpha3: 'atg',
    languageCode: 'en-AG',
  },
  {
    id: 32,
    countryName: 'Argentina',
    alpha2: 'ar',
    alpha3: 'arg',
    languageCode: 'es-AR',
  },
  {
    id: 51,
    countryName: 'Armenia',
    alpha2: 'am',
    alpha3: 'arm',
    languageCode: 'hy-AM',
  },
  {
    id: 533,
    countryName: 'Aruba',
    alpha2: 'aw',
    alpha3: 'abw',
    languageCode: 'nl-AW',
  },
  {
    id: 36,
    countryName: 'Australia',
    alpha2: 'au',
    alpha3: 'aus',
    languageCode: 'en-AU',
  },
  {
    id: 40,
    countryName: 'Austria',
    alpha2: 'at',
    alpha3: 'aut',
    languageCode: 'de-AT',
  },
  {
    id: 31,
    countryName: 'Azerbaijan',
    alpha2: 'az',
    alpha3: 'aze',
    languageCode: 'az-AZ',
  },
  {
    id: 44,
    countryName: 'Bahamas',
    alpha2: 'bs',
    alpha3: 'bhs',
    languageCode: 'en-BS',
  },
  {
    id: 48,
    countryName: 'Bahrain',
    alpha2: 'bh',
    alpha3: 'bhr',
    languageCode: 'ar-BH',
  },
  {
    id: 50,
    countryName: 'Bangladesh',
    alpha2: 'bd',
    alpha3: 'bgd',
    languageCode: 'bn-BD',
  },
  {
    id: 52,
    countryName: 'Barbados',
    alpha2: 'bb',
    alpha3: 'brb',
    languageCode: 'en-BB',
  },
  {
    id: 112,
    countryName: 'Belarus',
    alpha2: 'by',
    alpha3: 'blr',
    languageCode: 'be-BY',
  },
  {
    id: 56,
    countryName: 'Belgium',
    alpha2: 'be',
    alpha3: 'bel',
    languageCode: 'nl-BE',
  },
  {
    id: 84,
    countryName: 'Belize',
    alpha2: 'bz',
    alpha3: 'blz',
    languageCode: 'en-BZ',
  },
  {
    id: 204,
    countryName: 'Benin',
    alpha2: 'bj',
    alpha3: 'ben',
    languageCode: 'fr-BJ',
  },
  {
    id: 60,
    countryName: 'Bermuda',
    alpha2: 'bm',
    alpha3: 'bmu',
    languageCode: 'en-BM',
  },
  {
    id: 64,
    countryName: 'Bhutan',
    alpha2: 'bt',
    alpha3: 'btn',
    languageCode: 'dz-BT',
  },
  {
    id: 68,
    countryName: 'Bolivia (Plurinational State of)',
    alpha2: 'bo',
    alpha3: 'bol',
    languageCode: 'es-BO',
  },
  {
    id: 535,
    countryName: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'bq',
    alpha3: 'bes',
    languageCode: 'nl-BQ',
  },
  {
    id: 70,
    countryName: 'Bosnia and Herzegovina',
    alpha2: 'ba',
    alpha3: 'bih',
    languageCode: 'bs-BA',
  },
  {
    id: 72,
    countryName: 'Botswana',
    alpha2: 'bw',
    alpha3: 'bwa',
    languageCode: 'en-BW',
  },
  {
    id: 74,
    countryName: 'Bouvet Island',
    alpha2: 'bv',
    alpha3: 'bvt',
    languageCode: 'no-BV',
  },
  {
    id: 76,
    countryName: 'Brazil',
    alpha2: 'br',
    alpha3: 'bra',
    languageCode: 'pt-BR',
  },
  {
    id: 86,
    countryName: 'British Indian Ocean Territory',
    alpha2: 'io',
    alpha3: 'iot',
    languageCode: 'en-IO',
  },
  {
    id: 96,
    countryName: 'Brunei Darussalam',
    alpha2: 'bn',
    alpha3: 'brn',
    languageCode: 'ms-BN',
  },
  {
    id: 100,
    countryName: 'Bulgaria',
    alpha2: 'bg',
    alpha3: 'bgr',
    languageCode: 'bg-BG',
  },
  {
    id: 854,
    countryName: 'Burkina Faso',
    alpha2: 'bf',
    alpha3: 'bfa',
    languageCode: 'fr-BF',
  },
  {
    id: 108,
    countryName: 'Burundi',
    alpha2: 'bi',
    alpha3: 'bdi',
    languageCode: 'rn-BI',
  },
  {
    id: 132,
    countryName: 'Cabo Verde',
    alpha2: 'cv',
    alpha3: 'cpv',
    languageCode: 'pt-CV',
  },
  {
    id: 116,
    countryName: 'Cambodia',
    alpha2: 'kh',
    alpha3: 'khm',
    languageCode: 'km-KH',
  },
  {
    id: 120,
    countryName: 'Cameroon',
    alpha2: 'cm',
    alpha3: 'cmr',
    languageCode: 'fr-CM',
  },
  {
    id: 124,
    countryName: 'Canada',
    alpha2: 'ca',
    alpha3: 'can',
    languageCode: 'en-CA',
  },
  {
    id: 136,
    countryName: 'Cayman Islands',
    alpha2: 'ky',
    alpha3: 'cym',
    languageCode: 'en-KY',
  },
  {
    id: 140,
    countryName: 'Central African Republic',
    alpha2: 'cf',
    alpha3: 'caf',
    languageCode: 'fr-CF',
  },
  {
    id: 148,
    countryName: 'Chad',
    alpha2: 'td',
    alpha3: 'tcd',
    languageCode: 'fr-TD',
  },
  {
    id: 152,
    countryName: 'Chile',
    alpha2: 'cl',
    alpha3: 'chl',
    languageCode: 'es-CL',
  },
  {
    id: 156,
    countryName: 'China',
    alpha2: 'cn',
    alpha3: 'chn',
    languageCode: 'zh-CN',
  },
  {
    id: 162,
    countryName: 'Christmas Island',
    alpha2: 'cx',
    alpha3: 'cxr',
    languageCode: 'en-CX',
  },
  {
    id: 166,
    countryName: 'Cocos (Keeling) Islands',
    alpha2: 'cc',
    alpha3: 'cck',
    languageCode: 'en-CC',
  },
  {
    id: 170,
    countryName: 'Colombia',
    alpha2: 'co',
    alpha3: 'col',
    languageCode: 'es-CO',
  },
  {
    id: 174,
    countryName: 'Comoros',
    alpha2: 'km',
    alpha3: 'com',
    languageCode: 'ar-KM',
  },
  {
    id: 178,
    countryName: 'Congo',
    alpha2: 'cg',
    alpha3: 'cog',
    languageCode: 'fr-CG',
  },
  {
    id: 180,
    countryName: 'Congo, Democratic Republic of the',
    alpha2: 'cd',
    alpha3: 'cod',
    languageCode: 'fr-CD',
  },
  {
    id: 184,
    countryName: 'Cook Islands',
    alpha2: 'ck',
    alpha3: 'cok',
    languageCode: 'en-CK',
  },
  {
    id: 188,
    countryName: 'Costa Rica',
    alpha2: 'cr',
    alpha3: 'cri',
    languageCode: 'es-CR',
  },
  {
    id: 384,
    countryName: "Côte d'Ivoire",
    alpha2: 'ci',
    alpha3: 'civ',
    languageCode: 'fr-CI',
  },
  {
    id: 191,
    countryName: 'Croatia',
    alpha2: 'hr',
    alpha3: 'hrv',
    languageCode: 'hr-HR',
  },
  {
    id: 192,
    countryName: 'Cuba',
    alpha2: 'cu',
    alpha3: 'cub',
    languageCode: 'es-CU',
  },
  {
    id: 531,
    countryName: 'Curaçao',
    alpha2: 'cw',
    alpha3: 'cuw',
    languageCode: 'nl-CW',
  },
  {
    id: 196,
    countryName: 'Cyprus',
    alpha2: 'cy',
    alpha3: 'cyp',
    languageCode: 'el-CY',
  },
  {
    id: 203,
    countryName: 'Czechia',
    alpha2: 'cz',
    alpha3: 'cze',
    languageCode: 'cs-CZ',
  },
  {
    id: 208,
    countryName: 'Denmark',
    alpha2: 'dk',
    alpha3: 'dnk',
    languageCode: 'da-DK',
  },
  {
    id: 262,
    countryName: 'Djibouti',
    alpha2: 'dj',
    alpha3: 'dji',
    languageCode: 'fr-DJ',
  },
  {
    id: 212,
    countryName: 'Dominica',
    alpha2: 'dm',
    alpha3: 'dma',
    languageCode: 'en-DM',
  },
  {
    id: 214,
    countryName: 'Dominican Republic',
    alpha2: 'do',
    alpha3: 'dom',
    languageCode: 'es-DO',
  },
  {
    id: 218,
    countryName: 'Ecuador',
    alpha2: 'ec',
    alpha3: 'ecu',
    languageCode: 'es-EC',
  },
  {
    id: 818,
    countryName: 'Egypt',
    alpha2: 'eg',
    alpha3: 'egy',
    languageCode: 'ar-EG',
  },
  {
    id: 222,
    countryName: 'El Salvador',
    alpha2: 'sv',
    alpha3: 'slv',
    languageCode: 'es-SV',
  },
  {
    id: 226,
    countryName: 'Equatorial Guinea',
    alpha2: 'gq',
    alpha3: 'gnq',
    languageCode: 'es-GQ',
  },
  {
    id: 232,
    countryName: 'Eritrea',
    alpha2: 'er',
    alpha3: 'eri',
    languageCode: 'ti-ER',
  },
  {
    id: 233,
    countryName: 'Estonia',
    alpha2: 'ee',
    alpha3: 'est',
    languageCode: 'et-EE',
  },
  {
    id: 748,
    countryName: 'Eswatini',
    alpha2: 'sz',
    alpha3: 'swz',
    languageCode: 'en-SZ',
  },
  {
    id: 231,
    countryName: 'Ethiopia',
    alpha2: 'et',
    alpha3: 'eth',
    languageCode: 'am-ET',
  },
  {
    id: 238,
    countryName: 'Falkland Islands (Malvinas)',
    alpha2: 'fk',
    alpha3: 'flk',
    languageCode: 'en-FK',
  },
  {
    id: 234,
    countryName: 'Faroe Islands',
    alpha2: 'fo',
    alpha3: 'fro',
    languageCode: 'fo-FO',
  },
  {
    id: 242,
    countryName: 'Fiji',
    alpha2: 'fj',
    alpha3: 'fji',
    languageCode: 'en-FJ',
  },
  {
    id: 246,
    countryName: 'Finland',
    alpha2: 'fi',
    alpha3: 'fin',
    languageCode: 'fi-FI',
  },
  {
    id: 250,
    countryName: 'France',
    alpha2: 'fr',
    alpha3: 'fra',
    languageCode: 'fr-FR',
  },
  {
    id: 254,
    countryName: 'French Guiana',
    alpha2: 'gf',
    alpha3: 'guf',
    languageCode: 'fr-GF',
  },
  {
    id: 258,
    countryName: 'French Polynesia',
    alpha2: 'pf',
    alpha3: 'pyf',
    languageCode: 'fr-PF',
  },
  {
    id: 260,
    countryName: 'French Southern Territories',
    alpha2: 'tf',
    alpha3: 'atf',
    languageCode: 'fr-TF',
  },
  {
    id: 266,
    countryName: 'Gabon',
    alpha2: 'ga',
    alpha3: 'gab',
    languageCode: 'fr-GA',
  },
  {
    id: 270,
    countryName: 'Gambia',
    alpha2: 'gm',
    alpha3: 'gmb',
    languageCode: 'en-GM',
  },
  {
    id: 268,
    countryName: 'Georgia',
    alpha2: 'ge',
    alpha3: 'geo',
    languageCode: 'ka-GE',
  },
  {
    id: 276,
    countryName: 'Germany',
    alpha2: 'de',
    alpha3: 'deu',
    languageCode: 'de-DE',
  },
  {
    id: 288,
    countryName: 'Ghana',
    alpha2: 'gh',
    alpha3: 'gha',
    languageCode: 'en-GH',
  },
  {
    id: 292,
    countryName: 'Gibraltar',
    alpha2: 'gi',
    alpha3: 'gib',
    languageCode: 'en-GI',
  },
  {
    id: 300,
    countryName: 'Greece',
    alpha2: 'gr',
    alpha3: 'grc',
    languageCode: 'el-GR',
  },
  {
    id: 304,
    countryName: 'Greenland',
    alpha2: 'gl',
    alpha3: 'grl',
    languageCode: 'kl-GL',
  },
  {
    id: 308,
    countryName: 'Grenada',
    alpha2: 'gd',
    alpha3: 'grd',
    languageCode: 'en-GD',
  },
  {
    id: 312,
    countryName: 'Guadeloupe',
    alpha2: 'gp',
    alpha3: 'glp',
    languageCode: 'fr-GP',
  },
  {
    id: 316,
    countryName: 'Guam',
    alpha2: 'gu',
    alpha3: 'gum',
    languageCode: 'en-GU',
  },
  {
    id: 320,
    countryName: 'Guatemala',
    alpha2: 'gt',
    alpha3: 'gtm',
    languageCode: 'es-GT',
  },
  {
    id: 831,
    countryName: 'Guernsey',
    alpha2: 'gg',
    alpha3: 'ggy',
    languageCode: 'en-GG',
  },
  {
    id: 324,
    countryName: 'Guinea',
    alpha2: 'gn',
    alpha3: 'gin',
    languageCode: 'fr-GN',
  },
  {
    id: 624,
    countryName: 'Guinea-Bissau',
    alpha2: 'gw',
    alpha3: 'gnb',
    languageCode: 'pt-GW',
  },
  {
    id: 328,
    countryName: 'Guyana',
    alpha2: 'gy',
    alpha3: 'guy',
    languageCode: 'en-GY',
  },
  {
    id: 332,
    countryName: 'Haiti',
    alpha2: 'ht',
    alpha3: 'hti',
    languageCode: 'fr-HT',
  },
  {
    id: 334,
    countryName: 'Heard Island and McDonald Islands',
    alpha2: 'hm',
    alpha3: 'hmd',
    languageCode: 'en-HM',
  },
  {
    id: 336,
    countryName: 'Holy See',
    alpha2: 'va',
    alpha3: 'vat',
    languageCode: 'it-VA',
  },
  {
    id: 340,
    countryName: 'Honduras',
    alpha2: 'hn',
    alpha3: 'hnd',
    languageCode: 'es-HN',
  },
  {
    id: 344,
    countryName: 'Hong Kong',
    alpha2: 'hk',
    alpha3: 'hkg',
    languageCode: 'zh-HK',
  },
  {
    id: 348,
    countryName: 'Hungary',
    alpha2: 'hu',
    alpha3: 'hun',
    languageCode: 'hu-HU',
  },
  {
    id: 352,
    countryName: 'Iceland',
    alpha2: 'is',
    alpha3: 'isl',
    languageCode: 'is-IS',
  },
  {
    id: 356,
    countryName: 'India',
    alpha2: 'in',
    alpha3: 'ind',
    languageCode: 'hi-IN',
  },
  {
    id: 360,
    countryName: 'Indonesia',
    alpha2: 'id',
    alpha3: 'idn',
    languageCode: 'id-ID',
  },
  {
    id: 364,
    countryName: 'Iran (Islamic Republic of)',
    alpha2: 'ir',
    alpha3: 'irn',
    languageCode: 'fa-IR',
  },
  {
    id: 368,
    countryName: 'Iraq',
    alpha2: 'iq',
    alpha3: 'irq',
    languageCode: 'ar-IQ',
  },
  {
    id: 372,
    countryName: 'Ireland',
    alpha2: 'ie',
    alpha3: 'irl',
    languageCode: 'en-IE',
  },
  {
    id: 833,
    countryName: 'Isle of Man',
    alpha2: 'im',
    alpha3: 'imn',
    languageCode: 'en-IM',
  },
  {
    id: 376,
    countryName: 'Israel',
    alpha2: 'il',
    alpha3: 'isr',
    languageCode: 'he-IL',
  },
  {
    id: 380,
    countryName: 'Italy',
    alpha2: 'it',
    alpha3: 'ita',
    languageCode: 'it-IT',
  },
  {
    id: 388,
    countryName: 'Jamaica',
    alpha2: 'jm',
    alpha3: 'jam',
    languageCode: 'en-JM',
  },
  {
    id: 392,
    countryName: 'Japan',
    alpha2: 'jp',
    alpha3: 'jpn',
    languageCode: 'ja-JP',
  },
  {
    id: 832,
    countryName: 'Jersey',
    alpha2: 'je',
    alpha3: 'jey',
    languageCode: 'en-JE',
  },
  {
    id: 400,
    countryName: 'Jordan',
    alpha2: 'jo',
    alpha3: 'jor',
    languageCode: 'ar-JO',
  },
  {
    id: 398,
    countryName: 'Kazakhstan',
    alpha2: 'kz',
    alpha3: 'kaz',
    languageCode: 'kk-KZ',
  },
  {
    id: 404,
    countryName: 'Kenya',
    alpha2: 'ke',
    alpha3: 'ken',
    languageCode: 'en-KE',
  },
  {
    id: 296,
    countryName: 'Kiribati',
    alpha2: 'ki',
    alpha3: 'kir',
    languageCode: 'en-KI',
  },
  {
    id: 408,
    countryName: "Korea (Democratic People's Republic of)",
    alpha2: 'kp',
    alpha3: 'prk',
    languageCode: 'ko-KP',
  },
  {
    id: 410,
    countryName: 'Korea, Republic of',
    alpha2: 'kr',
    alpha3: 'kor',
    languageCode: 'ko-KR',
  },
  {
    id: 414,
    countryName: 'Kuwait',
    alpha2: 'kw',
    alpha3: 'kwt',
    languageCode: 'ar-KW',
  },
  {
    id: 417,
    countryName: 'Kyrgyzstan',
    alpha2: 'kg',
    alpha3: 'kgz',
    languageCode: 'ky-KG',
  },
  {
    id: 418,
    countryName: "Lao People's Democratic Republic",
    alpha2: 'la',
    alpha3: 'lao',
    languageCode: 'lo-LA',
  },
  {
    id: 428,
    countryName: 'Latvia',
    alpha2: 'lv',
    alpha3: 'lva',
    languageCode: 'lv-LV',
  },
  {
    id: 422,
    countryName: 'Lebanon',
    alpha2: 'lb',
    alpha3: 'lbn',
    languageCode: 'ar-LB',
  },
  {
    id: 426,
    countryName: 'Lesotho',
    alpha2: 'ls',
    alpha3: 'lso',
    languageCode: 'st-LS',
  },
  {
    id: 430,
    countryName: 'Liberia',
    alpha2: 'lr',
    alpha3: 'lbr',
    languageCode: 'en-LR',
  },
  {
    id: 434,
    countryName: 'Libya',
    alpha2: 'ly',
    alpha3: 'lby',
    languageCode: 'ar-LY',
  },
  {
    id: 438,
    countryName: 'Liechtenstein',
    alpha2: 'li',
    alpha3: 'lie',
    languageCode: 'de-LI',
  },
  {
    id: 440,
    countryName: 'Lithuania',
    alpha2: 'lt',
    alpha3: 'ltu',
    languageCode: 'lt-LT',
  },
  {
    id: 442,
    countryName: 'Luxembourg',
    alpha2: 'lu',
    alpha3: 'lux',
    languageCode: 'fr-LU',
  },
  {
    id: 446,
    countryName: 'Macao',
    alpha2: 'mo',
    alpha3: 'mac',
    languageCode: 'zh-MO',
  },
  {
    id: 450,
    countryName: 'Madagascar',
    alpha2: 'mg',
    alpha3: 'mdg',
    languageCode: 'fr-MG',
  },
  {
    id: 454,
    countryName: 'Malawi',
    alpha2: 'mw',
    alpha3: 'mwi',
    languageCode: 'en-MW',
  },
  {
    id: 458,
    countryName: 'Malaysia',
    alpha2: 'my',
    alpha3: 'mys',
    languageCode: 'ms-MY',
  },
  {
    id: 462,
    countryName: 'Maldives',
    alpha2: 'mv',
    alpha3: 'mdv',
    languageCode: 'dv-MV',
  },
  {
    id: 466,
    countryName: 'Mali',
    alpha2: 'ml',
    alpha3: 'mli',
    languageCode: 'fr-ML',
  },
  {
    id: 470,
    countryName: 'Malta',
    alpha2: 'mt',
    alpha3: 'mlt',
    languageCode: 'mt-MT',
  },
  {
    id: 584,
    countryName: 'Marshall Islands',
    alpha2: 'mh',
    alpha3: 'mhl',
    languageCode: 'en-MH',
  },
  {
    id: 474,
    countryName: 'Martinique',
    alpha2: 'mq',
    alpha3: 'mtq',
    languageCode: 'fr-MQ',
  },
  {
    id: 478,
    countryName: 'Mauritania',
    alpha2: 'mr',
    alpha3: 'mrt',
    languageCode: 'ar-MR',
  },
  {
    id: 480,
    countryName: 'Mauritius',
    alpha2: 'mu',
    alpha3: 'mus',
    languageCode: 'en-MU',
  },
  {
    id: 175,
    countryName: 'Mayotte',
    alpha2: 'yt',
    alpha3: 'myt',
    languageCode: 'fr-YT',
  },
  {
    id: 484,
    countryName: 'Mexico',
    alpha2: 'mx',
    alpha3: 'mex',
    languageCode: 'es-MX',
  },
  {
    id: 583,
    countryName: 'Micronesia (Federated States of)',
    alpha2: 'fm',
    alpha3: 'fsm',
    languageCode: 'en-FM',
  },
  {
    id: 498,
    countryName: 'Moldova, Republic of',
    alpha2: 'md',
    alpha3: 'mda',
    languageCode: 'ro-MD',
  },
  {
    id: 492,
    countryName: 'Monaco',
    alpha2: 'mc',
    alpha3: 'mco',
    languageCode: 'fr-MC',
  },
  {
    id: 496,
    countryName: 'Mongolia',
    alpha2: 'mn',
    alpha3: 'mng',
    languageCode: 'mn-MN',
  },
  {
    id: 499,
    countryName: 'Montenegro',
    alpha2: 'me',
    alpha3: 'mne',
    languageCode: 'sr-ME',
  },
  {
    id: 500,
    countryName: 'Montserrat',
    alpha2: 'ms',
    alpha3: 'msr',
    languageCode: 'en-MS',
  },
  {
    id: 504,
    countryName: 'Morocco',
    alpha2: 'ma',
    alpha3: 'mar',
    languageCode: 'ar-MA',
  },
  {
    id: 508,
    countryName: 'Mozambique',
    alpha2: 'mz',
    alpha3: 'moz',
    languageCode: 'pt-MZ',
  },
  {
    id: 104,
    countryName: 'Myanmar',
    alpha2: 'mm',
    alpha3: 'mmr',
    languageCode: 'my-MM',
  },
  {
    id: 516,
    countryName: 'Namibia',
    alpha2: 'na',
    alpha3: 'nam',
    languageCode: 'en-NA',
  },
  {
    id: 520,
    countryName: 'Nauru',
    alpha2: 'nr',
    alpha3: 'nru',
    languageCode: 'en-NR',
  },
  {
    id: 524,
    countryName: 'Nepal',
    alpha2: 'np',
    alpha3: 'npl',
    languageCode: 'ne-NP',
  },
  {
    id: 528,
    countryName: 'Netherlands',
    alpha2: 'nl',
    alpha3: 'nld',
    languageCode: 'nl-NL',
  },
  {
    id: 540,
    countryName: 'New Caledonia',
    alpha2: 'nc',
    alpha3: 'ncl',
    languageCode: 'fr-NC',
  },
  {
    id: 554,
    countryName: 'New Zealand',
    alpha2: 'nz',
    alpha3: 'nzl',
    languageCode: 'en-NZ',
  },
  {
    id: 558,
    countryName: 'Nicaragua',
    alpha2: 'ni',
    alpha3: 'nic',
    languageCode: 'es-NI',
  },
  {
    id: 562,
    countryName: 'Niger',
    alpha2: 'ne',
    alpha3: 'ner',
    languageCode: 'fr-NE',
  },
  {
    id: 566,
    countryName: 'Nigeria',
    alpha2: 'ng',
    alpha3: 'nga',
    languageCode: 'en-NG',
  },
  {
    id: 570,
    countryName: 'Niue',
    alpha2: 'nu',
    alpha3: 'niu',
    languageCode: 'en-NU',
  },
  {
    id: 574,
    countryName: 'Norfolk Island',
    alpha2: 'nf',
    alpha3: 'nfk',
    languageCode: 'en-NF',
  },
  {
    id: 807,
    countryName: 'North Macedonia',
    alpha2: 'mk',
    alpha3: 'mkd',
    languageCode: 'mk_MK',
  },
  {
    id: 580,
    countryName: 'Northern Mariana Islands',
    alpha2: 'mp',
    alpha3: 'mnp',
    languageCode: 'en-MP',
  },
  {
    id: 578,
    countryName: 'Norway',
    alpha2: 'no',
    alpha3: 'nor',
    languageCode: 'no-NO',
  },
  {
    id: 512,
    countryName: 'Oman',
    alpha2: 'om',
    alpha3: 'omn',
    languageCode: 'ar-OM',
  },
  {
    id: 586,
    countryName: 'Pakistan',
    alpha2: 'pk',
    alpha3: 'pak',
    languageCode: 'ur-PK',
  },
  {
    id: 585,
    countryName: 'Palau',
    alpha2: 'pw',
    alpha3: 'plw',
    languageCode: 'en-PW',
  },
  {
    id: 275,
    countryName: 'Palestine, State of',
    alpha2: 'ps',
    alpha3: 'pse',
    languageCode: 'ar-PS',
  },
  {
    id: 591,
    countryName: 'Panama',
    alpha2: 'pa',
    alpha3: 'pan',
    languageCode: 'es-PA',
  },
  {
    id: 598,
    countryName: 'Papua New Guinea',
    alpha2: 'pg',
    alpha3: 'png',
    languageCode: 'en-PG',
  },
  {
    id: 600,
    countryName: 'Paraguay',
    alpha2: 'py',
    alpha3: 'pry',
    languageCode: 'es-PY',
  },
  {
    id: 604,
    countryName: 'Peru',
    alpha2: 'pe',
    alpha3: 'per',
    languageCode: 'es-PE',
  },
  {
    id: 608,
    countryName: 'Philippines',
    alpha2: 'ph',
    alpha3: 'phl',
    languageCode: 'fil-PH',
  },
  {
    id: 612,
    countryName: 'Pitcairn',
    alpha2: 'pn',
    alpha3: 'pcn',
    languageCode: 'en-PN',
  },
  {
    id: 616,
    countryName: 'Poland',
    alpha2: 'pl',
    alpha3: 'pol',
    languageCode: 'pl-PL',
  },
  {
    id: 620,
    countryName: 'Portugal',
    alpha2: 'pt',
    alpha3: 'prt',
    languageCode: 'pt-PT',
  },
  {
    id: 630,
    countryName: 'Puerto Rico',
    alpha2: 'pr',
    alpha3: 'pri',
    languageCode: 'es-PR',
  },
  {
    id: 634,
    countryName: 'Qatar',
    alpha2: 'qa',
    alpha3: 'qat',
    languageCode: 'ar-QA',
  },
  {
    id: 638,
    countryName: 'Réunion',
    alpha2: 're',
    alpha3: 'reu',
    languageCode: 'fr-RE',
  },
  {
    id: 642,
    countryName: 'Romania',
    alpha2: 'ro',
    alpha3: 'rou',
    languageCode: 'ro-RO',
  },
  {
    id: 643,
    countryName: 'Russian Federation',
    alpha2: 'ru',
    alpha3: 'rus',
    languageCode: 'ru-RU',
  },
  {
    id: 646,
    countryName: 'Rwanda',
    alpha2: 'rw',
    alpha3: 'rwa',
    languageCode: 'rw-RW',
  },
  {
    id: 652,
    countryName: 'Saint Barthélemy',
    alpha2: 'bl',
    alpha3: 'blm',
    languageCode: 'fr-BL',
  },
  {
    id: 654,
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'sh',
    alpha3: 'shn',
    languageCode: 'en-SH',
  },
  {
    id: 659,
    countryName: 'Saint Kitts and Nevis',
    alpha2: 'kn',
    alpha3: 'kna',
    languageCode: 'en-KN',
  },
  {
    id: 662,
    countryName: 'Saint Lucia',
    alpha2: 'lc',
    alpha3: 'lca',
    languageCode: 'en-LC',
  },
  {
    id: 663,
    countryName: 'Saint Martin (French part)',
    alpha2: 'mf',
    alpha3: 'maf',
    languageCode: 'fr-MF',
  },
  {
    id: 666,
    countryName: 'Saint Pierre and Miquelon',
    alpha2: 'pm',
    alpha3: 'spm',
    languageCode: 'fr-PM',
  },
  {
    id: 670,
    countryName: 'Saint Vincent and the Grenadines',
    alpha2: 'vc',
    alpha3: 'vct',
    languageCode: 'en-VC',
  },
  {
    id: 882,
    countryName: 'Samoa',
    alpha2: 'ws',
    alpha3: 'wsm',
    languageCode: 'sm-WS',
  },
  {
    id: 674,
    countryName: 'San Marino',
    alpha2: 'sm',
    alpha3: 'smr',
    languageCode: 'it-SM',
  },
  {
    id: 678,
    countryName: 'Sao Tome and Principe',
    alpha2: 'st',
    alpha3: 'stp',
    languageCode: 'pt-ST',
  },
  {
    id: 682,
    countryName: 'Saudi Arabia',
    alpha2: 'sa',
    alpha3: 'sau',
    languageCode: 'ar-SA',
  },
  {
    id: 686,
    countryName: 'Senegal',
    alpha2: 'sn',
    alpha3: 'sen',
    languageCode: 'fr-SN',
  },
  {
    id: 688,
    countryName: 'Serbia',
    alpha2: 'rs',
    alpha3: 'srb',
    languageCode: 'sr-RS',
  },
  {
    id: 690,
    countryName: 'Seychelles',
    alpha2: 'sc',
    alpha3: 'syc',
    languageCode: 'en-SC',
  },
  {
    id: 694,
    countryName: 'Sierra Leone',
    alpha2: 'sl',
    alpha3: 'sle',
    languageCode: 'en-SL',
  },
  {
    id: 702,
    countryName: 'Singapore',
    alpha2: 'sg',
    alpha3: 'sgp',
    languageCode: 'en-SG',
  },
  {
    id: 534,
    countryName: 'Sint Maarten (Dutch part)',
    alpha2: 'sx',
    alpha3: 'sxm',
    languageCode: 'nl-SX',
  },
  {
    id: 703,
    countryName: 'Slovakia',
    alpha2: 'sk',
    alpha3: 'svk',
    languageCode: 'sk-SK',
  },
  {
    id: 705,
    countryName: 'Slovenia',
    alpha2: 'si',
    alpha3: 'svn',
    languageCode: 'sl-SI',
  },
  {
    id: 90,
    countryName: 'Solomon Islands',
    alpha2: 'sb',
    alpha3: 'slb',
    languageCode: 'en-SB',
  },
  {
    id: 706,
    countryName: 'Somalia',
    alpha2: 'so',
    alpha3: 'som',
    languageCode: 'so-SO',
  },
  {
    id: 710,
    countryName: 'South Africa',
    alpha2: 'za',
    alpha3: 'zaf',
    languageCode: 'en-ZA',
  },
  {
    id: 239,
    countryName: 'South Georgia and the South Sandwich Islands',
    alpha2: 'gs',
    alpha3: 'sgs',
    languageCode: 'en-GS',
  },
  {
    id: 728,
    countryName: 'South Sudan',
    alpha2: 'ss',
    alpha3: 'ssd',
    languageCode: 'en-SS',
  },
  {
    id: 724,
    countryName: 'Spain',
    alpha2: 'es',
    alpha3: 'esp',
    languageCode: 'es-ES',
  },
  {
    id: 144,
    countryName: 'Sri Lanka',
    alpha2: 'lk',
    alpha3: 'lka',
    languageCode: 'si-LK',
  },
  {
    id: 729,
    countryName: 'Sudan',
    alpha2: 'sd',
    alpha3: 'sdn',
    languageCode: 'ar-SD',
  },
  {
    id: 740,
    countryName: 'Suriname',
    alpha2: 'sr',
    alpha3: 'sur',
    languageCode: 'nl-SR',
  },
  {
    id: 744,
    countryName: 'Svalbard and Jan Mayen',
    alpha2: 'sj',
    alpha3: 'sjm',
    languageCode: 'no-SJ',
  },
  {
    id: 752,
    countryName: 'Sweden',
    alpha2: 'se',
    alpha3: 'swe',
    languageCode: 'sv-SE',
  },
  {
    id: 756,
    countryName: 'Switzerland',
    alpha2: 'ch',
    alpha3: 'che',
    languageCode: 'de-CH',
  },
  {
    id: 760,
    countryName: 'Syrian Arab Republic',
    alpha2: 'sy',
    alpha3: 'syr',
    languageCode: 'ar-SY',
  },
  {
    id: 158,
    countryName: 'Taiwan, Province of China',
    alpha2: 'tw',
    alpha3: 'twn',
    languageCode: 'zh-TW',
  },
  {
    id: 762,
    countryName: 'Tajikistan',
    alpha2: 'tj',
    alpha3: 'tjk',
    languageCode: 'tg-TJ',
  },
  {
    id: 834,
    countryName: 'Tanzania, United Republic of',
    alpha2: 'tz',
    alpha3: 'tza',
    languageCode: 'sw-TZ',
  },
  {
    id: 764,
    countryName: 'Thailand',
    alpha2: 'th',
    alpha3: 'tha',
    languageCode: 'th-TH',
  },
  {
    id: 626,
    countryName: 'Timor-Leste',
    alpha2: 'tl',
    alpha3: 'tls',
    languageCode: 'pt-TL',
  },
  {
    id: 768,
    countryName: 'Togo',
    alpha2: 'tg',
    alpha3: 'tgo',
    languageCode: 'fr-TG',
  },
  {
    id: 772,
    countryName: 'Tokelau',
    alpha2: 'tk',
    alpha3: 'tkl',
    languageCode: 'en-TK',
  },
  {
    id: 776,
    countryName: 'Tonga',
    alpha2: 'to',
    alpha3: 'ton',
    languageCode: 'to-TO',
  },
  {
    id: 780,
    countryName: 'Trinidad and Tobago',
    alpha2: 'tt',
    alpha3: 'tto',
    languageCode: 'en-TT',
  },
  {
    id: 788,
    countryName: 'Tunisia',
    alpha2: 'tn',
    alpha3: 'tun',
    languageCode: 'ar-TN',
  },
  {
    id: 792,
    countryName: 'Turkey',
    alpha2: 'tr',
    alpha3: 'tur',
    languageCode: 'tr-TR',
  },
  {
    id: 795,
    countryName: 'Turkmenistan',
    alpha2: 'tm',
    alpha3: 'tkm',
    languageCode: 'tk-TM',
  },
  {
    id: 796,
    countryName: 'Turks and Caicos Islands',
    alpha2: 'tc',
    alpha3: 'tca',
    languageCode: 'en-TC',
  },
  {
    id: 798,
    countryName: 'Tuvalu',
    alpha2: 'tv',
    alpha3: 'tuv',
    languageCode: 'en-TV',
  },
  {
    id: 800,
    countryName: 'Uganda',
    alpha2: 'ug',
    alpha3: 'uga',
    languageCode: 'en-UG',
  },
  {
    id: 804,
    countryName: 'Ukraine',
    alpha2: 'ua',
    alpha3: 'ukr',
    languageCode: 'uk-UA',
  },
  {
    id: 784,
    countryName: 'United Arab Emirates',
    alpha2: 'ae',
    alpha3: 'are',
    languageCode: 'ar-AE',
  },
  {
    id: 826,
    countryName: 'United Kingdom',
    alpha2: 'gb',
    alpha3: 'gbr',
    languageCode: 'en-GB',
  },
  {
    id: 840,
    countryName: 'United States',
    alpha2: 'us',
    alpha3: 'usa',
    languageCode: 'en-US',
  },
  {
    id: 581,
    countryName: 'United States Minor Outlying Islands',
    alpha2: 'um',
    alpha3: 'umi',
    languageCode: 'en-UM',
  },
  {
    id: 858,
    countryName: 'Uruguay',
    alpha2: 'uy',
    alpha3: 'ury',
    languageCode: 'es-UY',
  },
  {
    id: 860,
    countryName: 'Uzbekistan',
    alpha2: 'uz',
    alpha3: 'uzb',
    languageCode: 'uz-UZ',
  },
  {
    id: 548,
    countryName: 'Vanuatu',
    alpha2: 'vu',
    alpha3: 'vut',
    languageCode: 'bi-VU',
  },
  {
    id: 862,
    countryName: 'Venezuela, Bolivarian Republic of',
    alpha2: 've',
    alpha3: 'ven',
    languageCode: 'es-VE',
  },
  {
    id: 704,
    countryName: 'Viet Nam',
    alpha2: 'vn',
    alpha3: 'vnm',
    languageCode: 'vi-VN',
  },
  {
    id: 92,
    countryName: 'Virgin Islands, British',
    alpha2: 'vg',
    alpha3: 'vgb',
    languageCode: 'en-VG',
  },
  {
    id: 850,
    countryName: 'Virgin Islands, U.S.',
    alpha2: 'vi',
    alpha3: 'vir',
    languageCode: 'en-VI',
  },
  {
    id: 876,
    countryName: 'Wallis and Futuna',
    alpha2: 'wf',
    alpha3: 'wlf',
    languageCode: 'fr-WF',
  },
  {
    id: 732,
    countryName: 'Western Sahara',
    alpha2: 'eh',
    alpha3: 'esh',
    languageCode: 'ar-EH',
  },
  {
    id: 887,
    countryName: 'Yemen',
    alpha2: 'ye',
    alpha3: 'yem',
    languageCode: 'ar-YE',
  },
  {
    id: 894,
    countryName: 'Zambia',
    alpha2: 'zm',
    alpha3: 'zmb',
    languageCode: 'en-ZM',
  },
  {
    id: 716,
    countryName: 'Zimbabwe',
    alpha2: 'zw',
    alpha3: 'zwe',
    languageCode: 'en-ZW',
  },
];

export const countryNames = countries.map(country => country.countryName);
