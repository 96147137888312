import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { getScore } from 'services/omniRepository';
import { toImage, toPdf } from '../../../utils/utils';
import api from '../../../utils/api';
import { isMimeTypePDF } from '../../../utils/utils/helpers';
import { env } from '../../../env';

import warning from 'assets/img/warning.svg';
import passed from 'assets/img/passed.svg';
import unknown from 'assets/img/unknown.svg';
import failed from 'assets/img/failed.svg';
import idCard from 'assets/img/id-card-black.svg';
import liveness from 'assets/img/liveness.svg';
import recognition from 'assets/img/recognition.svg';
import videoconference from 'assets/img/videoconference.svg';
import poa from 'assets/img/poa.svg';
import placeholderIcon from 'assets/img/placeholder-icon.svg';
import { throwError } from 'rxjs';
import StickyFooter from './StickyFooter';
import FormItem from './FormItem';
import ManualIndicator from './ManualIndicator';

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: calc(100vh - 120px);
  background-color: #fff;
  padding: 40px;
  align-items: center;
  position: relative;
  overflow-y: auto;
`;

const RootContainer = styled.div`
  height: auto;
  position: relative;
`;

const IdContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
  padding: 0 30px;
  min-height: 125px;
`;

const CroppedId = styled.img`
  height: 125px;
  min-width: 40%;
  border-radius: 8px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 50px;
`;

const NumberParagraph = styled.p`
  font-family: $CircularStd-Book;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.09px;
  text-align: center;
  color: #dfdfdf;
  margin: 0 5px;
`;

const BarOuter = styled.div`
  margin: 10px 0;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15px;
  min-height: 15px;
  border-radius: 50px;
  background-color: #dfdfdf;
  background-image: linear-gradient(
    to right,
    #ff5c6f 12%,
    #eeb12d 61%,
    #0cd5a2 88%
  );
`;

const BarInner = styled.div`
  &.transparent {
    width: 30%;
    height: inherit;
    background-color: transparent;
    border-radius: 0 6px 6px 0;
    width: ${props => props.scoreOver}%;
  }

  &.gray {
    height: inherit;
    background-color: #ccc;
    border-radius: 0 6px 6px 0;
    width: ${props => props.scoreOverMinus}%;
  }
`;

const Indicator = styled.div`
  position: absolute;
  width: 75px;
  height: 35px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: ${({ status }) => getResultColorFromStatus(status)};
  border: ${({ status }) => (status === 'MANUAL' ? 'solid 3px #edaf2e' : '')};
  color: ${({ status }) => (status === 'MANUAL' ? '#edaf2e' : '#ffffff')};
  left: calc(${({ scoreOver }) => scoreOver}% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  font-size: 15px;
`;

const IndicatorImage = styled.img`
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;

const IndicatorText = styled.p`
  color: inherit;
  font-size: 10px;
  font-family: 'CircularStd-Medium';
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 30px;
  margin: 10px 0;
  min-height: 60px;
  align-items: center;
`;

const ModuleTitle = styled.h2`
  font-family: 'CircularStd-Medium';
  font-weight: bold;
  color: #20263d;
`;

const ResultContainer = styled.div`
  background-color: ${({ status }) => getResultColorFromStatus(status)};
  border: ${({ status }) => (status === 'MANUAL' ? 'solid 3px #edaf2e' : '')};
  color: ${({ status }) => (status === 'MANUAL' ? '#edaf2e' : '#ffffff')};
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 60px;
  width: 175px;
  flex-direction: column;
  padding-left: 15px;
`;

const ResultTitle = styled.p`
  opacity: 0.8;
  font-family: CircularStd-Bold;
  font-size: 10px;
  font-weight: bold;
  color: inherit;
  text-transform: uppercase;
`;

const Result = styled.p`
  font-family: CircularStd-Bold;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
`;

function getResultColorFromStatus(status) {
  switch (status) {
    case 'WARN':
      return '#f6ab2f';
    case 'MANUAL':
      return '#ffffff';
    case 'FAIL':
      return '#ff5c6f';
    case 'OK':
      return '#0cd5a2';
    default:
      return '#cccccc';
  }
}

const ModuleSubtitle = styled.h3`
  margin: 15px 0;
  color: #20263d;
  text-align: left;
  font-family: 'CircularStd-Bold';
  width: 100%;

  &.independent {
    padding: 0 30px;
  }
`;

const InfoRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 50px;
  min-height: 50px;
  border-bottom: solid 1px rgba(15, 73, 130, 0.1);
  align-items: center;
  font-size: 14px;

  &:last-of-type {
    border-bottom: none;
  }
`;

const Index = styled.div`
  width: 10px;
  margin: 0 10px;
`;

const ItemName = styled.div`
  width: 180px;
`;

const StatusImage = styled.img`
  width: 20px;
  margin: 0 10px;
`;

const InfoRowGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

function InfoRow({ status, keyName, index }) {
  return (
    <InfoRowContainer>
      <InfoRowGroup>
        <Index>{index + 1}</Index>
        <ItemName>{keyName}</ItemName>
      </InfoRowGroup>
      <InfoRowGroup>
        {(() => {
          switch (status) {
            case 'OK':
              return <StatusImage src={passed} />;
            case 'WARN':
            case 'MANUAL':
              return <StatusImage src={warning} />;
            case 'FAIL':
              return <StatusImage src={failed} />;
            default:
              return <StatusImage src={unknown} />;
          }
        })()}
      </InfoRowGroup>
    </InfoRowContainer>
  );
}

function LivenessInfoRow({ status, keyName, index }) {
  if (keyName.includes('overall')) return null;

  return (
    <InfoRowContainer>
      <InfoRowGroup>
        <Index>{index + 1}</Index>
        <ItemName>{keyName}</ItemName>
      </InfoRowGroup>
      <InfoRowGroup>
        {(() => {
          switch (status) {
            case 'OK':
              return <StatusImage src={passed} />;
            case 'MANUAL':
            case 'WARN':
              return <StatusImage src={warning} />;
            case 'FAIL':
              return <StatusImage src={failed} />;
            default:
              return <StatusImage src={unknown} />;
          }
        })()}
      </InfoRowGroup>
    </InfoRowContainer>
  );
}

const HorizontalMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  min-height: 80px;
  width: 100%;
  margin: 10px 0;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 0 15px;
  justify-content: space-between;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 60px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  &.active {
    width: 140px;
    opacity: 1;
    margin: 0 10px;
  }
`;

const MenuItemIcon = styled.img`
  width: 35px;
  height: 35px;
`;

const MenuItemName = styled.p`
  font-size: 14px;
  color: #000;
  margin-left: 10px;
`;

function HorizontalMenu({ currentView, handleViewChange }) {
  const { t } = useTranslation();

  return (
    <HorizontalMenuContainer>
      <MenuItem
        className={cx({ active: currentView === 'id-validation' })}
        onClick={() => handleViewChange('id-validation')}
      >
        <MenuItemIcon src={idCard} />
        {currentView === 'id-validation' && (
          <MenuItemName>{t('idValidation')}</MenuItemName>
        )}
      </MenuItem>
      <MenuItem
        className={cx({ active: currentView === 'liveness' })}
        onClick={() => handleViewChange('liveness')}
      >
        <MenuItemIcon src={liveness} />
        {currentView === 'liveness' && (
          <MenuItemName>{t('livenessDetection')}</MenuItemName>
        )}
      </MenuItem>
      <MenuItem
        className={cx({ active: currentView === 'recognition' })}
        onClick={() => handleViewChange('recognition')}
      >
        <MenuItemIcon src={recognition} />
        {currentView === 'recognition' && (
          <MenuItemName>{t('faceRecognition')}</MenuItemName>
        )}
      </MenuItem>
      <MenuItem
        className={cx({ active: currentView === 'videoconference' })}
        onClick={() => handleViewChange('videoconference')}
      >
        <MenuItemIcon src={videoconference} />
        {currentView === 'videoconference' && (
          <MenuItemName>{t('videoConference')}</MenuItemName>
        )}
      </MenuItem>
      <MenuItem
        className={cx({ active: currentView === 'poa' })}
        onClick={() => handleViewChange('poa')}
      >
        <MenuItemIcon src={poa} />
        {currentView === 'poa' && <MenuItemName>{t('poa')}</MenuItemName>}
      </MenuItem>
    </HorizontalMenuContainer>
  );
}

const SubtitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  min-height: 15px;
`;

const Subtitle = styled.p`
  height: 15px;
  font-family: 'CircularStd-Medium';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.06px;
  color: #ff5c6f;
  opacity: 0.5;

  &.approve {
    color: #0cd5a2;
  }

  &.suggested {
    opacity: 1;
  }
`;

const InfoTable = styled.div`
  width: 100%;
  padding: 0 30px;

  &.last {
    margin-bottom: 50px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-left: 25px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionTitle = styled.h4`
  color: #20263d;
  margin-bottom: 15px;
  font-family: $CircularStd-Bold;
`;

const MatchedLabel = styled.p`
  color: #000;
  font-size: 12px;
  font-weight: lighter;
  margin-bottom: 10px;
`;

const StatusIcon = styled.img`
  height: 25px;
`;

function getObjectFromFaceRecognitionStatus(status, value) {
  switch (status) {
    case 'OK':
      return {
        title: `PASSED ${value}`,
        subtitle: i18n.t('matched'),
        image: passed,
      };
    case 'WARN':
      return {
        title: 'WARN',
        subtitle: i18n.t('needsReview'),
        image: warning,
      };
    case 'FAIL':
      return {
        title: `FAILED ${value}`,
        subtitle: i18n.t('failed'),
        image: failed,
      };
    case 'MANUAL':
      return {
        title: 'NEEDS REVIEW',
        subtitle: i18n.t('needsReview'),
        image: warning,
      };
    default:
      return {
        title: 'N/A',
        subtitle: 'N/A',
        image: failed,
      };
  }
}

function getStringFromStatus(status, value = '') {
  switch (status) {
    case 'OK':
      return `${value} ${i18n.t('passed')}`;
    case 'WARN':
      return `${value} ${i18n.t('warning')}`;
    case 'FAIL':
      return `${value} ${i18n.t('failed')}`;
    case 'MANUAL':
      return i18n.t('needsReview');
    default:
      return 'N/A';
  }
}

const scoreCache = {};

function IdentityScore({
  interviewId,
  token,
  frontId,
  backId,
  selfie,
  closeSession,
  takenSelfie,
  croppedIdFace,
  frontConfidence,
  selfieConfidence,
  takenFront,
  document,
  poaName,
  proofOfAddress,
  manualIdCheckNeeded,
  manualSelfieCheckNeeded,
}) {
  const { t } = useTranslation();
  const [disableButtons, setDisableButtons] = useState(false);
  const [{ score, status }, setScore] = useState(() => ({
    status: scoreCache[interviewId] ? 'fulfilled' : 'pending',
    score: scoreCache[interviewId],
  }));
  const [currentView, setCurrentView] = useState('id-validation');
  const noMatch = manualIdCheckNeeded || manualSelfieCheckNeeded;

  useEffect(() => {
    if (score) {
      return;
    }

    if (!interviewId) {
      return;
    }

    getScore(interviewId, { token }).then(score => {
      scoreCache[interviewId] = score;
      setScore({
        score,
        status: 'fulfilled',
      });
    });
  }, [interviewId, token, score]);

  function handleViewChange(view) {
    setCurrentView(view);
  }

  async function handleApproveSession() {
    setDisableButtons(true);
    try {
      await api
        .post(
          `${env.REACT_APP_USER_SERVER}/omni/process/approve?interviewId=${interviewId}`,
          null,
          {
            token,
          },
        )
        .then(() => {
          closeSession('approve');
        });
    } catch (e) {
      throwError(e);
      setDisableButtons(false);
    }
  }

  if (status === 'pending') {
    return <ModuleContainer />;
  }

  return (
    <RootContainer>
      <ModuleContainer>
        <BarContainer>
          <NumberParagraph>0</NumberParagraph>
          <BarOuter>
            <Indicator
              scoreOver={score?.overall?.value || 0}
              status={score?.overall?.status}
            >
              <IndicatorImage
                src={croppedIdFace ? toImage(croppedIdFace) : placeholderIcon}
              />
              {score?.overall?.status === 'MANUAL' ? (
                <IndicatorText>NEEDS REVIEW</IndicatorText>
              ) : (
                score?.overall?.value
              )}
            </Indicator>
            <BarInner
              className="transparent"
              scoreOver={score?.overall?.value || 0}
            />
            <BarInner
              className="gray"
              scoreOverMinus={100 - (score?.overall?.value || 0)}
            />
          </BarOuter>
          <NumberParagraph>100</NumberParagraph>
        </BarContainer>
        <SubtitlesContainer>
          <Subtitle className={cx({ suggested: score?.overall?.value < 75 })}>
            {t('rejectApplicant')}
          </Subtitle>
          <Subtitle
            className={cx('approve', {
              suggested: score?.overall?.value >= 75,
            })}
          >
            {t('approveApplicant')}
          </Subtitle>
        </SubtitlesContainer>
        <HorizontalMenu
          currentView={currentView}
          handleViewChange={handleViewChange}
        />
        {(() => {
          switch (currentView) {
            case 'id-validation':
              return (
                <>
                  <TitleContainer>
                    <ModuleTitle>{t('idPhotoCapture')}</ModuleTitle>
                    <ResultContainer
                      status={score?.idValidation?.overall?.status}
                    >
                      <ResultTitle>{t('idVerificationScore')}</ResultTitle>
                      <Result>
                        {getStringFromStatus(
                          score?.idValidation?.overall?.status,
                          score?.idValidation?.overall?.value,
                        )}
                      </Result>
                    </ResultContainer>
                  </TitleContainer>
                  {(score?.idValidation?.overall?.status === 'MANUAL' ||
                    noMatch) && <ManualIndicator />}
                  <IdContainer>
                    {frontId && <CroppedId src={toImage(frontId)} />}
                    {backId && <CroppedId src={toImage(backId)} />}
                  </IdContainer>
                  <InfoTable>
                    <ModuleSubtitle>{t('photoSecurity')}</ModuleSubtitle>
                    {score?.idValidation?.photoSecurityAndQuality?.map(
                      (item, i) => (
                        <InfoRow
                          key={i}
                          status={item.status}
                          keyName={t(
                            `photoQualityAndSecurityTests.${item.key}`,
                          )}
                          index={i}
                        />
                      ),
                    )}
                  </InfoTable>
                  <InfoTable className="last">
                    <ModuleSubtitle>{t('idSpecific')}</ModuleSubtitle>
                    {score?.idValidation?.idSpecific?.map((item, i) => (
                      <InfoRow
                        key={i}
                        status={item.status}
                        keyName={t(`idSpecificTests.${item.key}`)}
                        index={i}
                      />
                    ))}
                  </InfoTable>
                </>
              );
            case 'liveness':
              return (
                score?.liveness && (
                  <>
                    <TitleContainer>
                      <ModuleTitle>
                        {t('livenessDetectionModule.livenessDetection')}
                      </ModuleTitle>
                      <ResultContainer
                        status={score?.liveness?.overall?.status}
                      >
                        <ResultTitle>
                          {t('livenessDetectionModule.livenessScore')}
                        </ResultTitle>
                        <Result>
                          {getStringFromStatus(
                            score?.liveness?.overall?.status,
                            score?.liveness?.overall?.value,
                          )}
                        </Result>
                      </ResultContainer>
                    </TitleContainer>
                    {(score?.liveness?.overall?.status === 'MANUAL' ||
                      noMatch) && <ManualIndicator />}
                    <InfoTable>
                      <ModuleSubtitle>
                        {t('livenessDetectionModule.scoreAndQuality')}
                      </ModuleSubtitle>
                      {Object.keys(score?.liveness).map((item, i) => (
                        <LivenessInfoRow
                          key={i}
                          status={score?.liveness?.overall?.status}
                          keyName={t(`livenessTests.${item}`)}
                          index={i}
                        />
                      ))}
                    </InfoTable>
                  </>
                )
              );
            case 'recognition':
              return (
                <>
                  <TitleContainer>
                    <ModuleTitle>
                      {t('faceRecognitionModule.faceRecognition')}
                    </ModuleTitle>
                    <ResultContainer
                      status={score?.faceRecognition?.overall?.status}
                    >
                      <ResultTitle>
                        {t('faceRecognitionModule.faceRecognitionScore')}
                      </ResultTitle>
                      <Result>
                        {getStringFromStatus(
                          score?.faceRecognition?.overall?.status,
                          score?.faceRecognition?.overall?.value,
                        )}
                      </Result>
                    </ResultContainer>
                  </TitleContainer>
                  <ImageRow className="last">
                    <ImagesContainer>
                      <ImageContainer>
                        <ImageTitle>ONBOARDING</ImageTitle>
                        {selfie ? (
                          <Image src={toImage(selfie)} />
                        ) : (
                          <NoImage>?</NoImage>
                        )}
                      </ImageContainer>
                      <ImageSeparator />
                      <ImageContainer>
                        <ImageTitle>
                          {t('faceRecognitionModule.governmentId')}
                        </ImageTitle>
                        {croppedIdFace ? (
                          <Image src={toImage(croppedIdFace)} />
                        ) : (
                          <NoImage>?</NoImage>
                        )}
                      </ImageContainer>
                    </ImagesContainer>
                    <ActionContainer>
                      <ActionTitle>{t('photoOfCustomer')}</ActionTitle>
                      <MatchedLabel>
                        {manualIdCheckNeeded
                          ? t('faceRecognitionModule.matchUnable')
                          : getObjectFromFaceRecognitionStatus(
                              score?.faceRecognition?.overall?.status,
                            )?.subtitle}
                      </MatchedLabel>
                      <StatusIcon
                        src={
                          manualIdCheckNeeded
                            ? warning
                            : getObjectFromFaceRecognitionStatus(
                                score?.faceRecognition?.overall?.status,
                              )?.image
                        }
                        alt="check"
                      />
                    </ActionContainer>
                  </ImageRow>
                  {(score?.faceRecognition?.overall?.status === 'MANUAL' ||
                    noMatch) && <ManualIndicator origin="face-recognition" />}
                  <Paragraph>{`${t('alreadyInDb')} ${
                    score?.faceRecognition?.existingUser ? 'Yes' : 'No'
                  }`}</Paragraph>
                </>
              );
            case 'videoconference':
              return (
                <>
                  <TitleContainer>
                    <ModuleTitle>
                      {t('videoconferenceModule.videoconference')}
                    </ModuleTitle>
                  </TitleContainer>
                  <ImageRow>
                    <ImagesContainer>
                      <ImageContainer>
                        <ImageTitle>ID ONBOARDING</ImageTitle>
                        {frontId ? (
                          <Image className="id" src={toImage(frontId)} />
                        ) : (
                          <NoImage>?</NoImage>
                        )}
                      </ImageContainer>
                      <ImageSeparator />
                      <ImageContainer>
                        <ImageTitle>
                          {' '}
                          {t('videoconferenceModule.idConference')}
                        </ImageTitle>
                        {takenFront ? (
                          <Image src={toImage(takenFront)} />
                        ) : (
                          <NoImage>?</NoImage>
                        )}
                      </ImageContainer>
                    </ImagesContainer>
                    <ActionContainer>
                      <ActionTitle>ID</ActionTitle>
                      <MatchedLabel>
                        {manualIdCheckNeeded
                          ? t('videoconferenceModule.matchUnable')
                          : `${t('matched')} ${Math.round(
                              Math.max(0, frontConfidence) * 100,
                            )}%`}
                      </MatchedLabel>
                      <StatusIcon
                        src={manualIdCheckNeeded ? warning : passed}
                        alt="check"
                      />
                    </ActionContainer>
                  </ImageRow>
                  <ImageRow className="last">
                    <ImagesContainer>
                      <ImageContainer>
                        <ImageTitle>SELFIE ONBOARDING</ImageTitle>
                        {selfie ? (
                          <Image src={toImage(selfie)} />
                        ) : (
                          <NoImage>?</NoImage>
                        )}
                      </ImageContainer>
                      <ImageSeparator />
                      <ImageContainer>
                        <ImageTitle>SELFIE CONFERENCE</ImageTitle>
                        {takenSelfie ? (
                          <Image src={toImage(takenSelfie)} />
                        ) : (
                          <NoImage>?</NoImage>
                        )}
                      </ImageContainer>
                    </ImagesContainer>
                    <ActionContainer>
                      <ActionTitle>Selfie</ActionTitle>
                      <MatchedLabel>
                        {manualSelfieCheckNeeded
                          ? t('videoconferenceModule.matchUnable')
                          : `${t('matched')} ${Math.round(
                              Math.max(0, selfieConfidence) * 100,
                            )}%`}
                      </MatchedLabel>
                      <StatusIcon
                        src={manualSelfieCheckNeeded ? warning : passed}
                        alt="check"
                      />
                    </ActionContainer>
                  </ImageRow>
                </>
              );
            case 'poa':
              return (
                <>
                  <TitleContainer>
                    <ModuleTitle>
                      {t('proofOfAddressModule.proofOfAddress')}
                    </ModuleTitle>
                  </TitleContainer>
                  {document &&
                    (isMimeTypePDF(document) ? (
                      <PoaPdf data={toPdf(document)} />
                    ) : (
                      <PoaImage src={toImage(document)} />
                    ))}
                  <FormItem
                    name="name"
                    title={t('fullName')}
                    data={poaName}
                  ></FormItem>
                  <FormItem
                    name="address"
                    title={t('proofOfAddressModule.addressFromDocument')}
                    data={proofOfAddress}
                  ></FormItem>
                </>
              );
            case 'FAIL':
              return <StatusImage src={unknown} />;
            default:
              return null;
          }
        })()}
      </ModuleContainer>
      <StickyFooter
        score={score?.overall?.value}
        handleApproveSession={handleApproveSession}
        closeSession={closeSession}
        disabled={disableButtons}
      />
    </RootContainer>
  );
}

const Paragraph = styled.p`
  font-size: 15px;
  color: #20263d;
  margin-top: 40px;
  width: 100%;
  padding: 0 30px;
`;

const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: solid 1px rgba(15, 73, 130, 0.1);
  padding: 0 30px;
  margin-top: 40px;
  padding-bottom: 40px;
  justify-content: flex-start;

  &.last {
    border-bottom: none;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 150px;
  justify-content: space-between;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #000;

  &.id {
    object-fit: contain;
  }
`;

const poaImageStyle = css`
  height: 225px;
  border-radius: 5px;
  align-self: start;
  margin: 15px 0 15px 30px;
`;

const PoaImage = styled.img`
  ${poaImageStyle}
`;

const PoaPdf = styled.object`
  ${poaImageStyle}
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  border: 3px solid #000;
  font-size: 48px;
`;

const ImageSeparator = styled.div`
  width: 20px;
  height: 4px;
  align-self: center;
  margin: 50px 2px 0 2px;
  border-radius: 2px;
  background-color: #20263d;
`;

const ImageTitle = styled.p`
  font-family: 'CircularStd-Book';
  color: #20263d;
  margin-bottom: 20px;
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  overflow: hidden;
  text-transform: uppercase;
`;

export default IdentityScore;
