import { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { isObjectEmpty } from '../../../utils/utils';
import { env } from '../../../env';

import smsButton from 'assets/img/sms-icon-landing.svg';
import checkIcon from 'assets/img/blue-check.svg';
import spinner from 'assets/img/spinner.svg';
import TickTock from './TickTock';

const fadeIn = keyframes`
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
`;

const ConnectingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 120px);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const AlertsContainer = styled.button`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 201;
  background-color: #00aeb1;
  top: 20px;
  right: 20px;
  width: 225px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  opacity: 1;

  &:disabled {
    background-color: #757575;
    cursor: auto;
    opacity: 0.7;
  }
`;

const SmsButton = styled.div`
  border: none;
  background: none;

  img {
    height: 40px;
  }
`;

const LoadingCircle = styled.div`
  width: 345px;
  height: 345px;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(#000, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-color: rgba(28, 31, 40, 0.1);
`;

const Image = styled.img`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  animation: spinner 0.6s linear infinite;
`;

const Title = styled.h1`
  font-family: 'CircularStd-Black';
  font-size: 36px;
`;

const ConnectingParagraph = styled.p`
  font-family: 'CircularStd-Book';
  font-size: 18px;
  margin: 20px 0;
  padding: 0 50px;
`;

const AlertParagraph = styled.p`
  font-size: 16px;
  margin: 0;
  font-family: 'CircularStd-Bold';
`;

const Paragraph = styled.p`
  font-size: 18px;
  margin: 0 20px;
`;

const ArrowBox = styled.div`
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 30px;
  background-color: white;
  color: #fff;
  position: absolute;
  float: left;
  border-radius: 10px;
  top: 90px;
  right: 90px;
  opacity: 0;
  animation: ${fadeIn} ease 5s;

  :after {
    content: ' ';
    position: absolute;
    right: 35px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 20px solid white;
  }
`;

const SentLabel = styled.p`
  color: black;
  font-size: 14px;
`;

const SentImage = styled.img`
  height: 16px;
`;

const OuterCircle = styled.div`
  background-color: white;
  width: 300px;
  height: 300px;
  position: absolute;
  opacity: 0.1;
  border-radius: 50%;
`;

const InnerCircle = styled.div`
  background-color: white;
  width: 246px;
  height: 246px;
  position: absolute;
  opacity: 0.1;
  border-radius: 50%;
`;

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  z-index: 201;
  padding: 16px;
  padding-bottom: 40px;
`;

function Connecting({ sendSMS, token, interviewId, connectivityEvents }) {
  const { t } = useTranslation();
  const [smsDisabled, setSmsDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const timesSmsSent = useRef(0);

  const timeOutSms = () => {
    setSmsDisabled(true);
    setTimeout(() => {
      setSmsDisabled(false);
      setShowNotification(true);
    }, 20000);
  };

  const sendSmsRequest = async () => {
    const urlParams = window.location.pathname.split('/');
    const clientId = urlParams[1];
    const url = `${env.REACT_APP_ONBOARDING_LINK}${
      clientId ? '/' + clientId : ''
    }/conference`;
    await sendSMS(url, interviewId, token);
    timesSmsSent.current = timesSmsSent.current + 1;
    setShowNotification(true);
    timeOutSms();
  };

  return (
    <ConnectingContainer>
      <AlertsContainer onClick={sendSmsRequest} disabled={smsDisabled}>
        <SmsButton disabled={smsDisabled}>
          <img src={smsButton} alt="send sms button" />
        </SmsButton>
        <AlertParagraph>{t('sendSMS')}</AlertParagraph>
      </AlertsContainer>
      {showNotification && (
        <ArrowBox
          key={timesSmsSent.current}
          showNotification={showNotification}
        >
          <SentImage src={checkIcon} alt="check" />
          <SentLabel>{t('sent')}</SentLabel>
        </ArrowBox>
      )}
      <LoadingCircle>
        <OuterCircle />
        <InnerCircle />
        <Image src={spinner} alt="spinner" />
        <Title>{t('hangOn')}</Title>
        {!isObjectEmpty(connectivityEvents) && (
          <>
            <ConnectingParagraph>{t('customerNetwork')}</ConnectingParagraph>
            <TickTock />
          </>
        )}
      </LoadingCircle>
      <EventsContainer>
        {connectivityEvents.map((connectivityEvent, index) => (
          <Paragraph key={index}> {connectivityEvent} </Paragraph>
        ))}
      </EventsContainer>
    </ConnectingContainer>
  );
}

export default Connecting;
