import forge from 'node-forge';

import api from './api';

const getPublicKeyUrl = () => `/e2ee/key/v2`;

const postPublicKeyUrl = () => `/e2ee/key`;

function getPublicKey() {
  return api.get(getPublicKeyUrl()).then(response => response.data);
}

function encryptRsaOaepSha1Mgf1(publicKey, data) {
  const encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });

  return forge.util.bytesToHex(encrypted);
}

function encryptAesGcm(secretKey, data) {
  try {
    const iv = forge.random.getBytesSync(12);
    const cipher = forge.cipher.createCipher('AES-GCM', secretKey);
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(JSON.stringify(data)));
    cipher.finish();

    const encrypted = cipher.output.toHex();
    const tag = cipher.mode.tag.toHex();
    const ivHex = forge.util.bytesToHex(iv);

    return { encryptedData: encrypted + tag, iv: ivHex };
  } catch (err) {
    throw new Error(err);
  }
}

function decryptAesGcm(secretKey, data) {
  try {
    const iv = forge.util.hexToBytes(data.iv);
    const tag = forge.util.hexToBytes(data.encryptedData.slice(-32));
    const decipher = forge.cipher.createDecipher('AES-GCM', secretKey);
    decipher.start({ iv, tag });
    decipher.update(
      forge.util.createBuffer(
        forge.util.hexToBytes(data.encryptedData.slice(0, -32)),
      ),
    );
    decipher.finish();

    const decrypted = decipher.output;
    return forge.util.decodeUtf8(decrypted.data);
  } catch (err) {
    throw new Error(err);
  }
}

function postPublicKey(encryptedKey, encryptedIv, sessionId, token) {
  return api
    .post(
      postPublicKeyUrl(),
      {
        encryptedKey,
        encryptedIv,
      },
      {
        headers: {
          sessionId,
          'X-Incode-Hardware-Id': token,
        },
      },
    )
    .then(response => response.data);
}

const allowList = ['/executive', '/onboarding/manualcorrection'];
function shouldSkipEncrypt(url) {
  return allowList.some(prefix => url.startsWith(prefix));
}
export default async function publishKeys(token) {
  const secretKey = forge.random.getBytesSync(32);
  const iv = forge.random.getBytesSync(16);

  const publicKeyData = await getPublicKey();
  const publicKeyPem = `-----BEGIN PUBLIC KEY-----\n${publicKeyData.publicKey}\n-----END PUBLIC KEY-----`;
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encryptedKey = encryptRsaOaepSha1Mgf1(publicKey, secretKey);
  const encryptedIv = encryptRsaOaepSha1Mgf1(publicKey, iv);
  const sessionId = publicKeyData.sessionId;

  const postStatus = await postPublicKey(
    encryptedKey,
    encryptedIv,
    sessionId,
    token,
  );

  api.instance.interceptors.request.use(
    function (config) {
      if (shouldSkipEncrypt(config.url)) {
        return config;
      }
      config.headers.sessionid = sessionId;
      const encrypted = encryptAesGcm(secretKey, config.data);
      config.data = encrypted;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  api.instance.interceptors.response.use(
    function (response) {
      if (response.data.encryptedData) {
        const decrypted = decryptAesGcm(secretKey, response.data);
        response.data = JSON.parse(decrypted);
      }

      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return postStatus;
}
