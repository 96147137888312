import {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';

import Messages from './Messages';
import MessageForm from './MessageForm';
import { useEventListener } from '../../../utils/hooks';
import readMessage from 'assets/img/readMessage.svg';
const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  border: solid 1px white;
  background-color: #fff;
  padding: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NewMessage = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
  position: absolute;
  transform: translateY(-50%);
  bottom: 40px;
  width: 187px;
  height: 56px;
  padding: 17px 19px 15px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #cccccc;
  background-color: #ffffff;
`;

const Chat = ({ session, messages }) => {
  const inputRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const receivedMessagesRef = useRef([]);
  const [unReadMessages, setUnReadMessages] = useState(0);
  const [messageSentByInterviewer, setMessageSentByInterviewer] =
    useState(false);
  useEventListener('scroll', handleScroll, messagesContainerRef.current);
  const markMessagesAsRead = useCallback(() => {
    receivedMessagesRef.current.forEach((_, index) => {
      receivedMessagesRef.current[index].read = true;
    });
    setUnReadMessages(0);
    scrollToBottom();
  }, []);

  useLayoutEffect(() => {
    function handleUnreadMessages() {
      if (receivedMessagesRef.current.length > 0) {
        const filterNewMessages = messages.filter(
          message =>
            message.author === 'user' &&
            !receivedMessagesRef.current.some(
              messageRef => messageRef.date === message.date,
            ),
        );

        if (filterNewMessages.length > 0) {
          receivedMessagesRef.current = [
            ...receivedMessagesRef.current,
            ...filterNewMessages,
          ];
        }
      } else {
        receivedMessagesRef.current = messages.filter(
          message => message.author === 'user',
        );
      }
      const unreadMessages = receivedMessagesRef.current.reduce(
        (acc, current) => {
          if (!current.read) {
            acc += 1;
          }
          return acc;
        },
        0,
      );

      setUnReadMessages(unreadMessages);
      if (
        unreadMessages === 0 ||
        messageSentByInterviewer ||
        (unreadMessages > 0 &&
          thresholdReached(messagesContainerRef.current.scrollTop))
      ) {
        markMessagesAsRead();
        setMessageSentByInterviewer(false);
      }
    }
    handleUnreadMessages();
  }, [messages, markMessagesAsRead, messageSentByInterviewer]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const scrollToBottom = () => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  };

  const handleSendMessage = message => {
    session.signal({
      type: 'msg',
      data: JSON.stringify({ message, author: 'interviewer' }),
    });
    setMessageSentByInterviewer(true);
  };

  function thresholdReached(scrollTop) {
    const height =
      messagesContainerRef.current.scrollHeight -
      messagesContainerRef.current.clientHeight;
    return height === scrollTop;
  }

  function handleScroll(event) {
    const {
      target: { scrollTop },
    } = event;
    if (thresholdReached(scrollTop)) {
      markMessagesAsRead();
    }
  }
  return (
    <ModuleContainer>
      <Messages messages={messages} ref={messagesContainerRef} />
      <MessageForm onSubmit={handleSendMessage} ref={inputRef} />
      {unReadMessages > 0 && (
        <NewMessage onClick={markMessagesAsRead}>
          <img src={readMessage} alt="" />
          <span>{`${unReadMessages} new message(s)`}</span>
        </NewMessage>
      )}
    </ModuleContainer>
  );
};

export default Chat;
