import { countryNames } from './countries';

export const DocumentTypes = {
  Unknown: 'Unknown',
  Passport: 'Passport',
  Visa: 'Visa',
  DriversLicense: 'Drivers License',
  IdentificationCard: 'Identification Card',
  Permit: 'Permit',
  Currency: 'Currency',
  ResidenceDocument: 'Residence Document',
  TravelDocument: 'Travel Document',
  BirthCertificate: 'Birth Certificate',
  VehicleRegistration: 'Vehicle Registration',
  Other: 'Other',
  WeaponLicense: 'Weapon License',
  TribalIdentification: 'Tribal Identification',
  VoterIdentification: 'Voter Identification',
  Military: 'Military',
  TaxIdentification: 'Tax Identification',
  FederalID: 'Federal ID',
  MedicalCard: 'Medical Card',
};
export const OPERATORS = ['==', '<=', '>=', '<', '>', '!=', '⊇'];
export const STATUS_OPERATORS = ['==', '!='];

export const SPLITTER_SELECTORS = [
  '_VALUE',
  '_STATUS',
  '_DOCUMENT',
  '_COMPARISON',
  '_EKYBBUSINESSNAME',
  '_EKYBADDRESSVERIFICATION',
  '_EKYBADDRESSPROPERTYTYPE',
  '_EKYBADDRESSDELIVERABILITY',
  '_SCORESTATUS',
  '_MANUALREVIEWSTATUS',
  '_COUNTRYNAME',
];

export const Selector = {
  STATUS: 'STATUS',
  VALUE: 'VALUE',
  COMPARISON: 'COMPARISON',
  DOCUMENT: 'DOCUMENT',
  EKYBBUSINESSNAME: 'EKYBBUSINESSNAME',
  EKYBADDRESSVERIFICATION: 'EKYBADDRESSVERIFICATION',
  EKYBADDRESSPROPERTYTYPE: 'EKYBADDRESSPROPERTYTYPE',
  EKYBADDRESSDELIVERABILITY: 'EKYBADDRESSDELIVERABILITY',
  SCORESTATUS: 'SCORESTATUS',
  MANUALREVIEWSTATUS: 'MANUALREVIEWSTATUS',
  COUNTRYNAME: 'COUNTRYNAME',
};

export const TEST_STATUS = ['OK', 'FAIL', 'WARN'];
export const TEST_COMPARISON = ['OK', 'FAIL', 'NON_EXISTENT'];
export const SUFFIX = Object.values(Selector);

export const MODULE_MAPPING = {
  [Selector.STATUS]: 'moduleStatus',
  [Selector.DOCUMENT]: 'moduleDocument',
  [Selector.VALUE]: 'moduleValue',
  [Selector.COMPARISON]: 'moduleComparison',
  [Selector.EKYBADDRESSVERIFICATION]: 'moduleAddressVerification',
  [Selector.EKYBADDRESSPROPERTYTYPE]: 'moduleAddressPropertyType',
  [Selector.EKYBADDRESSDELIVERABILITY]: 'moduleAddressDeliverability',
  [Selector.EKYBBUSINESSNAME]: 'moduleBusinessName',
  [Selector.SCORESTATUS]: 'moduleFraudStatus',
  [Selector.MANUALREVIEWSTATUS]: 'moduleManualReviewStatus',
  [Selector.COUNTRYNAME]: 'moduleCountryName',
};

export const BusinessNameStatus = [
  'VERIFIED',
  'SIMILAR MATCH',
  'UNVERIFIED',
  'ALTERNATE NAME',
];
export const AddressVerificationStatus = [
  'VERIFIED',
  'UNVERIFIED',
  'APPROXIMATE MATCH',
];
export const AddressPropertyTypeStatus = ['RESIDENTIAL', 'COMMERCIAL'];
export const AddressDeliverabilityStatus = ['DELIVERABLE', 'UNDELIVERABLE'];

export const FraudResultStatus = ['OK', 'FAIL', 'MANUAL'];

export const ManualReviewStatus = [
  'UNSET',
  'REQUIRED',
  'APPROVED',
  'REJECTED',
  'PENDING',
];

export const OperatorType = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
};

export const CountryNameValue = [...countryNames];
