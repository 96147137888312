import { useState } from 'react';
import cx from 'classnames';
import eye from './assets/eye.svg';
import './PasswordInput.scss';

function PasswordInput({ autoComplete, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  const type = isOpen ? 'text' : 'password';
  return (
    <div className="password-input-wrapper">
      <input
        type={type}
        className={cx('password-input', props.className)}
        autoComplete={autoComplete}
        {...props}
      />
      <img
        className={cx({ open: isOpen })}
        src={eye}
        alt="eye icon"
        onClick={() => setIsOpen(open => !open)}
      />
    </div>
  );
}

export default PasswordInput;
