import { forwardRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import interviewerIcon from 'assets/img/interviewer-avatar.svg';

const MessagesContainer = styled.div`
  top: 0;
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  overflow: auto;
`;

const MessagesInner = styled.div`
  max-height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const MessageContainer = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  margin-bottom: 15px;
  text-align: left;

  flex-direction: ${props => (props.sender === 'user' ? 'row' : 'row-reverse')};
  align-self: ${props => (props.sender === 'user' ? 'flex-start' : 'flex-end')};
`;

const AvatarContainer = styled.div`
  border-radius: 50%;
  height: 43px;
  width: 43px;
  margin-left: 10px;
  margin-right: 10px;
`;

const Avatar = styled.img`
  height: 43px;
  width: 43px;
`;

const Message = styled.div`
  border-radius: 7px;
  padding: 20px;
  max-width: 75%;
  background-color: ${props => (props.sender === 'user' ? '#ccc' : '#21273b')};
  color: ${props => (props.sender === 'user' ? '#21273b' : '#fff')};
`;

const DateSpan = styled.span`
  font-size: 10px;
  color: #21273b;
  width: 45px;
  margin-left: 10px;
  margin-right: 10px;
`;

const Messages = forwardRef(({ messages }, ref) => {
  return (
    <MessagesContainer ref={ref}>
      <MessagesInner>
        {messages.map(message => (
          <MessageContainer
            sender={message.author}
            key={message.date + message.body}
          >
            <AvatarContainer>
              <Avatar src={interviewerIcon} alt="avatar" />
            </AvatarContainer>
            <Message sender={message.author}>{message.body}</Message>
            <DateSpan>{moment(message.date).format('MMM D LT')}</DateSpan>
          </MessageContainer>
        ))}
      </MessagesInner>
    </MessagesContainer>
  );
});

export default Messages;
