import api from 'utils/api';

const getAllExecutivesURL = type => `/executive/all?type=${type}`;

export function getAllExecutives(type, { token }) {
  const url = getAllExecutivesURL(type);
  return api.get(url, null, { token }).then(r => r.data);
}

const editExecutiveURL = () => '/executive/edit';

export function editExecutive(
  { name, newPhone, email, oldPhone, baseUrl },
  { token },
) {
  return api
    .post(
      editExecutiveURL(),
      { name, newPhone, email, oldPhone, baseUrl },
      { token },
    )
    .then(r => r.data);
}

const deleteExecutiveURL = phone => `/executive/delete?phone=${phone}`;

export function deleteExecutive(phone, { token }) {
  const url = deleteExecutiveURL(phone);
  return api.delete(url, null, { token }).then(r => r.data);
}

const adminLoginURL = '/executive/admin/login';
export function adminLogin({ email, password }) {
  return api.post(adminLoginURL, { email, password }).then(r => r.data);
}

const fetchSsoRedirectWithEmail = email =>
  `/executive/sso-redirect-url-manual-correction?email=${email}`;
export async function getSsoRedirectWithEmail(email) {
  return api.get(fetchSsoRedirectWithEmail(email), {}).then(r => r.data);
}

const loginWithAuthCodeURL = '/executive/login-with-code';
export async function loginWithAuthCode(authCode) {
  const result = await api.post(loginWithAuthCodeURL, {
    authCode,
    loginType: 'ManualCorrection',
  });
  return result.data;
}
