import React, { forwardRef } from 'react';
import cx from 'classnames';
import './LogicalOperator.scss';

export const LogicalOperators = forwardRef(
  ({ operatorType, error, classes, disabled, ...props }, ref) => {
    return (
      <span
        className={cx('logical-operator-wrapper', classes)}
        data-testid="logical-operator-wrapper"
      >
        <label>
          <input
            type="radio"
            aria-label={operatorType}
            disabled={disabled}
            className={cx('radio', { 'error-logical-operator': !!error })}
            ref={ref}
            value={operatorType}
            {...props}
          />
          <span>{operatorType}</span>
        </label>
        {error && <span className="error-message">{error}</span>}
      </span>
    );
  },
);

export default LogicalOperators;
