import Modal from 'react-modal';
import './ConfirmModal.scss';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: 100,
    borderRadius: 22,
    boxShadow: '0 12px 28px 0 rgba(0, 0, 0, 0.11)',
    width: 474,
    padding: 30,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(33, 39, 59, 0.8)',
  },
};

export default function ConfirmModal({ closeModal, removeRules, ...props }) {
  return (
    <Modal style={customModalStyles} contentLabel="Delete rules" {...props}>
      <div className="submitModal">
        <h3 className="modal-text">Are you sure you want to delete rules?</h3>
        <div className="modal-buttons-container">
          <button className="modal-button deny" onClick={closeModal}>
            No, keep rules
          </button>
          <button className="modal-button confirm" onClick={removeRules}>
            Yes, delete rules
          </button>
        </div>
      </div>
    </Modal>
  );
}
