import { useEffect, useState } from 'react';
import {
  OPERATORS,
  OperatorType,
  Selector,
  STATUS_OPERATORS,
  SUFFIX,
} from './constants';
import cx from 'classnames';
import RulesGroupSelect from './RulesGruopSelect';
import RulesSelect from './RulesSelect';
import {
  getRandomStringId,
  hasValidModuleRules,
  mapSelectorsFunc,
  parseFromExpression,
  parseToExpression,
} from './utils';
import { renderRuleSelector } from './getSelectors';
import deleteIcon from '../../../../assets/img/icon-delete.svg';
import addIcon from '../../../../assets/img/icon-add.svg';
import LogicalOperator from './LogicalOperator';

function RuleActions({
  resetRule,
  toggleRuleCreation,
  saveRule,
  validExpression,
}) {
  return (
    <div className="rules-actions">
      <h2>Add new rule</h2>
      <div>
        <button onClick={resetRule}>Rule reset</button>
        <button onClick={toggleRuleCreation}>Close rule</button>
        <button
          className="submit"
          onClick={saveRule}
          form="rule-form"
          disabled={!validExpression}
        >
          Save rule
        </button>
      </div>
    </div>
  );
}

function RuleName({ ruleName, handleNameEdit }) {
  return (
    <div className="rule-name">
      <label className="input-label" htmlFor="name">
        Rule name:
        <input
          name="name"
          type="text"
          value={ruleName}
          onChange={handleNameEdit}
        />
      </label>
    </div>
  );
}

function RuleExpression({
  moduleRuleList,
  operands,
  handleSelectChange,
  condenseRule,
  expandRule,
  onOperatorChange,
}) {
  return (
    <div className="left-side">
      {moduleRuleList.map((element, index, array) => {
        const previousElement = array[index - 1];
        const allowedOperands = element?.moduleTest
          ? Object.values(operands)
              .flat(1)
              .filter(el => el.name === element.moduleTest)[0]?.allowed ??
            SUFFIX
          : SUFFIX;
        return (
          <div key={index}>
            <div
              className={cx('operands', {
                selectedPrev: element.moduleLogicOperator === OperatorType.AND,
                selectedNext:
                  previousElement &&
                  previousElement.moduleLogicOperator === OperatorType.AND,
              })}
            >
              <div className="expression-row">
                <span>IF</span>
                <RulesGroupSelect
                  selectName="moduleTest"
                  selectPlaceholder="Select"
                  selectGroupedOptions={operands}
                  selectValue={element.moduleTest}
                  handleChangeSelect={e => handleSelectChange(e, index)}
                  shouldNormalize
                />
                <RulesSelect
                  classes="suffix-select"
                  selectName="moduleSuffix"
                  selectValue={element.moduleTest ? element.moduleSuffix : ''}
                  selectOptions={allowedOperands}
                  selectPlaceholder="Select"
                  handleChangeSelect={e => {
                    const newSuffix = e.target.value;
                    const newOperator =
                      newSuffix === Selector.VALUE
                        ? OPERATORS[0]
                        : STATUS_OPERATORS[0];
                    handleSelectChange(
                      {
                        target: {
                          name: 'moduleSuffix',
                          value: newSuffix,
                        },
                      },
                      index,
                    );
                    handleSelectChange(
                      {
                        target: {
                          name: 'moduleOperator',
                          value: newOperator,
                        },
                      },
                      index,
                    );
                  }}
                  mapFunc={mapSelectorsFunc}
                  disabled={element.moduleTest === ''}
                />
                <RulesSelect
                  selectOptions={
                    element.moduleSuffix === Selector.VALUE
                      ? OPERATORS
                      : STATUS_OPERATORS
                  }
                  selectName="moduleOperator"
                  selectValue={element.moduleOperator}
                  handleChangeSelect={e => handleSelectChange(e, index)}
                  disabled={element.moduleTest === ''}
                />
                {renderRuleSelector({
                  element,
                  index,
                  disabled: element.moduleTest === '',
                  handleChange: handleSelectChange,
                })}
                {moduleRuleList.length !== 1 && (
                  <span className="control-button">
                    <img
                      src={deleteIcon}
                      height={35}
                      alt="icon delete"
                      onClick={() => condenseRule(index)}
                    />
                  </span>
                )}
                {moduleRuleList.length - 1 === index &&
                  element.moduleTest !== '' &&
                  (element.moduleStatus !== '' ||
                    element.moduleDocument !== '' ||
                    element.moduleBusinessName !== '' ||
                    element.moduleFraudStatus !== '' ||
                    element.moduleAddressVerification !== '' ||
                    element.moduleAddressPropertyType !== '' ||
                    element.moduleAddressDeliverability !== '' ||
                    element.moduleComparison !== '' ||
                    element.moduleManualReviewStatus !== '' ||
                    element.moduleCountryName !== '' ||
                    element.moduleValue !== '') &&
                  element.moduleOperator !== '' &&
                  !!element.moduleLogicOperator && (
                    <span className="control-button">
                      <img
                        src={addIcon}
                        height={35}
                        alt="icon add"
                        onClick={expandRule}
                      />
                    </span>
                  )}
              </div>
            </div>
            <div
              className={cx('operators', {
                selected: element.moduleLogicOperator === OperatorType.AND,
              })}
            >
              <LogicalOperator
                operatorType={OperatorType.AND}
                checked={element.moduleLogicOperator === OperatorType.AND}
                onChange={e => onOperatorChange(e, index)}
              />
              <LogicalOperator
                operatorType={OperatorType.OR}
                checked={element.moduleLogicOperator === OperatorType.OR}
                onChange={e => onOperatorChange(e, index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function RuleChecks({
  tamper,
  handleTamperChange,
  paper,
  handlePaperChange,
  screen,
  handleScreenChange,
  fake,
  handleFakeChange,
  ttlSeconds,
  handleTllSecondsChange,
  skipSupRel,
  handleSkipSupRel,
}) {
  return (
    <div className="right-side">
      <p>THEN</p>
      <div className="rule-result">
        <label htmlFor="skipSupRel" className="checkbox">
          <input
            type="checkbox"
            id="skipSupRel"
            checked={skipSupRel}
            onChange={handleSkipSupRel}
          />
          Skip supervision release
        </label>
        <label htmlFor="temper" className="checkbox">
          <input
            type="checkbox"
            id="temper"
            checked={tamper}
            onChange={handleTamperChange}
            disabled={skipSupRel}
          />
          Tamper
        </label>
        <label htmlFor="paper" className="checkbox">
          <input
            type="checkbox"
            id="paper"
            checked={paper}
            onChange={handlePaperChange}
            disabled={skipSupRel}
          />
          Paper
        </label>
        <label htmlFor="screen" className="checkbox">
          <input
            type="checkbox"
            id="screen"
            checked={screen}
            onChange={handleScreenChange}
            disabled={skipSupRel}
          />
          Screen
        </label>
        <label htmlFor="fake" className="checkbox">
          <input
            type="checkbox"
            id="fake"
            checked={fake}
            onChange={handleFakeChange}
            disabled={skipSupRel}
          />
          Fake check
        </label>
        <label className="input-label ttl-input" htmlFor="ttlSeconds">
          <input
            name="ttlSeconds"
            type="text"
            value={ttlSeconds}
            onChange={handleTllSecondsChange}
            disabled={skipSupRel}
          />
          ttl in seconds
        </label>
      </div>
    </div>
  );
}

export function RuleCreator({
  moduleRuleList,
  setModuleRuleList,
  operands,
  toggleRuleCreation,
  changeRule,
  currentRule,
}) {
  const ruleId = getRandomStringId();
  const [ruleName, setRuleName] = useState(`Untitled Rule`);
  const [paper, setPaper] = useState(false);
  const [screen, setScreen] = useState(false);
  const [tamper, setTamper] = useState(false);
  const [fake, setFake] = useState(false);
  const [skipSupRel, setSkipSupRel] = useState(false);
  const [ttlSeconds, setTtlSeconds] = useState(25);

  useEffect(() => {
    resetRule();
    if (currentRule.name !== '') {
      setRuleName(currentRule.name.split('#')[0]);
      setPaper(currentRule.paper);
      setScreen(currentRule.screen);
      setTamper(currentRule.tamper);
      setFake(currentRule.fake);
      setTtlSeconds(currentRule.ttlSeconds);
      setSkipSupRel(currentRule.skipSupRel);
      setModuleRuleList(parseFromExpression(currentRule.expression, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRule]);

  const handleNameEdit = e => {
    setRuleName(e.target.value);
  };

  const handleSkipSupRel = e => {
    setSkipSupRel(e.target.checked);
  };

  const handlePaperChange = e => {
    setPaper(e.target.checked);
  };

  const handleScreenChange = e => {
    setScreen(e.target.checked);
  };

  const handleTamperChange = e => {
    setTamper(e.target.checked);
  };

  const handleFakeChange = e => {
    setFake(e.target.checked);
  };

  const handleTllSecondsChange = e => {
    setTtlSeconds(e.target.value);
  };

  const handleSelectChange = (e, index) => {
    const { name, value } = e.target;
    const ruleList = [...moduleRuleList];
    ruleList[index][name] = value;
    setModuleRuleList(ruleList);
  };

  const resetRule = () => {
    setRuleName(`Untitled`);
    setPaper(false);
    setScreen(false);
    setTamper(false);
    setFake(false);
    setTtlSeconds(25);
    setModuleRuleList([
      {
        moduleTest: '',
        moduleSuffix: '',
        moduleOperator: OPERATORS[0],
        moduleStatus: '',
        moduleValue: '',
        moduleDocument: '',
        moduleComparison: '',
        moduleLogicOperator: '',
        moduleAddressPropertyType: '',
        moduleAddressVerification: '',
        moduleAddressDeliverability: '',
        moduleBusinessName: '',
        moduleFraudStatus: '',
        moduleManualReviewStatus: '',
        moduleCountryName: '',
      },
    ]);
  };

  const expandRule = () => {
    setModuleRuleList([
      ...moduleRuleList,
      {
        moduleTest: '',
        moduleSuffix: '',
        moduleOperator: OPERATORS[0],
        moduleStatus: '',
        moduleValue: '',
        moduleDocument: '',
        moduleComparison: '',
        moduleLogicOperator: '',
        moduleAddressPropertyType: '',
        moduleAddressVerification: '',
        moduleAddressDeliverability: '',
        moduleBusinessName: '',
        moduleFraudStatus: '',
        moduleManualReviewStatus: '',
        moduleCountryName: '',
      },
    ]);
  };
  const condenseRule = index => {
    const ruleList = [...moduleRuleList];
    ruleList.splice(index, 1);
    setModuleRuleList(ruleList);
  };

  const onOperatorChange = (e, index) => {
    const { value } = e.target;
    const ruleList = [...moduleRuleList];
    ruleList[index]['moduleLogicOperator'] = value;
    setModuleRuleList(ruleList);
  };

  const saveRule = () => {
    changeRule({
      name: ruleName + '#' + ruleId,
      expression: parseToExpression(moduleRuleList),
      paper: paper,
      screen: screen,
      tamper: tamper,
      fake: fake,
      skipSupRel: skipSupRel,
      ttlSeconds: Number(ttlSeconds),
    });
    resetRule();
    toggleRuleCreation();
  };

  const validExpression =
    !!ruleName && !!ttlSeconds && hasValidModuleRules(moduleRuleList);

  return (
    <>
      <RuleActions
        toggleRuleCreation={toggleRuleCreation}
        resetRule={resetRule}
        saveRule={saveRule}
        validExpression={validExpression}
      />
      <div className="rule-container">
        <div className="rule-creation visible">
          <form className="rule-form" id="rule-form">
            <RuleName handleNameEdit={handleNameEdit} ruleName={ruleName} />
            <div className="rule">
              <RuleExpression
                condenseRule={condenseRule}
                expandRule={expandRule}
                handleSelectChange={handleSelectChange}
                onOperatorChange={onOperatorChange}
                moduleRuleList={moduleRuleList}
                operands={operands}
              />
              <RuleChecks
                tamper={tamper}
                handleTamperChange={handleTamperChange}
                screen={screen}
                handleScreenChange={handleScreenChange}
                paper={paper}
                handlePaperChange={handlePaperChange}
                fake={fake}
                handleFakeChange={handleFakeChange}
                ttlSeconds={ttlSeconds}
                handleTllSecondsChange={handleTllSecondsChange}
                handleSkipSupRel={handleSkipSupRel}
                skipSupRel={skipSupRel}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
