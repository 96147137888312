import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import InputBox from './InputBox';

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function IndividualCharacterInputBoxes(props) {
  const [characterArray, setCharacterArray] = useState(
    Array(props.amount).fill(''),
  );
  const inputElements = useRef({});

  useEffect(() => {
    setCharacterArray(characterArray => {
      characterArray.forEach(
        (_, i) => (characterArray[i] = props.value[i] || ''),
      );
      return [...characterArray];
    });
  }, [props.value]);

  useEffect(() => {
    if (props.autoFocus) {
      inputElements.current['input0'].select();
    }
  }, [props.autoFocus]);

  useEffect(() => {
    props.onChange(characterArray.join(''));
  }, [characterArray, props]);

  const renderItems = () => {
    const items = characterArray.map((item, i) => (
      <InputBox
        key={i}
        type={props.password ? 'password' : 'text'}
        handleKeyDown={handleKeyDown}
        handleFocus={handleFocus}
        handleChange={handleChange}
        name={'input' + i}
        inputRef={el => {
          if (!el) return;
          inputElements.current[el.name] = el;
        }}
        value={characterArray[i]}
      />
    ));

    return items;
  };

  const handleChange = ({ target }) => {
    focusNextChar(target);
    setModuleOutput();
  };

  const handleKeyDown = ({ target, key }) => {
    if (key === 'Backspace') {
      if (target.value === '' && target.previousElementSibling !== null) {
        target.previousElementSibling.value = '';
        focusPrevChar(target);
      } else {
        target.value = '';
      }
      setModuleOutput();
    } else if (key === 'ArrowLeft') {
      focusPrevChar(target);
    } else if (key === 'ArrowRight') {
      focusNextChar(target);
    }
  };

  const handleFocus = ({ target }) => {
    const el = target;
    setTimeout(function () {
      el.select();
    }, 0);
  };

  const focusPrevChar = target => {
    if (target.previousElementSibling !== null) {
      target.previousElementSibling.focus();
    }
  };

  const focusNextChar = target => {
    if (target.nextElementSibling !== null) {
      target.nextElementSibling.focus();
    }
  };

  const setModuleOutput = () => {
    setCharacterArray(characterArray => {
      const updatedCharacters = characterArray.map(
        (_, number) => inputElements.current['input' + number].value[0],
      );
      return updatedCharacters;
    });
  };

  return (
    <Wrapper>
      <div style={{ display: 'flex' }}>{renderItems()}</div>
    </Wrapper>
  );
}

export default IndividualCharacterInputBoxes;
