import {
  DocumentTypes,
  MODULE_MAPPING,
  Selector,
  SPLITTER_SELECTORS,
  SUFFIX,
} from './constants';

export function mapSelectorsFunc(option) {
  switch (option) {
    case Selector.EKYBBUSINESSNAME:
      return 'NAME';
    case Selector.EKYBADDRESSVERIFICATION:
      return 'VERIFICATION';
    case Selector.EKYBADDRESSPROPERTYTYPE:
      return 'TYPE';
    case Selector.EKYBADDRESSDELIVERABILITY:
      return 'DELIVERABILITY';
    case Selector.MANUALREVIEWSTATUS:
      return 'REVIEW STATUS';
    case Selector.SCORESTATUS:
      return 'STATUS';
    case Selector.COUNTRYNAME:
      return 'COUNTRY';
    default:
      return option;
  }
}

export const idDocumentTypes = Object.keys(DocumentTypes).sort((a, b) => {
  if (DocumentTypes[a] < DocumentTypes[b]) {
    return -1;
  }
  if (DocumentTypes[a] > DocumentTypes[b]) {
    return 1;
  }
  return 0;
});

export function getIDDocumentTypes(documentTypeKey) {
  return DocumentTypes[documentTypeKey];
}

export function getRandomStringId() {
  const a = new Uint32Array(3);
  window.crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map(A => A.toString(36))
      .join('')
  )
    .replace(/\./g, '')
    .substring(0, 8);
}

export function parseRuleArrayToObject(ruleArr) {
  const ruleArrObj = [];
  for (let i = 0; i < ruleArr?.length; i += 4) {
    const temp = {
      moduleTest: ruleArr[i].split('_')[0],
      moduleSuffix: ruleArr[i].split('_')[1] || SUFFIX[0],
      moduleOperator: ruleArr[i + 1],
      moduleStatus:
        ruleArr[i].split('_')[1] === Selector.STATUS ? ruleArr[i + 2] : '',
      moduleValue:
        ruleArr[i].split('_')[1] === Selector.VALUE ? ruleArr[i + 2] : '',
      moduleDocument:
        ruleArr[i].split('_')[1] === Selector.DOCUMENT ? ruleArr[i + 2] : '',
      moduleComparison:
        ruleArr[i].split('_')[1] === Selector.COMPARISON ? ruleArr[i + 2] : '',
      moduleAddressVerification:
        ruleArr[i].split('_')[1] === Selector.EKYBADDRESSVERIFICATION
          ? ruleArr[i + 2]
          : '',
      moduleAddressDeliverability:
        ruleArr[i].split('_')[1] === Selector.EKYBADDRESSDELIVERABILITY
          ? ruleArr[i + 2]
          : '',
      moduleBusinessName:
        ruleArr[i].split('_')[1] === Selector.EKYBBUSINESSNAME
          ? ruleArr[i + 2]
          : '',
      moduleAddressPropertyType:
        ruleArr[i].split('_')[1] === Selector.EKYBADDRESSPROPERTYTYPE
          ? ruleArr[i + 2]
          : '',
      moduleFraudStatus:
        ruleArr[i].split('_')[1] === Selector.SCORESTATUS ? ruleArr[i + 2] : '',
      moduleManualReviewStatus:
        ruleArr[i].split('_')[1] === Selector.MANUALREVIEWSTATUS
          ? ruleArr[i + 2]
          : '',
      moduleCountryName:
        ruleArr[i].split('_')[1] === Selector.COUNTRYNAME ? ruleArr[i + 2] : '',
      moduleLogicOperator: ruleArr[i + 3],
    };
    ruleArrObj.push(temp);
  }
  return ruleArrObj;
}

export function parseFromExpression(exp) {
  const ruleArr = exp
    .replaceAll('&&', 'AND')
    .replaceAll('||', 'OR')
    .match(/('.*?'|[^\s]+)/g)
    ?.map(el => el.replaceAll("'", '').replaceAll('{{space}}', ' '));
  return parseRuleArrayToObject(ruleArr ?? []);
}

export function parseToExpression(moduleRuleList) {
  const expression = moduleRuleList
    .map((item, index, array) => {
      const nextModule = array[index + 1];
      const validOperator =
        nextModule?.moduleTest &&
        nextModule[MODULE_MAPPING[nextModule.moduleSuffix]]
          ? item.moduleLogicOperator
          : '';
      const selector =
        item[MODULE_MAPPING[item.moduleSuffix]] || item.moduleValue;
      return `${item.moduleTest}_${item.moduleSuffix} ${item.moduleOperator} '${selector}' ${validOperator} `;
    })
    .join('');

  return expression
    .replaceAll(/\bAND\b/g, '&&')
    .replaceAll(/\bOR\b/g, '||')
    .trim();
}

export function normalizeExpression(str) {
  const normalizedStr = str ?? '';
  const pattern = new RegExp(`(#\\S+|${SPLITTER_SELECTORS.join('|')})`, 'g');
  return normalizedStr.replace(pattern, '').trim().replace(/\s+/g, ' ');
}

export function hasValidModuleRules(moduleRuleList) {
  return (
    moduleRuleList.every(obj => obj.moduleTest !== '') &&
    moduleRuleList.every(obj => obj.moduleSuffix !== '') &&
    moduleRuleList.every(
      obj =>
        obj.moduleStatus !== '' ||
        obj.moduleValue !== '' ||
        obj.moduleDocument !== '' ||
        obj.moduleAddressPropertyType !== '' ||
        obj.moduleAddressVerification !== '' ||
        obj.moduleAddressDeliverability !== '' ||
        obj.moduleBusinessName !== '' ||
        obj.moduleComparison !== '' ||
        obj.moduleFraudStatus !== '' ||
        obj.moduleManualReviewStatus !== '' ||
        obj.moduleCountryName !== '',
    )
  );
}
