export const MEX_COUNTRY_CODE = 'MEX';
export const OPTIONS = {
  YES: 'true',
  NO: 'false',
  I_DONT_KNOW: 'idk',
};
export const manualCorrectionActions = {
  SET_VALUE: 'SET_VALUE',
  RESET: 'RESET',
  SET_STATUS: 'SET_STATUS',
};
export const manualCorrectionInitialState = {
  tamper: OPTIONS.I_DONT_KNOW,
  fake: OPTIONS.I_DONT_KNOW,
  paper: OPTIONS.I_DONT_KNOW,
  screen: OPTIONS.I_DONT_KNOW,
  faceSpoof: OPTIONS.I_DONT_KNOW,
  faceMismatch: OPTIONS.I_DONT_KNOW,
  status: 'idle',
};

export const allowedGroups = [
  'mex',
  'na',
  'latam',
  'eu',
  'afrasi',
  'optimizer',
  'other',
];

export const imageKeys = ['frontIdUrl', 'backIdUrl', 'selfieUrl'];
