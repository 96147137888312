import api from '../../../../utils/api';

const manualCorrectorLoginURL = '/executive/correctors-login';
export async function manualCorrectorLogin(email, password) {
  const result = await api.post(manualCorrectorLoginURL, {
    email,
    password,
  });
  return result.data;
}

const manualCorrectorNewPasswordURL = '/executive/new-password';
export async function setNewPassword(newPasswordBody) {
  const result = await api.post(manualCorrectorNewPasswordURL, newPasswordBody);
  return result.data;
}

const forgotPasswordURL = '/executive/forgot-password';
export async function sendForgotPasswordCode(email) {
  const result = await api.post(forgotPasswordURL, {
    email,
    redirectUrl: window.location.href,
  });
  return result.data;
}

const resetPasswordURL = '/executive/reset-password';
export async function sendResetPasswordCode(email, code, newPassword) {
  const result = await api.post(resetPasswordURL, {
    email,
    code,
    newPassword,
  });
  return result.data;
}
