import {
  useRouteMatch,
  Redirect,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { useLogin, useEncryption, useQuery } from '../../../utils/hooks';
import LoginComponent from '../auth/Login/LoginPage';
import Dashboard from './Dashboard';
import { preloadScript } from 'opentok-react';

function LoginLayout({ children }) {
  return <div className="app-wrapper">{children}</div>;
}

function BetaConference() {
  const { path, params } = useRouteMatch();
  const query = useQuery();
  const history = useHistory();
  const queue = query.get('queue');
  const { isLogged, validatedUser, login, logout, token } = useLogin();
  const isReady = useEncryption({ token, isLogged });

  function handleLogin(loginData) {
    login(loginData);
    history.push(`/conference${queue ? `/${queue}` : ''}`);
  }

  if (!isLogged) {
    return (
      <LoginLayout>
        <Switch>
          <Route exact path={`${path}/log-in`}>
            <LoginComponent onLogin={handleLogin} />
          </Route>
          <Redirect
            to={`/conference/log-in${
              params.queue && params.queue !== 'log-in'
                ? `?queue=${params.queue}`
                : ''
            }`}
          />
        </Switch>
      </LoginLayout>
    );
  }

  if (!isReady || !validatedUser) return null;

  return (
    <Switch>
      <Route exact path={path}>
        <Dashboard {...{ logout, isLogged, token }} />
      </Route>
      <Redirect to={'/conference'} />
    </Switch>
  );
}

export default preloadScript(BetaConference);
