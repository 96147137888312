import { Component } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  justify-content: space-between;
  width: 100%;
  max-width: 38px;
  height: 38px;
  margin: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
  border: solid 1px #e2e5ed;
  padding: 0;
`;

class InputBox extends Component {
  render() {
    return (
      <Input
        type={this.props.type}
        onKeyDown={this.props.handleKeyDown}
        onChange={this.props.handleChange}
        onFocus={this.props.handleFocus}
        maxLength="1"
        name={this.props.name}
        ref={this.props.inputRef}
        placeholder=" "
        autoComplete="off"
        value={this.props.value}
      />
    );
  }
}
export default InputBox;
