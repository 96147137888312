import { useEffect } from 'react';
import cx from 'classnames';
import './NotificationBar.scss';

export function NotificationBar({ message, setMessage }) {
  useEffect(() => {
    if (message.type === 'error' || message.type === 'success') {
      const timer = setTimeout(() => {
        setMessage({});
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [message, setMessage]);

  return (
    <div
      className={cx('notification-bar', message.type, {
        visible: message.type,
      })}
    >
      {message.text}
    </div>
  );
}
