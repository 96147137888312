import { useEffect, useState } from 'react';
import {
  addRules,
  deleteAllRules,
  getRuleOperands,
  getRules,
} from '../../../../services/omniRepository';
import { useAdminCorrectionLogin } from '../../../../utils/hooks';
import { OPERATORS } from './constants';
import { RulesTable } from './RulesTable';
import { RuleCreator } from './RuleCreator';

const initialRuleState = {
  name: '',
  expression: {},
  paper: false,
  screen: false,
  tamper: false,
  fake: false,
  ttlSeconds: 20,
};

function LoadingRules() {
  return <div>Loading...</div>;
}

export function Rules() {
  const [showRuleCreation, setShowRuleCreation] = useState(false);
  const { token } = useAdminCorrectionLogin();
  const [rules, setRules] = useState([]);
  const [operands, setOperands] = useState();
  const [configId, setConfigId] = useState();
  const [operation, setOperation] = useState('');
  const [ruleToEdit, setRuleToEdit] = useState(initialRuleState);
  const [ruleIndex, setRuleIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [moduleRuleList, setModuleRuleList] = useState([
    {
      moduleTest: '',
      moduleSuffix: '',
      moduleOperator: OPERATORS[0],
      moduleStatus: '',
      moduleValue: '',
      moduleDocument: '',
      moduleComparison: '',
      moduleLogicOperator: '',
      moduleAddressPropertyType: '',
      moduleAddressVerification: '',
      moduleAddressDeliverability: '',
      moduleBusinessName: '',
      moduleFraudStatus: '',
      moduleManualReviewStatus: '',
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [ruleResponse, operandResponse] = await Promise.all([
          getRules(token),
          getRuleOperands(token),
        ]);
        setRules(ruleResponse?.rules);
        setConfigId(ruleResponse?.id);
        setOperands(operandResponse);
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred while fetching data:', error);
        setMessage({
          text: `An error occurred while fetching data: ${error}`,
          type: 'error',
        });
        setLoading(false);
      }
    }
    fetchData();
  }, [token]);

  const toggleRuleCreation = () => {
    setShowRuleCreation(!showRuleCreation);
  };

  const addRule = () => {
    setOperation('add');
    setRuleToEdit({ ...initialRuleState });
    setShowRuleCreation(true);
  };

  const editRule = (rule, i) => {
    setOperation('edit');
    setRuleToEdit(rule);
    setRuleIndex(i);
    setShowRuleCreation(true);
  };

  const saveRules = async () => {
    try {
      await addRules({ rules: rules }, token);
      setMessage({
        text: `Data successfully saved`,
        type: 'success',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while saving data:', error);
      setMessage({
        text: `An error occurred while saving data: ${error}`,
        type: 'error',
      });
    }
  };

  const changeRule = newRule => {
    const currentRules = rules || [];
    if (operation === 'add') {
      setRules([...currentRules, newRule]);
    } else {
      const newRules = [...currentRules];
      newRules[ruleIndex] = newRule;
      setRules(newRules);
    }
    setRuleToEdit({ ...initialRuleState });
  };

  const deleteRule = i => {
    const newRules = [...rules];
    newRules.splice(i, 1);
    setRules(newRules);
  };

  const removeRules = async () => {
    try {
      await deleteAllRules(configId, token);
      const [ruleResponse, operandResponse] = await Promise.all([
        getRules(token),
        getRuleOperands(token),
      ]);
      setRules(ruleResponse?.rules);
      setConfigId(ruleResponse?.id);
      setOperands(operandResponse);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while deleting data:', error);
    }
  };

  if (loading) return <LoadingRules />;
  return (
    <div className="rules">
      {showRuleCreation ? (
        <RuleCreator
          moduleRuleList={moduleRuleList}
          setModuleRuleList={setModuleRuleList}
          operands={operands}
          changeRule={changeRule}
          toggleRuleCreation={toggleRuleCreation}
          currentRule={ruleToEdit}
        />
      ) : (
        <RulesTable
          rules={rules}
          setRules={setRules}
          addRule={addRule}
          editRule={editRule}
          deleteRule={deleteRule}
          saveRules={saveRules}
          removeRules={removeRules}
          message={message}
          setMessage={setMessage}
        />
      )}
    </div>
  );
}
