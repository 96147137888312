import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  color: black !important;
  width: 100%;
  border-radius: 20px 20px 0 0;
  height: 100px;
  padding: 20px;
`;

const ModalTitle = styled.h1`
  color: #000;
`;

const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: calc(100% - 100px);
  background-color: #ededed;
  border-radius: 0 0 20px 20px;
`;

const DetailsTextarea = styled.textarea`
  width: 100%;
  border: none;
  border-radius: 10px;
  height: 300px;
  padding: 15px;
  resize: none;
  font-family: 'CircularStd-Book';
`;

const SendButton = styled.button`
  margin-top: 15px;
  width: 100px;
  height: 50px;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  background-color: #00b2fd;

  &:disabled {
    background-color: #ccc;
  }
`;

function ReportProblemModal({ handleReportBug }) {
  const { t } = useTranslation();
  const [bugReport, setBugReport] = useState('');

  function handleBugReportChange(e) {
    setBugReport(e.target.value);
  }

  return (
    <>
      <ModalTitleContainer>
        <ModalTitle>{t('reportProblem')}</ModalTitle>
      </ModalTitleContainer>
      <ModalBodyContainer>
        <DetailsTextarea
          placeholder={t('addDetails')}
          value={bugReport}
          onChange={handleBugReportChange}
        ></DetailsTextarea>
        <SendButton
          onClick={() => handleReportBug(bugReport)}
          disabled={!bugReport}
        >
          {t('send')}
        </SendButton>
      </ModalBodyContainer>
    </>
  );
}

export default ReportProblemModal;
