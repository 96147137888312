import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  adminLogin,
  getSsoRedirectWithEmail,
  loginWithAuthCode,
} from 'services/usersRepository';

const EMAIL_PATTERN_USER = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: 432,
    borderRadius: 22,
    boxShadow: '0 12px 28px 0 rgba(0, 0, 0, 0.11)',
    width: 474,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(33, 39, 59, 0.8)',
  },
};

const LoginForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 432px;
  justify-content: space-around;
  margin: 0 auto;
  width: 280px;
`;

const Label = styled.label`
  color: #21273b;
  display: block;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 11px;
  text-align: center;
`;

const Input = styled.input`
  background-color: #edf2f6;
  border-radius: 4.8px;
  border: ${props => (props.hasError ? 'solid 4px #ff637b' : 'none')};
  box-shadow: none;
  color: #21273b;
  font-size: 20px;
  font-weight: bold;
  height: 62.4px;
  text-align: center;
  width: 100%;
`;

const Button = styled.button`
  background-color: #3cb0f7;
  border-radius: 11px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.14);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  height: 50px;
  text-align: center;
  width: 219px;

  &:disabled {
    background-color: #bdbdbd;
  }
`;

const FormControlContainer = styled.div`
  width: 100%;
`;

function FormControl({ labelText, id, ...rest }) {
  return (
    <FormControlContainer>
      <Label htmlFor={id}>{labelText}</Label>
      <Input type="text" id={id} {...rest} />
    </FormControlContainer>
  );
}

function validateForm({ username, password }) {
  if (username.length <= 3) {
    return false;
  }

  if (password.length <= 3) {
    return false;
  }

  return true;
}

function LoginModal({ onLogin, ...props }) {
  const params = new URLSearchParams(window.location.search);
  const ssoAuthCode = params.get('code');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleLogin(ev) {
    ev.preventDefault();
    setIsLoading(true);
    adminLogin({ email, password })
      .then(response => {
        onLogin(response);
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const checkQueryParams = async () => {
      if (ssoAuthCode) {
        try {
          const loginData = await loginWithAuthCode(ssoAuthCode);
          onLogin(loginData);
        } catch (err) {
          setHasError(true);
        }
      }
    };
    checkQueryParams();
    // eslint-disable-next-line
  }, [ssoAuthCode]);

  const verifyIfSSO = async () => {
    if (email.match(EMAIL_PATTERN_USER)) {
      try {
        setIsLoading(true);
        const result = await getSsoRedirectWithEmail(email.toLocaleLowerCase());

        if (result.redirectUrl) {
          window.location.href = result.redirectUrl;
        }
        setIsLoading(false);
      } catch {}
    }
  };

  useEffect(() => {
    setHasError(false);
  }, [email, password]);

  const canSubmit = validateForm({ username: email, password }) && !hasError;

  return (
    <Modal style={customModalStyles} contentLabel="Login Modal" {...props}>
      <div className="admin-login-modal">
        <LoginForm onSubmit={handleLogin}>
          <FormControl
            labelText="Username"
            name="username"
            id="username"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
            hasError={hasError}
            onBlur={verifyIfSSO}
          />
          <FormControl
            labelText="Password"
            name="passdword"
            id="password"
            value={password}
            onChange={ev => setPassword(ev.target.value)}
            type="password"
            hasError={hasError}
          />
          <Button disabled={!canSubmit || isLoading}>Login</Button>
        </LoginForm>
      </div>
    </Modal>
  );
}

export default LoginModal;
