import { Link } from 'react-router-dom';
import wave from 'assets/img/wave.svg';

export function Header({ manualCorrectors, onTabChange }) {
  return (
    <header>
      <Link to="/">
        <img src={wave} alt="wave logo" className="logo" />
      </Link>
      <div className="links">
        <button
          className={manualCorrectors ? 'active' : ''}
          onClick={() => onTabChange('correctors')}
        >
          Manual Correctors
        </button>
        <button
          className={!manualCorrectors ? 'active' : ''}
          onClick={() => onTabChange('rules')}
        >
          Rules
        </button>
      </div>
    </header>
  );
}
