import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IndividualCharacterInputBoxes from '../../common/individualCharacterInputBoxes/IndividualCharacterInputBoxes';

export default function MFA({ onSubmit, ...props }) {
  const { t } = useTranslation();
  const [code, setCode] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(code);
  };

  const handleCodeChange = string => {
    if (string === code) {
      return;
    }
    setCode(string);
  };

  return (
    <div className="login-form">
      <h2>Hi There!</h2>
      <span className="welcome">{t('mfaForm.formTitle')}</span>
      <form {...props} onSubmit={handleSubmit}>
        <div className="code-content">
          <label htmlFor="code">{t('mfaForm.confirmationCode')}</label>
          <IndividualCharacterInputBoxes
            amount={6}
            onChange={handleCodeChange}
            autoFocus
            value={code}
          />
        </div>

        <button className="green-lg" type="submit" disabled={code.length !== 6}>
          {t('mfaForm.submitCode')}
        </button>
      </form>
    </div>
  );
}
