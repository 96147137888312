import { useState } from 'react';

import noImage from 'assets/img/photo-blank.svg';
import addUser from 'assets/img/add-user.svg';

import { editExecutive, deleteExecutive } from 'services/usersRepository';
import { Header } from './Header';
import { UsersTable } from './UsersTable';
import { Rules } from './Rules/Rules';

const type = 'MANUAL_CORRECTION';
function UserRowForm({
  name,
  phone,
  email,
  editMode,
  onDelete,
  onSubmit,
  onDone,
}) {
  const [userData, setUserData] = useState({
    name: name || '',
    phone: phone || '',
    email: email || '',
  });
  const [error, setError] = useState({ showError: false, errorMessage: '' });
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit() {
    setIsLoading(true);
    setError({ showError: false, errorMessage: '' });

    return onSubmit({ ...userData, type })
      .then(() => {
        setUserData({
          name: '',
          phone: '',
          email: '',
        });
        setIsLoading(false);
        onDone({ ...userData, _id: name });
      })
      .catch(e => {
        setIsLoading(false);
        setError({ showError: true, errorMessage: e.response?.data?.message });
      });
  }
  const handleChange = ev => {
    const { name: inputName, value } = ev.target;
    setUserData(ud => ({ ...ud, [inputName]: value }));
    setError({ showError: false, errorMessage: '' });
  };

  return (
    <tr className="row new-user">
      <td className="avatar text-align-center">
        <img src={editMode ? noImage : addUser} alt="" />
      </td>
      <td>
        <input name="name" value={userData.name} onChange={handleChange} />
      </td>
      <td>
        <input name="phone" value={userData.phone} onChange={handleChange} />
      </td>
      <td>
        <input name="email" value={userData.email} onChange={handleChange} />
      </td>
      <td />
      <td />
      <td />
      <td />
      <td>
        <small className="error">{error.errorMessage}</small>
        {editMode && !error.showError && (
          <button className="delete" onClick={onDelete}>
            Delete
          </button>
        )}
      </td>
      <td className="actions text-align-center">
        <button
          type="submit"
          className="submit"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {editMode ? 'Save Changes' : 'Create User'}
        </button>
      </td>
    </tr>
  );
}

function UserRow({ enrolled, onDelete, _id, token, ...props }) {
  const [name, setName] = useState(props.name || '');
  const [phone, setPhone] = useState(props.phone || '');
  const [email, setEmail] = useState(props.email || '');
  const [editMode, setEditMode] = useState(false);

  function handleEdit({ phone: newPhone, name: newName, email: newEmail }) {
    return editExecutive(
      {
        name: newName !== name ? newName : null,
        newPhone: newPhone !== phone ? newPhone : null,
        email: newEmail !== email ? newEmail : null,
        oldPhone: phone,
      },
      { token },
    ).then(() => {
      setName(newName);
      setPhone(newPhone);
      setEmail(newEmail);
    });
  }

  function handleDelete() {
    return deleteExecutive(phone, { token }).then(() => onDelete(phone));
  }

  if (editMode) {
    return (
      <UserRowForm
        name={name}
        phone={phone}
        email={email}
        editMode
        onSubmit={handleEdit}
        onDone={() => setEditMode(false)}
        onDelete={handleDelete}
      />
    );
  }

  return (
    <tr className="row user-row">
      <td className="avatar text-align-center">
        <img src={noImage} alt="" />
      </td>
      <td>{name}</td>
      <td>{phone}</td>
      <td>{email}</td>
      <td>
        {enrolled ? (
          <span className="green">Face Enrolled</span>
        ) : (
          'Face not Enrolled'
        )}
      </td>
      <td>139</td>
      <td>24</td>
      <td>89.3%</td>
      <td>8.31 s</td>
      <td className="actions text-align-center">
        <button onClick={() => setEditMode(true)}>Modify</button>
      </td>
    </tr>
  );
}

function TableHeader() {
  return (
    <thead>
      <tr className="table-header">
        <th />
        <th>Name</th>
        <th>Phone Number</th>
        <th>Email</th>
        <th>Status</th>
        <th>Jobs</th>
        <th>Points</th>
        <th>Accuracy</th>
        <th>Average time</th>
        <th />
      </tr>
    </thead>
  );
}

function ManualCorrectorsDashboard(props) {
  const [activeTab, setActiveTab] = useState('correctors');

  const handleTabChange = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div className="user-dashboard">
      <Header
        manualCorrectors={activeTab === 'correctors'}
        onTabChange={handleTabChange}
      />
      <div className="dashboard-container">
        {activeTab === 'correctors' ? (
          <UsersTable
            TableHeader={TableHeader}
            UserRowForm={UserRowForm}
            UserRow={UserRow}
            type={type}
            {...props}
          />
        ) : (
          <Rules />
        )}
      </div>
    </div>
  );
}
export default ManualCorrectorsDashboard;
