import { useRef, useState } from 'react';
import { OTPublisher, OTSubscriber } from 'opentok-react';
import cx from 'classnames';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { env } from '../../../env';

import spinner from 'assets/img/spinner.svg';
import greenCheckImg from 'assets/img/green-check.svg';
import smsButton from 'assets/img/sms-icon.svg';
import checkIcon from 'assets/img/blue-check.svg';
import { addConferenceEvent } from 'services/InterviewRepository';

const fadeIn = keyframes`
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
`;

const HeaderContainer = styled.div`
  margin-top: auto;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75)
  );
  position: absolute;
  z-index: 200;
  width: 100%;
  top: 0;
  text-align: left;
`;

const Info = styled.div`
  width: 100%;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: row;
`;

const UserName = styled.h1`
  width: 400px;
  font-size: 36px;
  font-family: 'CircularStd-Book';
  color: #ffffff;
  text-transform: capitalize;
`;

const SmsContainer = styled.div`
  display: flex;
`;

const SmsButton = styled.button`
  background: transparent;
  border: none;
  height: 40px;
`;

const SmsButtonImage = styled.img`
  &.disabled {
    filter: grayscale(100%);
  }
`;

const ArrowBox = styled.div`
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 30px;
  background-color: white;
  color: #fff;
  position: absolute;
  float: left;
  border-radius: 10px;
  top: 75px;
  left: 390px;
  opacity: 0;
  animation: ${fadeIn} ease 5s;

  :after {
    content: ' ';
    position: absolute;
    right: 35px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 20px solid white;
  }
`;

const SentLabel = styled.p`
  color: black;
  font-size: 14px;
`;

const SentImage = styled.img`
  height: 16px;
`;

Modal.setAppElement(document.getElementById('root') || document.body);

function UserScreen({ interviewId, ...props }) {
  const otSubscriber = useRef(null);
  const otPublisher = useRef(null);
  const [smsDisabled, setSmsDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const timesSmsSent = useRef(0);
  const { t } = useTranslation();

  const renderConnecting = () => {
    return (
      <div className="">
        <div className="loading-circle">
          <img src={spinner} alt="loading-spinner" />
          <h2>{t('hangOn')}</h2>
          <p>{t('newCustomer')}</p>
        </div>
        <p className="notice">
          <small>{t('thisTakes')}</small>
        </p>
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className="card-wrapper">
        <div className="circle error">
          <h1>{'Error'}</h1>
          <h2>{t('notStablished')}</h2>
        </div>
      </div>
    );
  };

  function logToServerAndNotifyExec(ev) {
    logToServer(ev);
    const { showNotification } = props;
    showNotification(t(ev.type));
  }

  function logToServer(ev) {
    let event;
    try {
      event = JSON.stringify(ev);
    } catch {
      event = ev.reason || '';
    }

    addConferenceEvent({
      token: props.token,
      interviewId,
      opentokSessionId: props.sessionId,
      eventType: ev.type,
      details: event,
      eventSource: 'executive',
    });
  }

  const renderStreams = () => {
    if (props.streams.length === 0) return null;

    const subscriberEventHandlers = {
      audioLevel: logToServer,
      audioStatsUpdated: logToServer,
      connected: logToServerAndNotifyExec,
      error: logToServer,
      streamDisconnected: logToServer,
      streamReconnected: logToServer,
      videoDisabled: logToServer,
      videoDisableWarning: logToServerAndNotifyExec,
      videoDisableWarningLifted: logToServer,
      videoEnabled: logToServer,
      videoStatsUpdated: logToServer,
    };

    const stream = props.streams[props.streams.length - 1];
    return (
      <OTSubscriber
        key={stream.id}
        session={props.sessionHelper.session}
        properties={{ width: '100%', height: 'auto' }}
        stream={stream}
        ref={otSubscriber}
        eventHandlers={subscriberEventHandlers}
      />
    );
  };

  const timeOutSms = () => {
    setSmsDisabled(true);
    setTimeout(() => {
      setSmsDisabled(false);
      setShowNotification(true);
    }, 20000);
  };

  const renderHeader = () => {
    const { connected } = props;

    const sendSmsRequest = async () => {
      const { sendSMS, token } = props;
      const urlParams = window.location.pathname.split('/');
      const clientId = urlParams[1];
      const url = `${env.REACT_APP_ONBOARDING_LINK}${
        clientId ? '/' + clientId : ''
      }/conference`;
      await sendSMS(url, interviewId, token);
      timesSmsSent.current = timesSmsSent.current + 1;
      setShowNotification(true);
      timeOutSms();
    };

    return (
      <HeaderContainer className="header">
        <Info>
          <UserName>{props?.name?.toLowerCase()}</UserName>
          <SmsContainer>
            <SmsButton onClick={sendSmsRequest} disabled={smsDisabled}>
              <SmsButtonImage
                className={cx({ disabled: smsDisabled })}
                src={smsButton}
                alt="send sms button"
              />
            </SmsButton>
            {showNotification && (
              <ArrowBox
                key={timesSmsSent.current}
                showNotification={showNotification}
              >
                <SentImage src={checkIcon} alt="check" />
                <SentLabel>{t('sent')}</SentLabel>
              </ArrowBox>
            )}
          </SmsContainer>
        </Info>
        <p className="connected-status">
          <span
            className={cx(
              'connected-status-icon',
              connected ? 'connected' : 'not-connected',
            )}
          ></span>
          {connected ? 'connected' : 'disconnected'}
        </p>
        <span className="interview-code">
          <p>Applicant Code</p>
          <img src={greenCheckImg} alt="green check icon" />
        </span>
      </HeaderContainer>
    );
  };

  const renderRoom = () => {
    const { streams, connected } = props;

    const publisherEventHandlers = {
      accessAllowed: logToServer,
      accessDenied: logToServer,
      accessDialogClosed: logToServer,
      error: logToServer,
      accessDialogOpened: logToServer,
      destroyed: logToServer,
      mediaStopped: logToServer,
      streamCreated: logToServer,
      streamDestroyed: logToServer,
      videoDimensionsChanged: logToServer,
      videoElementCreated: logToServer,
    };

    return (
      <div
        className={cx('stream', {
          connecting: !connected || streams.length === 0,
          connected: connected && streams.length > 0,
        })}
      >
        {connected && streams.length > 0 ? renderHeader() : null}
        <div>
          {connected && streams.length > 0 && (
            <OTPublisher
              session={props.sessionHelper.session}
              ref={otPublisher}
              eventHandlers={publisherEventHandlers}
            />
          )}
        </div>
        {connected && streams.length > 0 ? renderStreams() : renderConnecting()}
      </div>
    );
  };

  const { error } = props;

  return (
    <section className="interview">
      {error ? renderError() : null}
      {props.sessionHelper && !error ? renderRoom() : null}
    </section>
  );
}

export default UserScreen;
