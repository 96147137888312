import {
  Selector,
  BusinessNameStatus,
  AddressDeliverabilityStatus,
  AddressVerificationStatus,
  AddressPropertyTypeStatus,
  FraudResultStatus,
  ManualReviewStatus,
  MODULE_MAPPING,
  TEST_COMPARISON,
  TEST_STATUS,
  CountryNameValue,
} from './constants';
import { idDocumentTypes, getIDDocumentTypes } from './utils';
import RulesSelect from './RulesSelect';

export const renderRuleSelector = ({
  element,
  index,
  disabled = false,
  handleChange,
}) => {
  const {
    moduleSuffix,
    moduleStatus,
    moduleDocument,
    moduleComparison,
    moduleBusinessName,
    moduleAddressDeliverability,
    moduleAddressPropertyType,
    moduleAddressVerification,
    moduleFraudStatus,
    moduleManualReviewStatus,
    moduleCountryName,
  } = element;

  switch (moduleSuffix) {
    case Selector.STATUS:
      return (
        <RulesSelect
          selectOptions={TEST_STATUS}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.STATUS]}
          selectValue={moduleStatus}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.DOCUMENT:
      return (
        <RulesSelect
          selectOptions={idDocumentTypes}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.DOCUMENT]}
          mapFunc={getIDDocumentTypes}
          selectValue={moduleDocument}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.COMPARISON:
      return (
        <RulesSelect
          selectValue={moduleComparison}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.COMPARISON]}
          handleChangeSelect={e => handleChange(e, index)}
          selectOptions={TEST_COMPARISON}
          disabled={disabled}
        />
      );
    case Selector.EKYBBUSINESSNAME:
      return (
        <RulesSelect
          selectOptions={BusinessNameStatus}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.EKYBBUSINESSNAME]}
          selectValue={moduleBusinessName}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.EKYBADDRESSDELIVERABILITY:
      return (
        <RulesSelect
          selectOptions={AddressDeliverabilityStatus}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.EKYBADDRESSDELIVERABILITY]}
          selectValue={moduleAddressDeliverability}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.EKYBADDRESSPROPERTYTYPE:
      return (
        <RulesSelect
          selectOptions={AddressPropertyTypeStatus}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.EKYBADDRESSPROPERTYTYPE]}
          selectValue={moduleAddressPropertyType}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.EKYBADDRESSVERIFICATION:
      return (
        <RulesSelect
          selectOptions={AddressVerificationStatus}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.EKYBADDRESSVERIFICATION]}
          selectValue={moduleAddressVerification}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.SCORESTATUS:
      return (
        <RulesSelect
          selectOptions={FraudResultStatus}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.SCORESTATUS]}
          selectValue={moduleFraudStatus}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.MANUALREVIEWSTATUS:
      return (
        <RulesSelect
          selectOptions={ManualReviewStatus}
          selectPlaceholder="Select"
          selectName={MODULE_MAPPING[Selector.MANUALREVIEWSTATUS]}
          selectValue={moduleManualReviewStatus}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    case Selector.COUNTRYNAME:
      return (
        <RulesSelect
          selectOptions={CountryNameValue}
          selectPlaceholder="select"
          selectName={MODULE_MAPPING[Selector.COUNTRYNAME]}
          selectValue={moduleCountryName}
          handleChangeSelect={e => handleChange(e, index)}
          disabled={disabled}
        />
      );
    default:
      return (
        <div className={`rules-select-wrapper`}>
          <input
            className="rules-input"
            type="text"
            name={MODULE_MAPPING[Selector.VALUE]}
            value={element.moduleValue}
            onChange={e => handleChange(e, index)}
            disabled={disabled}
          />
        </div>
      );
  }
};
