import styled from 'styled-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Problem from 'assets/img/problem.svg';

const CommonContainer = styled.div`
  display: flex;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1c1f28;
  position: absolute;
`;

const Image = styled.img`
  width: 100px;
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  font-size: 30px;
  font-weight: lighter;
  text-align: center;
  width: 650px;
  font-family: 'CircularStd-Book';
`;

const Span = styled.span`
  color: #ff5c6f;
  font-family: 'CircularStd-Bold';
  text-transform: capitalize;

  &.separated {
    display: block;
    margin: 5px 0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-around;
  margin-top: 30px;
`;

const ActionButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
`;

function AreYouSure({ dataValue, handleReviewClick }) {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <Image src={Problem} />
      <Paragraph>
        {`${t('areYouSure')} `}
        <Span className={cx({ separated: dataValue?.length > 15 })}>
          {dataValue?.toLowerCase()}
        </Span>{' '}
        {t('isNotCorrect')}
      </Paragraph>
      <ButtonsContainer>
        <ActionButton onClick={() => handleReviewClick(true)}>
          {t('isCorrectBtn')}
        </ActionButton>
        <ActionButton onClick={() => handleReviewClick(false)}>
          {t('isNotCorrectBtn')}
        </ActionButton>
      </ButtonsContainer>
    </CommonContainer>
  );
}

export default AreYouSure;
