import { camelToSentenceCase, toTitleCase } from 'utils/utils';
import cx from 'classnames';
import './RulesSelect.scss';

function RulesSelect({
  selectName,
  selectValue,
  handleChangeSelect,
  selectPlaceholder,
  selectOptions,
  selectedValue,
  shouldNormalize,
  mapFunc,
  disabled = false,
  classes,
}) {
  const wrapperClasses = cx('rules-select-wrapper', classes);
  return (
    <div className={wrapperClasses}>
      <select
        name={selectName}
        value={selectValue}
        onChange={handleChangeSelect}
        disabled={disabled}
      >
        {selectPlaceholder && (
          <option value="" className="placeholder">
            {toTitleCase(selectPlaceholder)}
          </option>
        )}
        {selectOptions &&
          selectOptions.map((option, i) => (
            <option value={option} key={i} selected={selectedValue}>
              {mapFunc
                ? mapFunc(option)
                : shouldNormalize
                ? camelToSentenceCase(option)
                : option}
            </option>
          ))}
      </select>
    </div>
  );
}

export default RulesSelect;
