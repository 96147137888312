import { useState } from 'react';
import styled from 'styled-components';

import { useInterval } from '../../../utils/hooks';

function timeValue(val) {
  const valString = '0' + val;
  return valString.slice(-2);
}

const Minutes = styled.p`
  font-family: 'CircularStd-Book';
  font-size: 20px;
`;

function TickTock() {
  const [seconds, setSeconds] = useState(0);

  useInterval(() => {
    setSeconds(seconds + 1);
  }, 1000);

  return (
    <Minutes>{`${timeValue(Math.floor(seconds / 60))}:${timeValue(
      seconds % 60,
    )}`}</Minutes>
  );
}

export default TickTock;
