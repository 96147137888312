import { useState, forwardRef } from 'react';
import styled from 'styled-components';

import chatIcon from 'assets/img/chat-icon.svg';

const Form = styled.form`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  height: 60px;
`;

const FormIcon = styled.img`
  padding: 20px 15px;
`;

const FormInput = styled.input`
  flex: 1;
  height: 100%;
  line-height: 59px;
  font-size: 16px;
  border: none;
`;

const SendButton = styled.button`
  font-family: 'Interstate-Bold';
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  height: 100%;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
`;

const MessageForm = forwardRef(({ onSubmit = () => {} }, ref) => {
  const [input, setInput] = useState('');

  const handleSubmit = ev => {
    ev.preventDefault();
    onSubmit(input);
    setInput('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormIcon src={chatIcon} alt="chat icon" />
      <FormInput
        type="text"
        placeholder="Write a message"
        value={input}
        onChange={ev => {
          setInput(ev.target.value);
        }}
        ref={ref}
      />
      <SendButton disabled={!input}>SEND</SendButton>
    </Form>
  );
});

export default MessageForm;
