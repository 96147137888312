import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { normalizeExpression } from './utils';
import iconDelete from 'assets/img/icon-delete-alt.svg';
import iconEdit from 'assets/img/icon-edit.svg';
import iconInfo from 'assets/img/icon-info.svg';
import ConfirmModal from './ConfirmModal';
import { useMemo, useState } from 'react';
import { NotificationBar } from './NotificationBar';

function TableActions({
  addRule,
  saveRules,
  openConfirmDeleteModal,
  rulesLength,
}) {
  return (
    <div className="rules-actions">
      <h2>Rules</h2>
      <div>
        <button onClick={addRule}>Add new rule</button>
        <button
          onClick={openConfirmDeleteModal}
          className="destructive"
          disabled={!rulesLength}
        >
          Remove all rules
        </button>
        <button className="submit" onClick={saveRules}>
          Save rules
        </button>
      </div>
    </div>
  );
}

function NoRules() {
  return (
    <div className="rules-table none">
      <h2>There are no rules set</h2>
    </div>
  );
}

function SortableRule({ rule, index, editRule, deleteRule }) {
  // eslint-disable-next-line no-unused-vars
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({
      id: rule.name,
    });
  const style = {
    // transform: transform ? CSS.Transform.toString(transform) : '',
    transition: transition ?? '',
  };

  return (
    <div key={rule.name} ref={setNodeRef} style={style}>
      <p className="single-rule">
        <span className="label">IF</span>
        <span
          className="rule-name rule-list"
          onClick={() => editRule(rule, index)}
          {...attributes}
          {...listeners}
        >
          {rule.name.split('#')[0]}
          <img src={iconEdit} alt="edit" height={18} />
        </span>
        <img
          src={iconDelete}
          alt="delete"
          onClick={() => deleteRule(index)}
          height={18}
        />
        <span className="info-icon">
          <img src={iconInfo} alt="info" height={24} />
          <span className="overlay">
            <code className="content label">
              <span className="if">IF</span>{' '}
              {normalizeExpression(rule.expression)} <br />
              <span className="then">THEN</span> tamper ={' '}
              <strong>{rule.tamper === true ? 'true' : 'false'}</strong>, paper
              = <strong>{rule.paper === true ? 'true' : 'false'}</strong>,
              screen ={' '}
              <strong>{rule.screen === true ? 'true' : 'false'}</strong>, fake ={' '}
              <strong>{rule.fake === true ? 'true' : 'false'}</strong>
            </code>
          </span>
        </span>
      </p>
    </div>
  );
}

export function RulesTable({
  setRules,
  rules,
  addRule,
  editRule,
  deleteRule,
  saveRules,
  removeRules,
  message,
  setMessage,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleDragEnd = e => {
    const { active, over } = e;
    if (active.id !== over.id) {
      setRules(newRules => {
        const oldIndex = newRules.findIndex(rule => rule.name === active.id);
        const newIndex = newRules.findIndex(rule => rule.name === over.id);
        return arrayMove(newRules, oldIndex, newIndex);
      });
    }
  };

  const sensors = [
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  ];

  const rulesLength = useMemo(() => (rules ? rules.length : 0), [rules]);

  return (
    <>
      <TableActions
        addRule={addRule}
        saveRules={saveRules}
        openConfirmDeleteModal={() => setModalOpen(true)}
        rulesLength={rulesLength}
      />
      <div className="rule-container">
        <NotificationBar message={message} setMessage={setMessage} />
        {rules ? (
          <div className="rules-table">
            <DndContext
              onDragEnd={handleDragEnd}
              sensors={sensors}
              collisionDetection={closestCenter}
            >
              <SortableContext
                items={rules.map(rule => rule.name)}
                strategy={verticalListSortingStrategy}
              >
                {rules.map((rule, index) => (
                  <SortableRule
                    key={rule.name}
                    rule={rule}
                    index={index}
                    editRule={editRule}
                    deleteRule={deleteRule}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        ) : (
          <NoRules />
        )}
        <ConfirmModal
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          removeRules={() => {
            removeRules();
            setModalOpen(false);
          }}
        />
      </div>
    </>
  );
}
