import { useState, useEffect } from 'react';
import { registerExecutive } from '../../../services/authRepository';
import { getAllExecutives } from 'services/usersRepository';
import { useAdminCorrectionLogin } from '../../../utils/hooks';
import LoginModal from './LoginModal';

export function UsersTable({ TableHeader, UserRowForm, UserRow, type }) {
  const [executives, setExecutives] = useState([]);
  const { isLogged, login, token } = useAdminCorrectionLogin();

  useEffect(() => {
    if (!isLogged) {
      return;
    }

    async function fetchExecutives() {
      try {
        const { executives } = await getAllExecutives(type, { token });
        setExecutives(executives);
      } catch (e) {}
    }
    fetchExecutives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  function handleDelete(phone) {
    return setExecutives(executives =>
      executives.filter(e => e.phone !== phone),
    );
  }

  function handleLogin({ token, clientId }) {
    return login({ token, clientId });
  }

  function handleRegisterExecutive(executive) {
    return registerExecutive(executive, { token });
  }

  return (
    <>
      <table className="users">
        <TableHeader />
        <tbody>
          <UserRowForm
            onSubmit={handleRegisterExecutive}
            onDone={executive => {
              setExecutives(executives => [executive, ...executives]);
            }}
          />
          {executives.map(user => (
            <UserRow
              key={user.phone}
              onDelete={handleDelete}
              token={token}
              {...user}
            />
          ))}
        </tbody>
      </table>
      {!isLogged && <LoginModal isOpen onLogin={handleLogin} />}
    </>
  );
}
