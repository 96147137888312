import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Ping = styled.p`
  width: 122px;
  height: 23px;
  flex-grow: 0;
  font-size: 18px;
  letter-spacing: 2px;
  text-align: left;
  color: #e6492d;
  font-family: 'CircularStd-Book';
`;

const TimeContainer = styled.div`
  padding-left: 10px;
  color: #1464a5;
`;

const Label = styled.p`
  height: 18px;
  font-family: 'CircularStd-Medium';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.94px;
  color: #20263d;
`;

const RedDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #e6492d;
  position: absolute;
  top: 5px;
  left: -10px;
`;

function timeValue(val) {
  const valString = '0' + val;
  return valString.slice(-2);
}

export default function Timer({ sessionExist }) {
  const [seconds, setSeconds] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (sessionExist) {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (!sessionExist && seconds !== 0) {
      setSeconds(0);
    }
  }, [sessionExist, seconds]);

  return (
    sessionExist && (
      <TimerContainer>
        <RedDot />
        <TimeContainer>
          <Ping>{t('recording')}</Ping>
          <Label id="minutes">{`${timeValue(
            Math.floor(seconds / 60),
          )}:${timeValue(seconds % 60)}`}</Label>
        </TimeContainer>
      </TimerContainer>
    )
  );
}
