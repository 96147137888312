import { createTheme } from '@material-ui/core';

export default createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        color: '#1c1f28',
      },
    },
    MuiDialogContent: {
      root: {
        overflow: 'hidden',
      },
    },
    MuiBackdrop: {
      root: {
        opacity: '0.9 !important',
        backgroundColor: '#1c1f28',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '"#1c1f28"',
      },
      root: {
        textTransform: 'none',
      },
    },
    MuiInput: {
      root: {
        color: '#fff',
        '&$focused': {
          color: '#fff',
        },
      },
      underline: {
        color: '#fff',
        '&$focused': {
          color: '#fff',
        },
        borderBottomColor: '#fff',
        '&:after': {
          borderBottomColor: '#fff',
        },
        '&:before': {
          borderBottomColor: '#fff',
        },
      },
    },
    MuiInputLabel: {
      animated: {
        color: '#fff',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          '&$focused': {
            color: '#FFF',
          },
        },
        color: '#FFF',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1c1f28',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#1c1f28',
      },
      daySelected: {
        backgroundColor: '#1c1f28',
      },
      current: {
        color: '#1c1f28',
      },
    },
  },
});
