import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordInput from './PasswordInput';
import RegexEnum from '../../../utils/regex';

const ForgotPassword = ({ onSendCode, onResetPassword, code, ...props }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, control, getValues } = useForm();
  const [step, setStep] = useState('sendCode');

  useEffect(() => {
    if (code > 0) {
      setStep('newPassword');
    }
  }, [code]);

  const sendCode = data => {
    onSendCode(data.email, () => setStep('codeSent'));
  };

  const resetPassword = async data => {
    await onResetPassword(data, () => setStep('sendCode'));
  };

  return (
    <div className="login-form">
      <h2>
        {step === 'codeSent'
          ? t('forgotPasswordForm.formTitleReset')
          : t('forgotPasswordForm.formTitle')}
      </h2>
      <span className="welcome">
        {step === 'codeSent'
          ? t('forgotPasswordForm.codeSentInfo')
          : step === 'sendCode'
          ? t('forgotPasswordForm.resetPasswordInfo')
          : ''}
      </span>
      {step === 'newPassword' ? (
        <form {...props} onSubmit={handleSubmit(resetPassword)}>
          <label htmlFor="newPassword">
            {t('forgotPasswordForm.newPassword')}
            {errors.newPassword && (
              <span className="error-message">
                {errors.newPassword.message}
              </span>
            )}
          </label>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: t('forgotPasswordForm.newPasswordRequired'),
              pattern: {
                value: RegexEnum.PASSWORD_PATTERN,
                message: t('forgotPasswordForm.newPasswordError'),
              },
            }}
            defaultValue=""
            render={({ onChange }) => (
              <PasswordInput
                id="password"
                className={errors.newPassword && 'error-input'}
                onChange={onChange}
              />
            )}
          />
          <label htmlFor="confirmPassword">
            {t('forgotPasswordForm.confirmPassword')}
            {errors.confirmPassword && (
              <span className="error-message">
                {errors.confirmPassword.message}
              </span>
            )}
          </label>
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: t('forgotPasswordForm.confirmPasswordRequired'),
              validate: value => {
                if (value === getValues('newPassword')) {
                  return true;
                } else {
                  return t('forgotPasswordForm.confirmPasswordError');
                }
              },
            }}
            render={({ onChange }) => (
              <PasswordInput
                id="confirmPassword"
                className={errors.confirmPassword && 'error-input'}
                onChange={onChange}
              />
            )}
          />
          <button className="green-lg" type="submit">
            {t('forgotPasswordForm.changePassword')}
          </button>
        </form>
      ) : (
        step === 'sendCode' && (
          <form {...props} onSubmit={handleSubmit(sendCode)}>
            <label htmlFor="email">
              {t('forgotPasswordForm.enterEmail')}
              {errors.email && (
                <span className="error-message">{errors.email.message}</span>
              )}
            </label>
            <input
              id="email"
              name="email"
              ref={register({ required: 'email is required' })}
              className={errors.email && 'error-input'}
            />
            <button className="green-lg" type="submit">
              {t('forgotPasswordForm.resetLink')}
            </button>
          </form>
        )
      )}
    </div>
  );
};

export default ForgotPassword;
