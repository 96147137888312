import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import alarmSound from 'assets/mp3/notification.wav';
import cx from 'classnames';
import moment from 'moment';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { ToastContainer } from 'react-toastify';
import { from, fromEvent, of } from 'rxjs';
import { delay, mergeMap, repeat, takeUntil, tap } from 'rxjs/operators';
import {
  finishSupervision,
  getNext,
  update,
} from '../../../services/repository';
import {
  useInterval,
  useManualCorrectionLogin,
  useQuery,
} from '../../../utils/hooks';
import ManualCorrectorLogin from './ManualCorrectorLogin';
import {
  manualCorrectionActions,
  manualCorrectionInitialState,
  OPTIONS,
  allowedGroups,
} from './constants';
import { ManualCorrectionImages } from './ManualCorrectionImages';

const RadioOption = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#21273b',
    border: '2px solid #fff',
    width: '110px',
    height: '36px',
    borderRadius: '6px',
    fontSize: '12px',
    '&$checked': {
      color: '#ffffff',
    },
    '&:hover': {
      backgroundColor: '#00b2fd',
      borderColor: '#00b2fd',
      color: '#ffffff',
    },
  },
  checked: {
    backgroundColor: '#00b2fd',
    borderColor: '#00b2fd',
  },
})(props => (
  <Radio
    checkedIcon={<span>{props.title}</span>}
    icon={<span>{props.title}</span>}
    color="default"
    {...props}
  />
));

function Question({ label, onChange, name, value, isDisabled }) {
  const { t } = useTranslation();
  return (
    <div className="question">
      <label>{label}</label>
      <div className="answers">
        <RadioGroup
          aria-label="label"
          name={name}
          value={value}
          className="radio-group"
          onChange={onChange}
        >
          <FormControlLabel
            disabled={isDisabled}
            classes={{ label: 'control-label' }}
            value={true}
            control={<RadioOption title={t('yesAnswer')} />}
          />
          <FormControlLabel
            disabled={isDisabled}
            classes={{ label: 'control-label' }}
            value={false}
            control={<RadioOption title={t('noAnswer')} />}
          />
          <FormControlLabel
            disabled={isDisabled}
            classes={{ label: 'control-label' }}
            value={OPTIONS.I_DONT_KNOW}
            control={<RadioOption title={t('iDontKnowAnswer')} />}
          />
        </RadioGroup>
      </div>
    </div>
  );
}

function Languages() {
  const { i18n, t } = useTranslation();
  const handleChangeLanguage = useCallback(
    lang => {
      i18n.changeLanguage(lang);
    },
    [i18n],
  );
  const [language] = i18n.language.split('-');
  const isSpanishLanguage = language === 'es';

  return (
    <div className="languages">
      <button
        className={cx('languaje', { selected: isSpanishLanguage })}
        onClick={() => {
          handleChangeLanguage('es');
        }}
      >
        <span className="flag" role="img" aria-label="mex-flag">
          🇲🇽
        </span>
        {t('spanish')}
      </button>
      <button
        className={cx('languaje', { selected: !isSpanishLanguage })}
        onClick={() => {
          handleChangeLanguage('en');
        }}
      >
        <span className="flag" role="img" aria-label="us-flag">
          🇺🇸
        </span>
        {t('english')}
      </button>
    </div>
  );
}

const manualCorrectionReducer = (state, action) => {
  switch (action.type) {
    case manualCorrectionActions.SET_VALUE: {
      const { payload: manualCorrectionNewValue } = action;
      return { ...state, ...manualCorrectionNewValue, status: 'resolved' };
    }
    case manualCorrectionActions.SET_STATUS: {
      const { payload: status } = action;
      return { ...state, status };
    }
    case manualCorrectionActions.RESET: {
      return manualCorrectionInitialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

function calculateTimeLeft(manualCorrectionTime = 0) {
  const difference = manualCorrectionTime - +new Date();

  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / 1000 / 60 / 60) % 60),
      mins: Math.floor((difference / 1000 / 60) % 60),
      secs: Math.floor((difference / 1000) % 60),
    };
  } else if (manualCorrectionTime !== 0 && difference <= 0) {
    timeLeft = {
      hours: 0,
      mins: 0,
      secs: 0,
    };
  }
  return timeLeft;
}

const Logout = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const { logout } = useManualCorrectionLogin();

  return (
    <button ref={ref} className="btn-logout" onClick={logout}>
      {t('logout')}
    </button>
  );
});

function ManualCorrection() {
  const urlGroup = useQuery().get('group');

  const group = useMemo(() => {
    if (!urlGroup || !allowedGroups.includes(urlGroup)) {
      return 'other';
    }
    return urlGroup;
  }, [urlGroup]);
  const [
    { tamper, fake, paper, screen, faceSpoof, faceMismatch, status },
    dispatch,
  ] = useReducer(manualCorrectionReducer, manualCorrectionInitialState);
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(calculateTimeLeft());
  const [data, setData] = useState({});
  const logoutButton = useRef(null);

  const { isLogged, login, token } = useManualCorrectionLogin();
  const audioElement = useRef(null);
  const originalData = useRef();

  function timer() {
    let hours = '00';
    let mins = '00';
    let secs = '00';
    if (Object.keys(remainingTime).length) {
      hours = `0${remainingTime?.hours}`;
      mins = `0${remainingTime?.mins}`;
      secs = `0${remainingTime?.secs}`;
    }

    return `${hours === '00' ? '' : `${hours.slice(-2)}:`}${mins.slice(
      -2,
    )}:${secs.slice(-2)}`;
  }

  const setQueueStatus = useCallback(() => {
    finishSupervision(data.id, token).finally(() => {
      setData({});
      setRemainingTime({});
      originalData.current = null;
      dispatch({ type: manualCorrectionActions.RESET });
    });
  }, [data.id, token]);

  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      data.id &&
      remainingTime?.hours === 0 &&
      remainingTime?.secs === 0 &&
      remainingTime?.mins === 0
    ) {
      const timeIsUp = t('timeIsUp');
      new Notification(timeIsUp);
      setQueueStatus();
    }
    return () => {
      mounted = false;
    };
  }, [data.id, remainingTime, setQueueStatus, t]);

  useInterval(
    () => {
      setRemainingTime(calculateTimeLeft(data.expiresAt));
    },
    data.expiresAt ? 1000 : null,
  );
  useEffect(() => {
    Notification.requestPermission().catch(error => {
      console.error('Failed to request notification permission:', error); // eslint-disable-line no-console
    });
  }, []);

  const startPolling = useCallback(() => {
    const stopPolling$ = fromEvent(logoutButton.current, 'click');
    const handleResponse = newData => {
      if (!newData) {
        return;
      }
      const remainingSecs = moment
        .duration(moment(newData.expiresAt).diff(moment()))
        .asSeconds();

      if (remainingSecs <= 0) {
        setQueueStatus(newData.id);
      } else {
        new Notification(t('notifications.newUser'));
        setData(newData);
        originalData.current = newData;
        audioElement.current
          .play()
          .catch(e => console.log(`Sound can not play... Error: ${e}`)); // eslint-disable-line no-console
      }
    };

    const getNext$ = () => from(getNext(token, group));

    const poll$ = of({}).pipe(
      mergeMap(() => {
        if (originalData.current) {
          return of('');
        }
        return getNext$();
      }),
      tap(handleResponse),
      delay(1000),
      repeat(),
      takeUntil(stopPolling$),
    );

    poll$.subscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (isLogged) {
      //comment for developing purposes and fill up data state with mock info
      startPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const handleLogin = loginData => {
    login(loginData);
  };

  const handleOnChangeOption = (event, key) => {
    const {
      target: { value },
    } = event;
    const dataProp = {};
    if (value === OPTIONS.I_DONT_KNOW) {
      dataProp[key] = OPTIONS.I_DONT_KNOW;
    } else {
      dataProp[key] = value === OPTIONS.YES ? true : false;
    }

    const updatedData = {
      ...(dataProp[key] !== OPTIONS.I_DONT_KNOW && dataProp),
    };
    dispatch({ type: manualCorrectionActions.SET_STATUS, payload: 'pending' });
    update(data.id, updatedData, token)
      .then(() => {
        dispatch({
          type: manualCorrectionActions.SET_VALUE,
          payload: dataProp,
        });
      })
      .finally(() => {
        dispatch({
          type: manualCorrectionActions.SET_STATUS,
          payload: 'idle',
        });
      });
  };

  const isQuestionDisabled = status === 'pending';
  const hasSelfie = data?.selfieUrl;

  return (
    <>
      {!isLogged ? (
        <ManualCorrectorLogin onLogin={handleLogin} />
      ) : (
        <>
          <ToastContainer position="top-center" role="alert" autoClose={1500} />
          <section className={cx('manual-review', { empty: !data })}>
            <audio src={alarmSound} ref={audioElement}>
              {t('notSupportAudio')}
            </audio>
            <header>
              <Languages />
              <Logout ref={logoutButton} />
            </header>

            {data.id && (
              <div className="content">
                <ManualCorrectionImages data={data} />

                <div className="main-content-mc">
                  <div className="timer-container">
                    <div>
                      <p className="label">
                        {t('clientID')}: <span>{data.clientId || '-'}</span>
                      </p>
                      <p className="label">
                        {t('sessionID')}: <span>{data.id || '-'}</span>
                      </p>
                    </div>
                    <div
                      className={cx('timer', {
                        red:
                          remainingTime?.hours === 0 &&
                          remainingTime?.mins === 0 &&
                          remainingTime?.secs <= 5,
                      })}
                    >
                      <span>{timer()}</span>
                    </div>
                  </div>
                  <div className="questions">
                    {!data?.config ||
                      (data.config.tamper === true && (
                        <Question
                          isDisabled={isQuestionDisabled}
                          label={t('tamperQuestion')}
                          name="tamper"
                          value={tamper}
                          onChange={ev => {
                            handleOnChangeOption(ev, 'tamper');
                          }}
                        />
                      ))}
                    {!data?.config ||
                      (data.config.fake === true && (
                        <Question
                          isDisabled={isQuestionDisabled}
                          label={t('fakeQuestion')}
                          name="fakeCheck"
                          value={fake}
                          onChange={ev => {
                            handleOnChangeOption(ev, 'fake');
                          }}
                        />
                      ))}
                    {!data?.config ||
                      (data.config.paper === true && (
                        <Question
                          isDisabled={isQuestionDisabled}
                          label={t('paperQuestion')}
                          name="papervalue"
                          value={paper}
                          onChange={ev => {
                            handleOnChangeOption(ev, 'paper');
                          }}
                        />
                      ))}
                    {!data?.config ||
                      (data.config.screen === true && (
                        <Question
                          isDisabled={isQuestionDisabled}
                          label={t('screenQuestion')}
                          name="screenvalue"
                          value={screen}
                          onChange={ev => {
                            handleOnChangeOption(ev, 'screen');
                          }}
                        />
                      ))}
                    {hasSelfie && (
                      <>
                        <Question
                          isDisabled={isQuestionDisabled}
                          label={t('faceSpoofQuestion')}
                          name="faceSpoof"
                          value={faceSpoof}
                          onChange={ev => {
                            handleOnChangeOption(ev, 'faceSpoof');
                          }}
                        />
                        <Question
                          isDisabled={isQuestionDisabled}
                          label={t('faceMismatchQuestion')}
                          name="faceMismatch"
                          value={faceMismatch}
                          onChange={ev => {
                            handleOnChangeOption(ev, 'faceMismatch');
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="actions">
                    <button
                      className="primary"
                      type="submit"
                      onClick={setQueueStatus}
                    >
                      {t('done')}
                    </button>
                    <button
                      className="secondary"
                      type="submit"
                      onClick={setQueueStatus}
                      style={{ color: 'black' }}
                    >
                      {t('skip')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </>
      )}
    </>
  );
}

export default ManualCorrection;
