import SignaturesEnum from './signatureEnums';

export const isMimeTypePDF = base64 =>
  !!base64.includes(SignaturesEnum.PDF_FILE);

export const toImage = base64 =>
  base64 ? `data:image/png;base64,${base64}` : '';

export const base64ToBlob = base64 => {
  const byteCharacters = atob(base64);
  const len = byteCharacters.length;
  const byteArray = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }
  return new Blob([byteArray], { type: 'application/pdf' });
};

export function keyToName(key) {
  return (
    key
      .replace(/([A-Z])/g, ' $1')
      .replace(/m+\s/g, '')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export const toTitleCase = sentence =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const keyToTitleCase = sentence =>
  toTitleCase(sentence.toLowerCase().replaceAll('_', ' '));

const mockedSessions = ['mocked'];

export const findInMockedSessions = curp => mockedSessions.includes(curp);
