import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Snackbar, IconButton } from '@material-ui/core';

const Notification = ({
  open,
  messageNode,
  autoHideDuration = 6000,
  requestCloseCallback,
}) => {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={requestCloseCallback}
        message={messageNode}
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={requestCloseCallback}
            >
              Close
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={requestCloseCallback}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  messageNode: PropTypes.node,
  autoHideDuration: PropTypes.number,
  requestCloseCallback: PropTypes.func.isRequired,
};

export default Notification;
