import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Modal from 'react-modal';
import cx from 'classnames';

import history from '../../../utils/history';
import phone from 'assets/img/phone.svg';
import { useState } from 'react';
import Timer from './Timer';
import { useTranslation } from 'react-i18next';
import newLogo from 'assets/img/omni.svg';
import reported from 'assets/img/reported.svg';
import logout from 'assets/img/logout.svg';
import ReportProblemModal from '../../widget/reportProblemModal/ReportProblemModal';

Modal.setAppElement('#root');

const HeaderContainer = styled.header`
  padding: 0 25px;
  height: 120px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
`;

const LogoContainer = styled.div`
  height: 100%;
`;

const Logo = styled.img`
  height: auto;
`;

const UsersInQueue = styled.p`
  width: 171px;
  height: 25px;
  font-family: 'CircularStd-Book';
  padding-left: 7px;
  font-size: 16px;
  letter-spacing: -0.1px;
  color: #20263d;

  span {
    font-weight: bold;
    color: #3cb0f7;
  }
`;

const QueueName = styled.p`
  width: 133px;
  height: 18px;
  flex-grow: 0;
  font-family: 'CircularStd-Book';
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #9ea0a5;
`;

const ReportBugButton = styled.button`
  width: auto;
  height: 35px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  object-fit: contain;
  margin: 10px;
  padding: 0 10px;
  border-radius: 50px;
  background-color: #21273b;
  color: #ffffff;
`;

const ModalTitle = styled.h1`
  color: #000;
`;

const ReportedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ReportedImage = styled.img`
  width: 70px;
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 120px;
  align-items: center;
`;

const StartTime = styled.p`
  width: 180px;
  height: 16px;
  opacity: 0.7;
  font-family: 'CircularStd-Bold';
  font-size: 12px;
  font-weight: bold;
  color: #2c5e40;
  white-space: nowrap;
  margin-top: 5px;
  background-color: #9dfab7;
  border-radius: 50px;
  padding: 0 5px;
`;

const LoginInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExecutiveName = styled.p`
  font-size: 14px;
  color: #20263d;
`;

const UserType = styled.p`
  font-size: 14px;
  color: #9ea0a5;
`;

const ExecutiveActionButton = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 60px;

  &.hang {
    background-color: #ff5c6f;
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }
`;

const ExecutiveActionButtonImage = styled.img``;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const ProductLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00b2fd;
  width: 400px;
  height: 90px;
  border-radius: 5px;
  padding: 8px;
`;

const ProductLabelParagraph = styled.p`
  font-size: 12px;
  line-height: 1.05;
`;

const ProductIdSpan = styled.span`
  font-size: 10px;
  margin: 0 0 5px 5px;
`;

function Reported() {
  return (
    <ReportedContainer>
      <ReportedImage src={reported} alt="reported" />
      <ModalTitle>Reported!</ModalTitle>
    </ReportedContainer>
  );
}

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoAndActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const QueueInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function Header({
  sessionExist,
  handleLogOut,
  handleReportBug,
  reported,
  setReported,
  setIsFinishSessionModalOpen,
  queueLength,
  loginInfo,
  queueName,
  productName,
  productId,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation();

  const startTime = useRef();

  useEffect(() => {
    startTime.current = moment().format('MMM Do YYYY kk:mm:ss');
  }, []);

  function closeModal() {
    setModalIsOpen(false);
    setReported(false);
  }

  return (
    <HeaderContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        {reported ? (
          <Reported />
        ) : (
          <ReportProblemModal handleReportBug={handleReportBug} />
        )}
      </Modal>
      <LeftSection>
        <LogoContainer>
          <Logo src={newLogo} alt="logo" onClick={() => history.push('/')} />
          <QueueInfoContainer>
            <UsersInQueue>
              {`${t('usersInQueue')}: `}
              <span>{queueLength}</span>
            </UsersInQueue>
            <QueueName>{`${t('header.queue')}: ${
              queueName ? queueName : 'default'
            }`}</QueueName>
          </QueueInfoContainer>
        </LogoContainer>
        {productName && (
          <ProductInfoContainer>
            <ProductIdSpan>{productId}</ProductIdSpan>
            <ProductLabelContainer>
              <ProductLabelParagraph>{productName}</ProductLabelParagraph>
            </ProductLabelContainer>
          </ProductInfoContainer>
        )}
      </LeftSection>
      <Timer sessionExist={sessionExist} />
      <ButtonsContainer>
        <ReportBugButton onClick={() => setModalIsOpen(true)}>
          {t('reportProblem')}
        </ReportBugButton>
        <RightContainer>
          <InfoAndActionButtonContainer>
            <LoginInfoContainer>
              <ExecutiveName>
                {loginInfo?.name || loginInfo?.email}
              </ExecutiveName>
              <UserType>{t(`header.userType.${loginInfo?.userType}`)}</UserType>
            </LoginInfoContainer>
            <ExecutiveActionButton
              className={cx({
                hang: sessionExist,
              })}
              onClick={
                sessionExist
                  ? () => setIsFinishSessionModalOpen(true)
                  : handleLogOut
              }
            >
              <ExecutiveActionButtonImage src={sessionExist ? phone : logout} />
            </ExecutiveActionButton>
          </InfoAndActionButtonContainer>
          {sessionExist && (
            <StartTime>{`Started: ${startTime.current}`}</StartTime>
          )}
        </RightContainer>
      </ButtonsContainer>
    </HeaderContainer>
  );
}

export default Header;
