import styled from 'styled-components';
import cx from 'classnames';

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  margin-left: 40px;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 50px;
  }
`;

const Span = styled.span`
  position: absolute;
  top: 0;
  font-weight: lighter;
  font-size: 10px;
  letter-spacing: 2px;
  height: 15px;
  font-family: $CircularStd-Book;
  color: #20263d;
  white-space: nowrap;
`;

const Paragraph = styled.p`
  font-family: $CircularStd-Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #20263d;
  color: #000;
  margin-top: 10px;
  text-transform: capitalize;

  &.address {
    font-size: 12px;
  }
`;

function FormItem({ title, data, name }) {
  return (
    <FormItemContainer>
      <Span>{title}</Span>
      <Paragraph className={cx({ address: name === 'address' })}>
        {data?.toLowerCase()}
      </Paragraph>
    </FormItemContainer>
  );
}

export default FormItem;
