import { useTranslation } from 'react-i18next';
import successIcon from 'assets/img/success.svg';

const PasswordWinState = ({ handleProceedToLogin }) => {
  const { t } = useTranslation();
  return (
    <div className="login-form">
      <h2>{t('login.passwordSet')}</h2>
      <div className="password-success">
        <img src={successIcon} alt="icon success" />
      </div>
      <button className="green-lg" type="button" onClick={handleProceedToLogin}>
        {t('login.proceed')}
      </button>
    </div>
  );
};

export default PasswordWinState;
