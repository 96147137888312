import { useState, useMemo } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { OrganizationsContext } from './context';
import store from './store';
import bbvaTheme from './theme';

const Providers = ({ children }) => {
  const [organizations, setOrganizations] = useState([]);

  const organizationsProvider = useMemo(
    () => ({ organizations, setOrganizations }),
    [organizations, setOrganizations],
  );
  return (
    <MuiThemeProvider theme={bbvaTheme}>
      <Provider store={store}>
        <OrganizationsContext.Provider value={organizationsProvider}>
          {children}
        </OrganizationsContext.Provider>
      </Provider>
    </MuiThemeProvider>
  );
};

export default Providers;
