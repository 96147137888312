import styled from 'styled-components';

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  border: solid 1px white;
  background-color: #fff;
  padding: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ModuleTitle = styled.h2`
  color: #000;
  margin: 10px;
`;

function Summary() {
  return (
    <ModuleContainer>
      <ModuleTitle>{`Tab name & content programmable by the bank.`}</ModuleTitle>
    </ModuleContainer>
  );
}

export default Summary;
