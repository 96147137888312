import styled from 'styled-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const StickyFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  height: 65px;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.14);
`;

const ColorfulButton = styled.button`
  width: 155px;
  height: 40px;
  border: none;
  border-radius: 20px;
  margin: 0 10px;
  color: #000;
  font-weight: bold;

  &.active {
    color: #fff;

    &.green {
      background-color: #0cd5a2;
    }

    &.red {
      background-color: #ff5c6f;
    }
  }
`;

function StickyFooter({
  score,
  handleApproveSession,
  closeSession,
  disabled = false,
}) {
  const { t } = useTranslation();

  return (
    <StickyFooterContainer>
      <ColorfulButton
        className={cx('green', { active: score >= 75 })}
        onClick={handleApproveSession}
        disabled={disabled}
      >
        {t('approveRequest')}
      </ColorfulButton>
      <ColorfulButton
        className={cx('red', { active: score < 75 })}
        onClick={() => closeSession('deny')}
        disabled={disabled}
      >
        {t('denyRequest')}
      </ColorfulButton>
    </StickyFooterContainer>
  );
}

export default StickyFooter;
