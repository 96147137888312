import axios from 'axios';
import sha1 from 'js-sha1';
import { getClientId, invalidTokenLogout } from './hooks';

import { env } from '../env';

const userServer = env.REACT_APP_USER_SERVER;

const apiKeyLessEndpoints = [
  '/executive/log-in',
  '/executive/login',
  '/executive/mfa-login',
  '/executive/login-with-code',
  '/executive/credentials-login',
  '/executive/correctors-login',
  '/executive/admin/login',
  '/executive/forgot-password',
  '/executive/reset-password',
];

const errorHandler = error => {
  if (
    error.response?.status === 403 ||
    error.response?.status === 401 ||
    (!error.response && error?.message?.includes('Network Error'))
  ) {
    console.log('Network error', error); //eslint-disable-line no-console
    invalidTokenLogout(error.response?.status, window.location.pathname);
  }
  throw error;
};

const createApi = baseURL => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'api-version': '1.0',
  };

  const axiosInstance = axios.create({
    baseURL,
    headers: defaultHeaders,
  });

  axiosInstance.interceptors.request.use(config => {
    if (
      !apiKeyLessEndpoints.includes((config.url ?? '').split('?')[0]) &&
      !config.headers['x-api-key']
    ) {
      config.headers['x-api-key'] = sha1(getClientId());
    }
    return config;
  });

  const createRequest =
    method =>
    async (url, data, { token, ...config } = {}) => {
      const headersToSet = config.headers;
      delete config.headers;
      const response = await axiosInstance({
        method,
        url,
        data,
        headers: {
          ...headersToSet,
          ...(token && { 'X-Incode-Hardware-Id': token }),
        },
        ...config,
      }).catch(errorHandler);
      return {
        success: response.status >= 200 && response.status < 300,
        status: response.status,
        data: response.data,
      };
    };

  return {
    get: createRequest('GET'),
    post: createRequest('POST'),
    put: createRequest('PUT'),
    delete: createRequest('DELETE'),
    request: (url, method = 'GET') => createRequest(method)(url),
    instance: axiosInstance,
  };
};

const api = createApi(userServer);

export default api;
