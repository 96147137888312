import {
  camelToSentenceCase,
  sortAlphabetically,
  toTitleCase,
} from 'utils/utils';
import './RulesSelect.scss';

const HASHTAG_REGEX = /#(\w+)\s*#(\w+)|#(\w+)/g;

function RulesGroupSelect({
  selectName,
  selectPlaceholder,
  selectValue,
  handleChangeSelect,
  selectedValue,
  selectGroupedOptions,
  shouldNormalize = false,
  disabled = false,
}) {
  const getCheckName = name => {
    const checkName = {
      ipAddress: 'IP Address',
      ipCountry: 'IP Country',
      geolocationToIdLocationDistance: 'Geolocation to ID Location Distance',
      geolocationToIpLocationDistance: 'Geolocation to IP Location Distance',
    };
    return checkName[name] ?? camelToSentenceCase(name);
  };

  const renderOptions = options => {
    return options?.sort(sortAlphabetically).map(option => (
      <option key={option.name} value={option.name} selected={selectedValue}>
        {shouldNormalize
          ? getCheckName(option.name.replace(HASHTAG_REGEX, ''))
          : option.name.replace(HASHTAG_REGEX, '')}
      </option>
    ));
  };

  const getGroupName = name => {
    const groupNames = {
      eKyc: 'eKYC',
      videoselfie: 'Video selfie',
      eKyb: 'eKYB',
      antifraud: 'Fraud validation',
      idValidation: 'ID validation',
      ocrConfidence: 'OCR Confidence',
    };
    return groupNames[name] ?? camelToSentenceCase(name);
  };

  return (
    <div className={`rules-group-select-wrapper`}>
      <select
        name={selectName}
        value={selectValue}
        onChange={handleChangeSelect}
        disabled={disabled}
      >
        <option value="" className="placeholder">
          {toTitleCase(selectPlaceholder)}
        </option>
        {Object.keys(selectGroupedOptions)
          .filter(group => selectGroupedOptions.hasOwnProperty(group))
          .filter(group => selectGroupedOptions[group].length > 0)
          .map((group, index) => {
            return (
              <optgroup
                key={index}
                label={shouldNormalize ? getGroupName(group) : group}
              >
                {renderOptions(selectGroupedOptions[group])}
              </optgroup>
            );
          })}
      </select>
    </div>
  );
}

export default RulesGroupSelect;
