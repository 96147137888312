import api from 'utils/api';

const addCompanyUrl = '/omni/organization/add';

const getValidationResultUrl = ({ limit, offset }) =>
  `/omni/validation-results/search?offset=${offset}&limit=${limit}`;
const getScoreUrl = id => `/omni/get/score?id=${id}`;
const getDeviceUrl = id => `/omni/get/device-info?id=${id}`;
const getImageUrl = id => `/omni/get/images/v2?id=${id}`;
const getEventsUrl = id => `/omni/get/events?id=${id}`;
const getEventsSignatureUrl = id => `/omni/get/events-signature?id=${id}`;
const getVideoSelfieUrl = interviewId =>
  `omni/get/archives${interviewId ? `?interviewId=${interviewId}` : ''}`;
const getVideoSelfieUrlUrl = archiveId =>
  `omni/generateVideoSelfieDownloadUrl?archiveId=${archiveId}`;
const getOcrDataURL = id => `/omni/get/ocr-data?id=${id}`;
const getMedicalOcrUrl = id => `/omni/get/medical-ocr-data?id=${id}`;
const getSignedContractsUrl = id => `/omni/get/signed-contracts-links?id=${id}`;
const getModuleCategoriesUrl = `/omni/module-category/get-all`;
const getModulesUrl = `/omni/module/get-all`;

const getAllFlowsUrl = `/omni/flow/get-all`;
const getFlowsUrl = (id = '') => `/omni/flow/${id}`;

const getOldFlowByIdUrl = id => `/omni/configuration/get?id=${id}`;
const getOldFlowsUrl = apiKey => `/omni/configuration/get-all`;
const updateOldFlowUrl = id => `/omni/configuration/update?id=${id}`;
const deleteOldFlowUrl = id => `/omni/configuration/delete?id=${id}`;
const addOldFlowUrl = `/omni/configuration/add`;

const newSessionUrl = `/omni/start`;
const addPhonenUrl = `/omni/add/phone`;
const addFrontIdUrl = `/omni/add/front-id/v2`;
const addBackIdUrl = `/omni/add/back-id/v2`;
const getStatusUrl = `/omni/get/ms-status`;
const finishThirdPartyUrl = clientId =>
  `/omni/third-party/finish?clientId=${clientId}`;
const sendSmsUrl = `/omni/notify/send-sms`;

const approveCustomerUrl = interviewId =>
  `/omni/process/approve?interviewId=${interviewId}`;
const addNoteUrl = id => `/omni/add/notes?id=${id}`;
const addFeedbackUrl = id => `/omni/add/conference/feedback?id=${id}`;
const addUserConsentUrl = id =>
  `/omni/add/conference-consent?interviewId=${id}`;
const getConferenceModulesUrl = '/omni/get/conference-modules';
const addConferenceModuleUrl = '/omni/add/conference-module';
const removeConferenceModuleUrl = moduleId =>
  `/omni/delete/conference-module?id=${moduleId}`;
const getSessionReportUrl = '/omni/sessions/report';
const reportIssueUrl = '/omni/conference/report-issue';
const getCustomFieldsUrl = id => `/omni/get/custom-fields?id=${id}`;
const getOrgsUrl = `/omni/organization`;

export async function getValidationResults(
  { offset = 0, limit = 10 },
  requestBody,
  { token, cognitoToken },
) {
  const result = await api.post(
    getValidationResultUrl({ offset, limit }),
    requestBody,
    {
      token,
      cognitoToken,
    },
  );
  return result.data;
}

export async function getCustomFields(id, { token }) {
  try {
    const result = await api.get(getCustomFieldsUrl(id), {}, { token });
    return result.data;
  } catch (err) {
    console.log('Failed to get custom fields:', err); // eslint-disable-line no-console
  }
}

export async function addOrganization(organizationData) {
  const result = await api.post(addCompanyUrl, organizationData, {});

  return result.data;
}

export async function getScore(id, { token }) {
  try {
    const result = await api.get(getScoreUrl(id), {}, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function getDevice(id, { token, cognitoToken }) {
  const result = await api.get(getDeviceUrl(id), {}, { token, cognitoToken });
  return result.data;
}

export async function getStatus() {
  const result = await api.get(getStatusUrl, {}, {});
  return result.data;
}

export async function getImage(id, imgObj, { token, cognitoToken }) {
  const result = await api.post(getImageUrl(id), imgObj, {
    token,
    cognitoToken,
  });
  return result.data;
}

export async function getEvents(id, { token, cognitoToken }) {
  const result = await api.get(getEventsUrl(id), {}, { token, cognitoToken });
  return result.data;
}

export async function getEventsSignature(id, { token, cognitoToken }) {
  const result = await api.get(getEventsSignatureUrl(id), {}, { token });
  return result.data;
}

export async function getSignedContracts(id, { token }) {
  try {
    const result = await api.get(getSignedContractsUrl(id), {}, { token });
    return result.data;
  } catch (err) {
    //Temporal fix for Citi env
    return {
      additionalInformation: {},
    };
  }
}

export async function getModuleCategories(token) {
  const result = await api.get(getModuleCategoriesUrl, {}, { token });
  return result.data;
}

export async function getModules(token) {
  const result = await api.get(getModulesUrl, {}, { token });
  return result.data;
}

export async function getFlows(token) {
  const result = await api.get(getAllFlowsUrl, {}, { token });
  return result.data;
}

export async function getFlowById(id, token) {
  const result = await api.get(getFlowsUrl(id), {}, { token });
  return result.data;
}

export async function addFlow(flow, token) {
  const result = await api.post(getFlowsUrl(), flow, { token });
  return result.data;
}

export async function updateFlow(id, body, token) {
  try {
    const result = await api.put(getFlowsUrl(id), body, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function deleteFlow(id, token) {
  try {
    const result = await api.delete(getFlowsUrl(id), {}, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function getOldFlowById(id, token) {
  const result = await api.get(getOldFlowByIdUrl(id), {}, { token });
  return result.data;
}

export async function getOldFlows(apiKey, token) {
  const result = await api.get(getOldFlowsUrl(apiKey), {}, { token });
  return result.data;
}

export async function addOldFlow(flow, token) {
  const result = await api.post(addOldFlowUrl, flow, { token });
  return result.data;
}

export async function updateOldFlow(id, body, token) {
  try {
    const result = await api.put(updateOldFlowUrl(id), body, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function deleteOldFlow(id, token) {
  try {
    const result = await api.delete(deleteOldFlowUrl(id), {}, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function newSession(
  { countryCode = 'MX', configurationId },
  token,
) {
  const result = await api.post(
    newSessionUrl,
    {
      countryCode,
      ...(configurationId && { configurationId }),
    },
    { token },
  );
  return result.data;
}

export async function addFrontImage(base64Image, token, options) {
  const result = await api.post(
    addFrontIdUrl,
    { base64Image },
    { token, ...options },
  );
  return result.data;
}

export async function addBackImage(base64Image, token, options) {
  const result = await api.post(
    addBackIdUrl,
    { base64Image },
    { token, ...options },
  );
  return result.data;
}

export async function addPhone(phone, token) {
  const result = await api.post(addPhonenUrl, { phone }, { token });
  return result.data;
}

export async function sendSms(params, token) {
  const result = await api.post(
    sendSmsUrl,
    {
      smsType: 'PARTIAL_ONBOARDING',
      params,
    },
    { token },
  );
  return result.data;
}

export async function finishThirdParty(token, clientId) {
  const result = await api.post(finishThirdPartyUrl(clientId), {}, { token });
  return result.data;
}

export async function getVideoSelfie(interviewId, { token, cognitoToken }) {
  const result = await api.get(
    getVideoSelfieUrl(interviewId),
    {},
    { token, cognitoToken },
  );
  return result.data;
}

export async function getVidSelfieUrl(archiveId, { token, cognitoToken }) {
  const result = await api.get(
    getVideoSelfieUrlUrl(archiveId),
    {},
    { token, cognitoToken },
  );
  return result.data;
}

export function getOcrData(id, { token }) {
  return api
    .get(getOcrDataURL(id), null, { token })
    .then(response => response.data);
}

export async function getMedicalOcr(id, { token }) {
  const result = await api.get(getMedicalOcrUrl(id), {}, { token });
  return result.data;
}

export async function approveCustomer(interviewId, token, approveComponents) {
  const result = await api.post(
    approveCustomerUrl(interviewId),
    {},
    {
      token,
    },
  );
  return result.data;
}

export async function addNote(interviewId, note, token) {
  const result = await api.post(addNoteUrl(interviewId), note, { token });
  return result.data;
}

export async function getConferenceModules(token) {
  const result = await api.get(getConferenceModulesUrl, {}, { token });
  return result.data;
}

export async function addConferenceModule(token, module) {
  const result = api.post(addConferenceModuleUrl, module, { token });
  return result.data;
}

export async function removeConferenceModule(token, module) {
  try {
    const result = api.delete(removeConferenceModuleUrl(module), {}, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function addFeedback(interviewId, feedback, token) {
  const result = await api.post(addFeedbackUrl(interviewId), feedback, {
    token,
  });
  return result.data;
}

export async function addUserConsent(interviewId, userConsent, token) {
  try {
    const result = await api.post(addUserConsentUrl(interviewId), userConsent, {
      token,
    });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

export async function getSessionReport({ token, filterBody }) {
  try {
    const result = await api.post(getSessionReportUrl, filterBody, {
      token,
    });
    return result.data;
  } catch (err) {
    return err.response;
  }
}

export async function getOrganizations(token) {
  const result = await api.get(getOrgsUrl, {}, { token });
  return result.data;
}

export async function reportIssue(
  interviewId,
  opentokSessionId,
  details,
  token,
) {
  try {
    const result = await api.post(
      reportIssueUrl,
      { interviewId, opentokSessionId, details },
      { token },
    );
    return result.data;
  } catch (err) {
    return err.message;
  }
}

// RULES
const getRulesUrl = `/omni/supervision/config`;
export async function getRules(token) {
  const result = await api.get(getRulesUrl, {}, { token });
  return result.data;
}

const addRulesUrl = '/omni/supervision/config';
export async function addRules(rulesData, token) {
  const result = await api.post(addRulesUrl, rulesData, { token });
  return result.data;
}

const deleteAllRulesUrl = id => `/omni/supervision/config?id=${id}`;
export async function deleteAllRules(ruleId, token) {
  try {
    const result = await api.delete(deleteAllRulesUrl(ruleId), {}, { token });
    return result.data;
  } catch (err) {
    return err.message;
  }
}

const getRuleOperandsUrl = `/omni/supervision/rule-operands`;
export async function getRuleOperands(token) {
  const result = await api.get(getRuleOperandsUrl, {}, { token });
  return result.data;
}
