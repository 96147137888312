import { useState, useRef, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import cx from 'classnames';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import api from '../../../utils/api';
import { toImage, toPdf } from '../../../utils/utils';
import { isMimeTypePDF } from '../../../utils/utils/helpers';
import { env } from '../../../env';

import CustomToast from '../../widget/customToast/CustomToast';
import passedIcon from 'assets/img/passed.svg';
import thrashIcon from 'assets/img/trash.svg';
import warningIcon from 'assets/img/warning.svg';

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: calc(100vh - 120px);
  background-color: #fff;
  padding: 40px;
  align-items: center;
  position: relative;
  overflow-y: auto;
`;

const ModuleTitle = styled.h2`
  display: flex;
  width: 100%;
  justify-content: left;
  letter-spacing: 0.88px;
  color: #20263d;
  font-family: $CircularStd-Bold;
`;

const ImagesContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
  min-width: 100%;
  min-height: 150px;
  align-items: center;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
`;

const sharedImageStyle = css`
  object-fit: cover;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  border: 3px solid #000;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }

  &.id {
    object-fit: contain;
  }
`;

const Image = styled.img`
  ${sharedImageStyle}
`;

const PdfObject = styled.object`
  ${sharedImageStyle}
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 50%;
  border: 3px solid #000;
  font-size: 48px;
  margin-left: 15px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  align-items: flex-start;
  height: 100%;
  margin-top: 45px;
`;

const ActionTitle = styled.h4`
  color: #20263d;
  margin-bottom: 10px;
  font-family: $CircularStd-Bold;
`;

const ActionButton = styled.button`
  background-color: #00b2fd;
  border: none;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-weight: bolder;
  width: 130px;
  margin-bottom: 5px;
  text-transform: uppercase;

  &:disabled {
    background-color: #bdbdbd;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
`;

const Button = styled.button`
  background-color: #3cb0f7;
  border-radius: 11px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.14);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  height: 50px;
  text-align: center;
  width: 180px;
  border: none;

  &:disabled {
    background-color: #bdbdbd;
  }
`;

const ModalImage = styled.img`
  width: 682.5px;
  height: 536.9px;
  object-fit: cover;
  border: 5px solid white;
  border-radius: 20px;
`;

const ModalTitle = styled.h1`
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 225px;
  justify-content: space-evenly;
  top: 20px;
  right: 0;
`;

const ModalButton = styled.button`
  height: 50px;
  border: none;
  background: #fff;
  width: 100px;
  border-radius: 11px;
  color: #000;
  font-weight: lighter;
  font-size: 16px;
  font-family: $CircularStd-Medium;
  text-transform: uppercase;

  &.delete {
    width: 50px;
    background-color: #9d9d9d;
  }
`;

const ModalButtonImage = styled.img`
  width: 20px;
`;

const MatchedLabel = styled.p`
  color: #000;
  font-size: 12px;
  font-weight: lighter;
  margin-bottom: 10px;
`;

const StatusIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
`;

const SubtitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
`;

const Subtitle = styled.p`
  font-family: $CircularStd-Book;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  color: #20263d;
  margin-right: 35px;
`;

function createBase64image() {
  const canvas = getCanvas();
  return canvas && canvas.toDataURL().split(',')[1];
}

function getCanvas() {
  const video = document.getElementsByTagName('video');

  if (video[1]) {
    const canvas = document.createElement('canvas');

    canvas.width = video[1].videoWidth;
    canvas.height = video[1].videoHeight;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(video[1], 0, 0, canvas.width, canvas.height);

    return canvas;
  }
}

const verifyFaceUrl = `/omni/verifyFace`;

function ImageCheck({
  selfie,
  frontId,
  backId,
  interviewId,
  handleModuleChange,
  handleComplete,
  setSelfie,
  takenSelfie,
  takenFront,
  setTakenFront,
  setTakenFrontFace,
  document,
  token,
  takenBack,
  setTakenBack,
  takenDocument,
  setTakenDocument,
  frontConfidence,
  setFrontConfidence,
  selfieConfidence,
  setSelfieConfidence,
  setStatusObject,
  session,
  manualIdCheckNeeded,
  manualSelfieCheckNeeded,
  setInterviewFinishedOptions,
  skipValidation,
  dataSent,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isTaking, setIsTaking] = useState(false);
  const status = useRef(0);

  const getCheck = (checkNeeded, image, takenImage) => {
    if (checkNeeded) {
      return true;
    }

    if (!image) return true;

    return takenImage;
  };

  const isValid = useMemo(() => {
    return skipValidation
      ? true
      : getCheck(manualSelfieCheckNeeded, selfie, takenSelfie) &&
          getCheck(manualIdCheckNeeded, frontId, takenFront) &&
          getCheck(false, backId, takenBack) &&
          getCheck(false, document, takenDocument);
  }, [
    takenSelfie,
    takenFront,
    takenBack,
    takenDocument,
    backId,
    document,
    frontId,
    manualIdCheckNeeded,
    manualSelfieCheckNeeded,
    selfie,
    skipValidation,
  ]);

  useEffect(() => {
    if (isValid) {
      setInterviewFinishedOptions(interviewFinishedOptions => ({
        ...interviewFinishedOptions,
        completed: {
          label: 'Interview completed',
          selected: false,
          disabled: false,
        },
      }));

      if (env.REACT_APP_ENCRYPT === 'true' && !dataSent) {
        session.signal(
          {
            type: 'showLegalModal',
          },
          error => {
            if (error) {
              console.log('signal error: ' + error.message); // eslint-disable-line no-console
            } else {
              console.log('signal sent'); // eslint-disable-line no-console
            }
          },
        );
      }
    }
  }, [isValid, setInterviewFinishedOptions, session, dataSent]);

  async function handleCaptureSelfie() {
    status.current = '';
    setIsTaking(true);
    const base64Image = createBase64image();
    const response = await captureImage(base64Image, interviewId, token);

    if (response?.status === 4020) {
      setSelfie(base64Image);
    }

    if (response?.confidence) {
      setSelfie(base64Image);
      setSelfieConfidence(response?.confidence);
    }

    setStatusObject(currentStatusObject => ({
      ...currentStatusObject,
      captureChecks: {
        ...currentStatusObject.captureChecks,
        selfie: response?.confidence ? true : false,
      },
    }));

    setIsTaking(false);
  }

  async function handleCaptureFront() {
    const videoHeight =
      window.document.querySelector('.OT_video-element').videoHeight;
    status.current = '';
    const base64Image = createBase64image();
    setIsTaking(true);
    const response = await captureIdImage(base64Image, interviewId, token);

    if (response?.status === 4020 || videoHeight < 480) {
      setTakenFront(base64Image);
    }

    if (response?.confidence && videoHeight >= 480) {
      setTakenFront(base64Image);
      setTakenFrontFace(response?.base64CroppedImage);
      setFrontConfidence(response?.confidence);
    }

    setStatusObject(currentStatusObject => ({
      ...currentStatusObject,
      captureChecks: {
        ...currentStatusObject.captureChecks,
        front: response?.confidence ? true : false,
      },
    }));

    setIsTaking(false);
  }

  async function handleStorePhoto(type) {
    status.current = '';
    const base64Image = createBase64image();
    setIsTaking(true);
    await storeConferencePhoto(base64Image, type);
    if (type === 'front') {
      setTakenFront(base64Image);
    } else if (type === 'back') {
      setTakenBack(base64Image);
    } else {
      setTakenDocument(base64Image);
    }
    setIsTaking(false);
    setStatusObject(currentStatusObject => ({
      ...currentStatusObject,
      captureChecks: {
        ...currentStatusObject.captureChecks,
        [type]: true,
      },
    }));
  }

  async function captureImage(base64Image, interviewId, token) {
    toast.dismiss();

    return api
      .post(
        verifyFaceUrl,
        {
          base64Image,
          interviewId,
          sendCroppedFace: false,
          compareWith: 'selfie',
        },
        { token },
      )
      .then(res => {
        if (res.data.confidence > 0.5) {
          status.current = 'success';
          toast(<CustomToast status="success" />);
          const { confidence } = res.data;
          return { base64Image, confidence };
        } else {
          toast(<CustomToast status="not-matched" />);
        }
      })
      .catch(err => {
        const res = err.response.data || '{}';

        toast(
          <CustomToast status={res.status ? res.status : 'server-error'} />,
        );

        return res;
      });
  }

  async function captureIdImage(base64Image, interviewId, token) {
    toast.dismiss();

    return api
      .post(
        verifyFaceUrl,
        {
          base64Image,
          interviewId,
          sendCroppedFace: true,
          compareWith: 'id',
        },
        { token },
      )
      .then(res => {
        if (res.data.confidence > 0.5) {
          status.current = 'success';
          toast(<CustomToast status="success" />);
          const { base64CroppedImage, confidence } = res.data;
          return { base64CroppedImage, confidence };
        } else {
          toast(<CustomToast status="not-matched" />);
        }
      })
      .catch(err => {
        const res = err.response.data || '{}';

        toast(
          <CustomToast status={res.status ? res.status : 'server-error'} />,
        );

        return res;
      });
  }

  async function storeConferencePhoto(base64Image, origin) {
    toast.dismiss();

    return api
      .post(
        'omni/conference/photo',
        {
          base64Image,
          interviewId,
          origin,
        },
        { token },
      )
      .then(res => {
        if (res.data.success) {
          status.current = 'success';
          toast(<CustomToast status="success" />);
        } else {
          toast(<CustomToast status="server-error" />);
        }
      })
      .catch(err => {
        const res = err.response.data || '{}';
        toast(
          <CustomToast status={res.status ? res.status : 'server-error'} />,
        );
      });
  }

  function handleClick(module) {
    if (!dataSent) {
      session.signal(
        {
          type: 'showLegalModal',
        },
        error => {
          if (error) {
            console.log('signal error: ' + error.message); // eslint-disable-line no-console
          } else {
            console.log('signal sent'); // eslint-disable-line no-console
          }
        },
      );
    }

    handleModuleChange(module);
    handleComplete(module);
  }

  function handleDeleteSelfie() {
    setIsModalOpen(false);
    setSelfie('');
  }

  function handleDeleteFront() {
    setIsModalOpen(false);
    setTakenFront('');
    setTakenFrontFace('');
  }

  function handleDeleteBack() {
    setIsModalOpen(false);
    setTakenBack(null);
  }

  function handleDeleteDocument() {
    setIsModalOpen(false);
    setTakenDocument(null);
  }

  function handleOpenModal(current) {
    setIsModalOpen(true);
    setCurrentImage(current);
  }

  return (
    <ModuleContainer>
      <StyledModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <ModalTitle>{t(currentImage)}</ModalTitle>
        <ModalImage
          src={
            currentImage === 'frontOfId'
              ? toImage(takenFront)
              : currentImage === 'backOfId'
              ? toImage(takenBack)
              : currentImage === 'selfie'
              ? toImage(takenSelfie)
              : toImage(takenDocument)
          }
          alt="taken picture"
        />
        <ButtonsContainer>
          <ModalButton
            className="delete"
            onClick={
              currentImage === 'frontOfId'
                ? handleDeleteFront
                : currentImage === 'backOfId'
                ? handleDeleteBack
                : currentImage === 'selfie'
                ? handleDeleteSelfie
                : handleDeleteDocument
            }
          >
            <ModalButtonImage src={thrashIcon} alt="delete" />
          </ModalButton>
          <ModalButton onClick={() => setIsModalOpen(false)}>
            {t('common.close')}
          </ModalButton>
        </ButtonsContainer>
      </StyledModal>
      <ToastContainer
        className="custom-toast-container"
        toastClassName={cx('custom-toast-wrapper', status.current)}
        containerId="conference-toast"
        position="top-center"
        hideProgressBar
        limit={1}
      />
      <ModuleTitle>{t('photoAndId')}</ModuleTitle>
      <SubtitlesContainer>
        <Subtitle>{t('uploaded')}</Subtitle>
        <Subtitle>{t('videoStream')}</Subtitle>
      </SubtitlesContainer>
      <ImagesContainer>
        {selfie && (
          <ImageRow>
            <Image src={toImage(selfie)} />
            {takenSelfie ? (
              <Image
                src={toImage(takenSelfie)}
                onClick={() => handleOpenModal('selfie')}
              />
            ) : (
              <NoImage>?</NoImage>
            )}
            <ActionContainer>
              <ActionTitle>{t('photoOfCustomer')}</ActionTitle>
              {takenSelfie ? (
                <>
                  <MatchedLabel>
                    {manualSelfieCheckNeeded
                      ? t('captured')
                      : `${t('matched')} ${Math.round(
                          Math.max(0, selfieConfidence) * 100,
                        )}%`}
                  </MatchedLabel>
                  <StatusIcon
                    src={manualSelfieCheckNeeded ? warningIcon : passedIcon}
                    alt="check"
                  />
                </>
              ) : (
                <ActionButton
                  disabled={takenSelfie || isTaking}
                  onClick={handleCaptureSelfie}
                >
                  {t('capture')}
                </ActionButton>
              )}
              {manualSelfieCheckNeeded && (
                <MatchedLabel>
                  *Unable to perform matching for uploaded photo
                </MatchedLabel>
              )}
            </ActionContainer>
          </ImageRow>
        )}
        {frontId && (
          <ImageRow>
            <Image className="id" src={toImage(frontId)} />
            {takenFront ? (
              <Image
                src={toImage(takenFront)}
                onClick={() => handleOpenModal('frontOfId')}
              />
            ) : (
              <NoImage>?</NoImage>
            )}
            <ActionContainer>
              <ActionTitle>{t('frontOfId')}</ActionTitle>
              {takenFront ? (
                <>
                  <MatchedLabel>
                    {env.REACT_APP_ENCRYPT === 'true' || manualIdCheckNeeded
                      ? 'Captured'
                      : `Matched! ${Math.round(
                          Math.max(0, frontConfidence) * 100,
                        )}%`}
                  </MatchedLabel>
                  <StatusIcon
                    src={manualIdCheckNeeded ? warningIcon : passedIcon}
                    alt="check"
                  />
                </>
              ) : (
                <ActionButton
                  onClick={async () => {
                    if (env.REACT_APP_ENCRYPT === 'true') {
                      await handleStorePhoto('front');
                    } else {
                      await handleCaptureFront();
                    }
                  }}
                  disabled={takenFront || isTaking}
                >
                  {t('capture')}
                </ActionButton>
              )}
              {manualIdCheckNeeded && (
                <MatchedLabel>
                  *Unable to perform matching for uploaded photo
                </MatchedLabel>
              )}
            </ActionContainer>
          </ImageRow>
        )}
        {backId && (
          <ImageRow>
            <Image className="id" src={toImage(backId)} />
            {takenBack ? (
              <Image
                src={toImage(takenBack)}
                onClick={() => handleOpenModal('backOfId')}
              />
            ) : (
              <NoImage>?</NoImage>
            )}
            <ActionContainer>
              <ActionTitle>{t('backOfId')}</ActionTitle>
              {takenBack ? (
                <StatusIcon src={passedIcon} alt="check" />
              ) : (
                <ActionButton
                  onClick={() => handleStorePhoto('back')}
                  disabled={takenBack || isTaking}
                >
                  {t('capture')}
                </ActionButton>
              )}
            </ActionContainer>
          </ImageRow>
        )}
        {document && (
          <ImageRow>
            {isMimeTypePDF(document) ? (
              <PdfObject data={toPdf(document)} />
            ) : (
              <Image className="id" src={toImage(document)} />
            )}
            {takenDocument ? (
              <Image
                src={toImage(takenDocument)}
                onClick={() => handleOpenModal('photoFromDocument')}
              />
            ) : (
              <NoImage>?</NoImage>
            )}
            <ActionContainer>
              <ActionTitle>{t('photoFromDocument')}</ActionTitle>
              {takenDocument ? (
                <StatusIcon src={passedIcon} alt="check" />
              ) : (
                <ActionButton
                  onClick={() => handleStorePhoto('poa')}
                  disabled={takenDocument || isTaking}
                >
                  {t('capture')}
                </ActionButton>
              )}
            </ActionContainer>
          </ImageRow>
        )}
      </ImagesContainer>
      {env.REACT_APP_ENCRYPT !== 'true' && (
        <ButtonContainer>
          <Button onClick={() => handleClick('score')} disabled={!isValid}>
            {t('common.nextStep')}
          </Button>
        </ButtonContainer>
      )}
    </ModuleContainer>
  );
}

export default ImageCheck;
