import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import loadingSpinner from '../components/services/loadingSpinner';
import notification from '../components/services/notification';
import circle from '../components/services/circle';
import thunk from 'redux-thunk';

const reducers = {
  notification,
  loadingSpinner,
  circle,
};

const logger = store => next => action => {
  const result = next(action);

  if (
    process.env.NODE_ENV !== 'production' &&
    !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    /* eslint-disable */
    console.group(action.type);
    console.info('Dispatching:', action);
    console.log('Next state:', store.getState());
    console.groupEnd(action.type);
    /* eslint-enable */
  }

  return result;
};

const store = createStore(
  combineReducers(reducers),
  composeWithDevTools(applyMiddleware(thunk, logger)),
);

export default store;
