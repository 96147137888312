import styled from 'styled-components';
import cx from 'classnames';

import { env } from '../../../env';

import idCard from 'assets/img/id-card.svg';
import imageCheck from 'assets/img/image-check.svg';
import chatIcon from 'assets/img/chat-icon-white.svg';
import completed from 'assets/img/completed.svg';
import score from 'assets/img/score.svg';
import summary from 'assets/img/summary.svg';
import notes from 'assets/img/notes.svg';

const ModuleMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 10vw;
  min-width: 150px;
  max-width: 160px;
  justify-content: space-between;
  background-color: #20263d;
  height: calc(100vh - 120px);
`;

const ModuleMenuItemContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: none;
  background-color: #20263d;
  border-bottom: solid 2px rgba(255, 255, 255, 0.05);
  flex-direction: column;

  &.active {
    background-color: #3c3f4a;
    border-bottom: none;
  }
`;

const ModuleIcon = styled.img`
  opacity: 0.2;

  &.active {
    opacity: 1;
  }
`;

const ModuleName = styled.p`
  height: 24px;
  font-family: 'CircularStd-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  opacity: 0.2;

  &.active {
    opacity: 1;
  }
`;

const CompletedIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const UnreadMessages = styled.div`
  background-color: #ff5c6f;
  border-radius: 50%;
  border: solid 2px #fff;
  font-weight: bold;
  height: 31px;
  line-height: 27px;
  position: absolute;
  right: -10px;
  top: -13px;
  width: 31px;
  z-index: 99;
`;

const MainModulesContainer = styled.div``;

const BottomModulesContainer = styled.div``;

function ModuleMenu({
  handleModuleChange,
  currentModule,
  modules,
  unreadMessages,
  externalLinks,
}) {
  function handleClick(module) {
    handleModuleChange(module);
  }

  function changeToMainModules(module) {
    if (modules[module]?.current) {
      handleModuleChange(module);
    }
  }

  return (
    <ModuleMenuContainer>
      <MainModulesContainer>
        <ModuleMenuItemContainer
          className={cx({ active: currentModule === 'info' })}
          onClick={() => changeToMainModules('info')}
          disabled={modules['info'].completed}
        >
          {modules['info'].completed && <CompletedIcon src={completed} />}
          <ModuleIcon
            className={cx({ active: currentModule === 'info' })}
            src={idCard}
          />
          <ModuleName className={cx({ active: currentModule === 'info' })}>
            ID Info
          </ModuleName>
        </ModuleMenuItemContainer>
        <ModuleMenuItemContainer
          className={cx({ active: currentModule === 'image' })}
          onClick={() => changeToMainModules('image')}
          disabled={modules['image'].completed}
        >
          {modules['image'].completed && <CompletedIcon src={completed} />}
          <ModuleIcon
            className={cx({ active: currentModule === 'image' })}
            src={imageCheck}
          />
          <ModuleName className={cx({ active: currentModule === 'image' })}>
            Image Check
          </ModuleName>
        </ModuleMenuItemContainer>
        {env.REACT_APP_ENCRYPT !== 'true' && (
          <ModuleMenuItemContainer
            className={cx({ active: currentModule === 'score' })}
            onClick={() => changeToMainModules('score')}
          >
            <ModuleIcon
              className={cx({ active: currentModule === 'score' })}
              src={score}
            />
            <ModuleName className={cx({ active: currentModule === 'score' })}>
              {' '}
              Identity Score
            </ModuleName>
          </ModuleMenuItemContainer>
        )}
        {Object.keys(externalLinks).map((k, i) => {
          return (
            <ModuleMenuItemContainer
              key={k}
              className={cx({ active: currentModule === k })}
              onClick={() => handleClick(k)}
            >
              {modules[k]?.completed && <CompletedIcon src={completed} />}
              <ModuleIcon
                className={cx({ active: currentModule === k })}
                src={summary}
              />
              <ModuleName className={cx({ active: currentModule === k })}>
                {k}
              </ModuleName>
            </ModuleMenuItemContainer>
          );
        })}
      </MainModulesContainer>
      <BottomModulesContainer>
        <ModuleMenuItemContainer
          className={cx({ active: currentModule === 'notes' })}
          onClick={() => handleClick('notes')}
        >
          <ModuleIcon className="active" src={notes} />
          <ModuleName className="active">Notes</ModuleName>
        </ModuleMenuItemContainer>
        <ModuleMenuItemContainer
          className={cx({ active: currentModule === 'chat' })}
          onClick={() => handleClick('chat')}
        >
          {unreadMessages > 0 && (
            <UnreadMessages>{unreadMessages}</UnreadMessages>
          )}
          <ModuleIcon className="active" src={chatIcon} />
          <ModuleName className="active">Messages</ModuleName>
        </ModuleMenuItemContainer>
      </BottomModulesContainer>
    </ModuleMenuContainer>
  );
}

export default ModuleMenu;
