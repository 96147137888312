import { useEffect, useRef } from 'react';

import ImageCheck from './ImageCheck';
import IdInfo from './IdInfo';
import Chat from './Chat';
import Summary from './Summary';
import IdentityScore from './IdentityScore';
import Notes from './Notes';

const toImage = base64 => `data:image/png;base64,${base64}`;

function Module({
  frontId,
  backId,
  dataToReview,
  handleReview,
  session,
  selfie,
  currentModule,
  handleModuleChange,
  handleComplete,
  modules,
  interviewId,
  token,
  closeSession,
  messages,
  croppedIdFace,
  externalLinks,
  setSelfie,
  takenSelfie,
  notes,
  handleNotesChange,
  takenFront,
  setTakenFront,
  takenFrontFace,
  setTakenFrontFace,
  document,
  takenBack,
  setTakenBack,
  takenDocument,
  setTakenDocument,
  frontConfidence,
  setFrontConfidence,
  selfieConfidence,
  setSelfieConfidence,
  areNotesSaved,
  setAreNotesSaved,
  poaName,
  proofOfAddress,
  setStatusObject,
  manualIdCheckNeeded,
  manualSelfieCheckNeeded,
  typeOfId,
  setInterviewFinishedOptions,
  skipValidation,
  dataSent,
}) {
  const frameRef = useRef(null);

  useEffect(() => {
    //TODO: adapt handler to accept any external module
    if (currentModule !== 'one') return;

    const handler = event => {
      const res = JSON.parse(event.data);

      if (res.message === 'data is correct') {
        handleComplete();
      }
      //event.data has module response
    };

    window.addEventListener('message', handler);

    setTimeout(() => {
      frameRef.current.contentWindow.postMessage(
        JSON.stringify({
          error: false,
          message: { front: toImage(frontId), back: toImage(backId) },
        }),
        'http://localhost:3000',
      );
    }, 3000);

    return () => window.removeEventListener('message', handler);
  });

  switch (currentModule) {
    case 'info':
      return (
        <IdInfo
          frontId={frontId}
          backId={backId}
          dataToReview={dataToReview}
          handleReview={handleReview}
          handleModuleChange={handleModuleChange}
          handleComplete={handleComplete}
          modules={modules}
          typeOfId={typeOfId}
          skipValidation={skipValidation}
        />
      );
    case 'image':
      return (
        <ImageCheck
          selfie={selfie}
          frontId={frontId}
          backId={backId}
          croppedIdFace={croppedIdFace}
          handleModuleChange={handleModuleChange}
          interviewId={interviewId}
          handleComplete={handleComplete}
          modules={modules}
          setSelfie={setSelfie}
          takenSelfie={takenSelfie}
          takenFront={takenFront}
          setTakenFront={setTakenFront}
          takenFrontFace={takenFrontFace}
          setTakenFrontFace={setTakenFrontFace}
          document={document}
          token={token}
          takenBack={takenBack}
          setTakenBack={setTakenBack}
          takenDocument={takenDocument}
          setTakenDocument={setTakenDocument}
          frontConfidence={frontConfidence}
          setFrontConfidence={setFrontConfidence}
          selfieConfidence={selfieConfidence}
          setSelfieConfidence={setSelfieConfidence}
          setStatusObject={setStatusObject}
          session={session}
          manualIdCheckNeeded={manualIdCheckNeeded}
          manualSelfieCheckNeeded={manualSelfieCheckNeeded}
          setInterviewFinishedOptions={setInterviewFinishedOptions}
          skipValidation={skipValidation}
          dataSent={dataSent}
        />
      );
    case 'score':
      return (
        <IdentityScore
          token={token}
          interviewId={interviewId}
          frontId={frontId}
          backId={backId}
          selfie={selfie}
          closeSession={closeSession}
          session={session}
          takenSelfie={takenSelfie}
          croppedIdFace={croppedIdFace}
          frontConfidence={frontConfidence}
          selfieConfidence={selfieConfidence}
          takenFront={takenFront}
          document={document}
          poaName={poaName}
          proofOfAddress={proofOfAddress}
          manualIdCheckNeeded={manualIdCheckNeeded}
          manualSelfieCheckNeeded={manualSelfieCheckNeeded}
        />
      );
    case 'summary':
      return <Summary />;
    case 'notes':
      return (
        <Notes
          notes={notes}
          handleNotesChange={handleNotesChange}
          areNotesSaved={areNotesSaved}
          setAreNotesSaved={setAreNotesSaved}
        />
      );
    case 'chat':
      return <Chat session={session} messages={messages} />;
    default:
      return (
        <iframe
          ref={frameRef}
          src={externalLinks[currentModule].url}
          title={externalLinks[currentModule].label}
          width="100%"
        />
      );
  }
}

export default Module;
