import { useState, useCallback, useEffect } from 'react';
import {
  manualCorrectorLogin,
  setNewPassword,
  sendForgotPasswordCode,
  sendResetPasswordCode,
} from './Login/authRepository';
import LoginForm from '../auth/LoginForm';
import NewPasswordForm from '../auth/NewPasswordForm';
import ForgotPassword from '../auth/ForgotPassword';
import { useQuery, clientName } from '../../../utils/hooks';
import { parseError } from '../../../utils/utils';

const ManualCorrectorLogin = ({ onLogin }) => {
  const [error, setError] = useState({ showError: false, message: '' });
  const [message, setMessage] = useState();
  const [changePassword, setChangePassword] = useState({
    show: false,
    email: '',
    session: '',
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const query = useQuery();
  const email = query.get('email');
  const tempPass = query.get('tempPass');
  const code = query.get('code') || '';

  const handleLoginResponse = (loginData, email = '') => {
    if (loginData.success) {
      query.delete('code');
      onLogin({ ...loginData, email });
      return;
    }
    if (loginData.message === 'NEW_PASSWORD_REQUIRED') {
      setChangePassword({ email, show: true, session: loginData.session });
    } else {
      setError({
        showError: true,
        message: 'An unexpected error occurred, please try again.',
      });
    }
  };

  const handleChangePassword = async ({ password, repeatPassword }) => {
    cleanMessages();
    if (password !== repeatPassword) {
      setError({
        showError: true,
        message: 'Passwords do not match',
      });
      return;
    }
    try {
      const newPasswordData = await setNewPassword({
        ...changePassword,
        password,
      });
      handleLoginResponse(newPasswordData, changePassword.email);
    } catch (error) {
      setError({
        showError: true,
        message: parseError(
          error.response?.data?.message ||
            'An unexpected error occurred, please try again.',
        ),
      });
    }
  };

  const handleResetForgottenPassword = async ({ newPassword }, nextStep) => {
    cleanMessages();
    try {
      await sendResetPasswordCode(email, code, newPassword);
      setForgotPassword(false);
      setMessage(
        'Password reset completed, you can now login with new credentials',
      );
      nextStep();
    } catch (error) {
      setError({
        showError: true,
        message:
          error.response?.data?.message ||
          'An unexpected error occurred, please try again.',
      });
    }
  };

  const handleSubmit = useCallback(async ({ email, password }) => {
    cleanMessages();
    try {
      const loginData = await manualCorrectorLogin(email, password);

      handleLoginResponse({ ...loginData, clientId: clientName }, email);
    } catch (error) {
      setError({
        showError: true,
        message: parseError(
          error.response?.data?.message ||
            'An unexpected error occurred, please try again.',
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleForgotPasswordCode = async (email, nextStep) => {
    cleanMessages();
    try {
      await sendForgotPasswordCode(email);
      nextStep();
    } catch (error) {
      setError({
        showError: true,
        message:
          error.response?.data?.message ||
          'An unexpected error occurred, please try again.',
      });
    }
  };

  const cleanMessages = () => {
    setError({
      showError: false,
      message: '',
    });
    setMessage(null);
  };

  const toggleForgotPassword = () => {
    cleanMessages();
    setForgotPassword(val => !val);
  };

  useEffect(() => {
    const checkQueryParams = async () => {
      if (email && tempPass) {
        handleSubmit({ email, password: tempPass });
      } else if (email && code) {
        setForgotPassword(true);
      }
    };
    checkQueryParams();
  }, [email, code, tempPass, handleSubmit]);

  return (
    <div className="login-page" style={{ height: '100vh' }}>
      <div className="login-inner">
        {changePassword.show ? (
          <NewPasswordForm
            name={changePassword.email}
            onSubmit={handleChangePassword}
          />
        ) : forgotPassword ? (
          <ForgotPassword
            onSendCode={handleForgotPasswordCode}
            onResetPassword={handleResetForgottenPassword}
            code={code}
          />
        ) : (
          <LoginForm onSubmit={handleSubmit} cleanMessage={cleanMessages} />
        )}
        {error.showError && <p className="error">{error.message}</p>}
        {message && <p className="msg">{message}</p>}
      </div>
      {forgotPassword ? (
        <div className="outer-link">
          <span>If you prefer it you can</span>
          <p className="forgot-password" onClick={toggleForgotPassword}>
            Go back to login
          </p>
        </div>
      ) : (
        <div className="outer-link">
          <span>Forgot your password?</span>
          <p className="forgot-password" onClick={toggleForgotPassword}>
            Get a new password
          </p>
        </div>
      )}
    </div>
  );
};

export default ManualCorrectorLogin;
