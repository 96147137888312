import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordInput from './PasswordInput';
import { env } from 'env';

export default function LoginForm({ onSubmit, cleanMessage, ...props }) {
  const { t } = useTranslation();
  const { register, errors, handleSubmit, control } = useForm();
  const autoComplete = env.REACT_APP_ENCRYPT !== 'true' ? 'on' : 'off';

  return (
    <div className="login-form">
      <h2>{t('loginForm.formTitle')}</h2>
      <span className="welcome">{t('loginForm.formSubtitle')}</span>
      <form {...props} onSubmit={handleSubmit(onSubmit, cleanMessage)}>
        <label htmlFor="email">{t('loginForm.email')}</label>
        <input
          id="email"
          name="email"
          className={errors.email && 'error-input'}
          ref={register({ required: true })}
          autoComplete={autoComplete}
        />
        <label htmlFor="password">{t('loginForm.password')}</label>
        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
          }}
          defaultValue=""
          render={({ onChange }) => (
            <PasswordInput
              id="password"
              className={errors.password && 'error-input'}
              onChange={onChange}
              autoComplete={autoComplete}
            />
          )}
        />
        <button className="green-lg" type="submit">
          {t('loginForm.login')}
        </button>
      </form>
    </div>
  );
}
