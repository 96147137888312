import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordInput from './PasswordInput';

export default function NewPasswordForm({ onSubmit, ...props }) {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit({ repeatPassword, password });
  }

  return (
    <div className="login-form">
      <h2>
        {t('newPasswordForm.formTitle')}{' '}
        <span className="login-email">{props.name}!</span>
      </h2>
      <span className="welcome">{t('newPasswordForm.setPassword')}</span>
      <form {...props} onSubmit={handleSubmit}>
        <label htmlFor="password">{t('newPasswordForm.newPassword')}</label>
        <PasswordInput
          id="password"
          name="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <label htmlFor="password">{t('newPasswordForm.confirmPassword')}</label>
        <PasswordInput
          id="repeat-password"
          name="repeat-password"
          value={repeatPassword}
          onChange={event => setRepeatPassword(event.target.value)}
        />

        <button className="green-lg" type="submit">
          {t('newPasswordForm.register')}
        </button>
      </form>
    </div>
  );
}
