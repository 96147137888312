import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import check from 'assets/img/1828739.svg';
import alert from 'assets/img/497789.svg';

const CustomToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`;

const Image = styled.img`
  height: 25px;
`;

const Text = styled.p`
  font-family: 'CircularStd-Bold';
  font-size: 18px;
  margin-left: 15px;
`;

const labelObj = {
  success: 'successfullyCaptured',
  'server-error': 'serverError',
  'not-matched': 'facesDontMatch',
  4019: 'faceNotFound',
  1003: 'faceCroppingFailure',
  'sms-sent': 'smsSentCorrectly',
  4020: 'imageToCompareNotFound',
  'customer-consumed': 'customerConsumed',
  500: 'serverError',
};

function CustomToast({ status }) {
  const { t } = useTranslation();

  return (
    <CustomToastWrapper>
      <Image src={status === 'success' ? check : alert} alt="check" />
      <Text>{t(labelObj[status])}</Text>
    </CustomToastWrapper>
  );
}

export default CustomToast;
